import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { currencyFormater, singleDetailsBoxStyle } from "../../../utils";

function WalletInformation({ data }) {
  return (
    <>
      <Typography gutterBottom variant="h6" sx={{ fontWeight: 500 }}>
        Wallet Information
      </Typography>
      <Box py={2}>
        <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
          <Typography color="text.secondary">Commission Balance:</Typography>

          <Typography align="left">
            {currencyFormater(data?.commissionBalance)}
          </Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
          <Typography color="text.secondary">Wallet Balance:</Typography>

          <Typography align="left">
            {currencyFormater(data?.walletBalance)}
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default WalletInformation;
