import React from "react";
import Box from "@mui/material/Box";
import { CSVLink } from "react-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Button from "@mui/material/Button";
import { csvBoxStyle, csvLinkStyle } from "../../../utils";

function TableCSVButton({ filename, data }) {
  return (
    <Box sx={csvBoxStyle}>
      <CSVLink filename={filename} data={data} style={csvLinkStyle}>
        <Button
          variant="outlined"
          size="small"
          startIcon={<FileDownloadIcon />}
        >
          Download CSV
        </Button>
      </CSVLink>
    </Box>
  );
}

export default TableCSVButton;
