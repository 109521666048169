import React from "react";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import { currencyFormater, safeJSONParse } from "../../../utils";

function TicketInformation({ data, itemBoxStyle, renderGameStatus }) {
  const raffle = safeJSONParse(data?.raffle);
  // console.log("ttttttttttttt", raffle[0]);
  return (
    <Grid item xs={12} md={7} lg={8}>
      <Typography gutterBottom variant="h6" sx={{ fontWeight: 500 }}>
        Ticket Information
      </Typography>
      <Box py={2}>
        <Box mb={1} pb={1} pr={2} sx={itemBoxStyle}>
          <Typography color="text.secondary">Ticket ID:</Typography>
          <Typography align="left" sx={{ textTransform: "uppercase" }}>
            {data && data?.ticketId}
          </Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={itemBoxStyle}>
          <Typography color="text.secondary"> ID:</Typography>
          <Typography align="left" sx={{ textTransform: "uppercase" }}>
            {data && data?.id}
          </Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={itemBoxStyle}>
          <Typography color="text.secondary">Booking Code:</Typography>
          <Typography align="left">{data && data?.bookingCode}</Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={itemBoxStyle}>
          <Typography color="text.secondary">Lines Count:</Typography>
          <Typography align="left" sx={{ textTransform: "uppercase" }}>
            {data && data?.linesCount}
          </Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={itemBoxStyle}>
          <Typography color="text.secondary">Potential Winning:</Typography>
          <Typography align="left" sx={{ textTransform: "uppercase" }}>
            {data && data?.potentialWinning}
          </Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={itemBoxStyle}>
          <Typography color="text.secondary">Game Status:</Typography>
          {renderGameStatus(data?.gameStatus)}
        </Box>
        <Box mb={1} pb={1} pr={2} sx={itemBoxStyle}>
          <Typography color="text.secondary">Total Staked Amount:</Typography>

          <Typography align="left">
            NGN{currencyFormater(data?.totalStakedAmount)}
          </Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={itemBoxStyle}>
          <Typography color="text.secondary">Total Wining Amount:</Typography>

          <Typography align="left">
            NGN{currencyFormater(data?.totalWinAmount)}
          </Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={itemBoxStyle}>
          <Typography color="text.secondary">Reference ID:</Typography>
          <Typography align="left">{data && data?.referenceId}</Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={itemBoxStyle}>
          <Typography color="text.secondary">
            Winer's Redemption Method:
          </Typography>
          <Typography align="left">
            {data && data?.winningRedemptionMethod}
          </Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={itemBoxStyle}>
          <Typography color="text.secondary">Date Created:</Typography>
          <Typography align="left">
            {dayjs(data?.createdAt).format("D MMM YYYY, hh:ss a")}
          </Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={itemBoxStyle}>
          <Typography color="text.secondary">Date Last Updated:</Typography>
          <Typography align="left">
            {dayjs(data?.updatedAt).format("D MMM YYYY, hh:ss a")}
          </Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={itemBoxStyle}>
          <Typography color="text.secondary">Ticket State:</Typography>
          {data?.status ? (
            <Chip color="success" label="Active" size="small" />
          ) : (
            <Chip label="Not Active" color="error" size="small" />
          )}
        </Box>
        <Box
          mb={1}
          pb={1}
          pr={2}
          sx={{
            borderBottom: "1px solid #eee",
          }}
        >
          <Typography color="text.secondary">Raffle:</Typography>
          {raffle?.map((item, idx) => {
            return typeof item === "object" ? (
              <Typography
                sx={{ mr: 2 }}
                key={idx}
                component="span"
                // color="text.secondary"
              >
                {item?.code}
              </Typography>
            ) : (
              <Typography
                sx={{ mr: 2 }}
                key={idx}
                component="span"
                // color="text.secondary"
              >
                {item}
              </Typography>
            );
          })}
        </Box>
      </Box>
    </Grid>
  );
}

export default TicketInformation;
