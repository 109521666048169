import React, { useState } from "react";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import axios from "axios";
import useSWRImmutable from "swr/immutable";
import { mutate } from "swr";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import AdminLayout from "../components/shared/AdminLayout/AdminLayout";
import MetaDecorator from "../components/shared/MetaDecorator/MetaDecorator";
import AdminsTable from "../components/pages/AdminsComponents/AdminsTable";
import PageHeading from "../components/shared/PageHeading/PageHeading";
import AdminsFilter from "../components/pages/AdminsComponents/AdminsFilter";
import FullScreenError from "../components/shared/FullScreenError/FullScreenError";
import FullScreenLoader from "../components/shared/FullScreenLoader/FullScreenLoader";
import {
  AddButtonBoxStyles,
  checkExpiredAuthToken,
  getAuthToken,
  limit,
} from "../utils";
import ClearFilters from "../components/shared/ClearFilters/ClearFilters";
import PreviewAdminModal from "../components/pages/AdminsComponents/PreviewAdminModal";
import { AppContext } from "../context";
import ToggleAdminDialog from "../components/pages/AdminsComponents/ToggleAdminDialog";
import AdminsAnalytics from "../components/pages/AdminsComponents/AdminsAnalytics";

function Admins() {
  const [status, setStatus] = useState("");
  const [userName, setUserName] = useState("");
  const [userRole, setUserRole] = useState("");
  const [page, setPage] = useState(1);
  const [isFiltering, setIsFiltering] = useState(false);
  const [hasFiltered, setHasFiltered] = useState(false);
  const [loginDate, setLoginDate] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openToggleDialog, setOpenToggleDialog] = useState(false);
  const [openPreviewAdminDialog, setOpenPreviewAdminDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const navigate = useNavigate();
  const { handleOpenSnackbar } = React.useContext(AppContext);

  const handleCloseToggleAdminDialog = () => setOpenToggleDialog(false);
  const handleOpenToggleAdminDialog = () => setOpenToggleDialog(true);
  const handleSelectUser = (user) => setSelectedUser(user);

  const url = `${process.env.BASE_URL}admin/fetch-admins?limit=${limit}&page=${page}`;

  let filterURL = `${process.env.BASE_URL}admin/fetch-admins?limit=${limit}&page=${page}`;

  // SessionStorage Variable Declarations

  let sStatus = "status";
  let sUserName = "userName";
  let sLoginDate = "loginDate";
  let sUserRole = "userRole";

  // change methods for search filters
  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
    sessionStorage.setItem(sStatus, e.target.value);
  };
  const handleChangeUserName = (e) => {
    setUserName(e.target.value);
    sessionStorage.setItem(sUserName, e.target.value);
  };

  const handleChangeLoginDate = (value) => {
    setLoginDate(value);
    sessionStorage.setItem(sLoginDate, value);
  };

  const handleChangeUserRole = (e) => {
    setUserRole(e.target.value);
    sessionStorage.setItem(sUserRole, e.target.value);
  };

  const handleClearSessionStorage = () => {
    sessionStorage.removeItem(sStatus);
    sessionStorage.removeItem(sUserName);
    sessionStorage.removeItem(sLoginDate);
    sessionStorage.removeItem(sUserRole);
  };

  const handleSetSessionStorage = () => {
    let ssStatus = sessionStorage.getItem(sStatus);
    if (ssStatus) {
      setStatus(ssStatus);
      setHasFiltered(true);
    }
    let ssUserName = sessionStorage.getItem(sUserName);
    if (ssUserName) {
      setUserName(ssUserName);
      setHasFiltered(true);
    }
    let ssLoginDate = sessionStorage.getItem(sLoginDate);
    if (ssLoginDate) {
      setLoginDate(ssLoginDate);
      setHasFiltered(true);
    }

    let sSUserRole = sessionStorage.getItem(sUserRole);
    if (sSUserRole) {
      setUserRole(sSUserRole);
      setHasFiltered(true);
    }

    // Updating URL

    if (userName) {
      if (!filterURL.includes(sUserName)) {
        filterURL = filterURL + `&search=${userName}`;
      }
    }
    if (userRole) {
      if (!filterURL.includes(sUserRole)) {
        filterURL = filterURL + `&role=${userRole}`;
      }
    }
    if (status !== "") {
      if (!filterURL.includes(sStatus)) {
        filterURL = filterURL + `&status=${status}`;
      }
    }

    if (loginDate) {
      if (!filterURL.includes(sLoginDate)) {
        filterURL =
          filterURL + `&lastLoginDate=${dayjs(loginDate).format("DD/MM/YYYY")}`;
      }
    }
  };
  const handleResetFields = () => {
    setStatus("");
    setUserName("");
    setUserRole("");
    setLoginDate(null);
  };

  const handleOpenPreviewAdminDialog = () => {
    setOpenPreviewAdminDialog(true);
    setOpenToggleDialog(false);
  };
  const handleClosePreviewAdminDialog = () => {
    setOpenPreviewAdminDialog(false);
  };

  React.useEffect(() => {
    handleSetSessionStorage();
    return () => {
      handleClearSessionStorage();
    };
  }, []);

  const handleSubmitToggleAdminStatus = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    let values = {
      status: selectedUser?.status ? false : true,
    };
    values.adminId = selectedUser?.adminId;
    axios
      .post(`${process.env.BASE_URL}admin/toggle-account-status`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data?.message);
        setIsSubmitting(false);
        if (res?.data?.message) {
          return handleOpenSnackbar({
            message: res?.data?.message,
            severity: "success",
          });
        }

        mutate(url);
        return handleCloseToggleAdminDialog();
      })
      .catch((err) => {
        setIsSubmitting(false);

        if (err?.response?.data?.responsemessage?.msg) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage?.msg,
            severity: "error",
          });
        }
        if (err?.response?.data?.responsemessage) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage,
            severity: "error",
          });
        }
        handleOpenSnackbar({
          message: "Sorry, Admin Could Not Be Deactivated",
          severity: "error",
        });
      });
  };

  const fetcher = () => {
    handleSetSessionStorage();
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data;
      });
  };

  const handleFiterSubmit = () =>
    mutate(filterURL, async () => {
      try {
        handleSetSessionStorage();
        checkExpiredAuthToken();
        const token = getAuthToken();
        setPage(1);
        setIsFiltering(true);
        const res = await axios.get(filterURL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setIsFiltering(false);
        setHasFiltered(true);
        return res.data?.data;
      } catch (err) {
        setIsFiltering(false);
        return err;
      }
    });

  const handleChangePage = (event, newPage) => {
    setIsFiltering(true);
    handleSetSessionStorage();
    setPage(newPage + 1);
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsFiltering(false);
        mutate(filterURL, res?.data?.data);
      })
      .catch((err) => {
        setIsFiltering(false);
        console.log(err);
      });
  };
  const handleCancelFilters = () => {
    handleClearSessionStorage();
    handleResetFields();
    handleResetFields();
    setHasFiltered(false);
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return mutate(url, res.data?.data, false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const { data, error } = useSWRImmutable(filterURL, fetcher);

  if (error)
    return (
      <FullScreenError
        title=" Sorry, can't retrieve admins data at this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;
  return (
    <AdminLayout>
      <MetaDecorator title="Admins Table - Merrybet Lotto Admin Dashboard" />
      <Container maxWidth="xl">
        <PageHeading title="Admins" />
        {openPreviewAdminDialog ? (
          <PreviewAdminModal
            openDialog={openPreviewAdminDialog}
            handleCloseDialog={handleClosePreviewAdminDialog}
            selectedUser={selectedUser}
          />
        ) : null}
        {openToggleDialog ? (
          <ToggleAdminDialog
            openDialog={openToggleDialog}
            handleCloseDialog={handleCloseToggleAdminDialog}
            handleSubmit={handleSubmitToggleAdminStatus}
            isSubmitting={isSubmitting}
            selectedUser={selectedUser}
          />
        ) : null}
        <AdminsAnalytics />
        <AdminsFilter
          status={status}
          userName={userName}
          userRole={userRole}
          loginDate={loginDate}
          handleChangeStatus={handleChangeStatus}
          handleChangeLoginDate={handleChangeLoginDate}
          handleChangeUserName={handleChangeUserName}
          handleChangeUserRole={handleChangeUserRole}
          handleFiterSubmit={handleFiterSubmit}
        />
        {hasFiltered && <ClearFilters handleClick={handleCancelFilters} />}
        <Box sx={AddButtonBoxStyles}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("add")}
          >
            Add Admin
          </Button>
        </Box>
        <AdminsTable
          hasFiltered={hasFiltered}
          isFiltering={isFiltering}
          page={page}
          data={data?.data}
          totalCount={data?.totalCount}
          handleChangePage={handleChangePage}
          selectedUser={selectedUser}
          handleSelectUser={handleSelectUser}
          handleOpenToggleAdminDialog={handleOpenToggleAdminDialog}
          handleOpenPreviewAdminDialog={handleOpenPreviewAdminDialog}
        />
      </Container>
    </AdminLayout>
  );
}

export default Admins;
