import React from "react";
import { useTheme } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AdminLayout from "../components/shared/AdminLayout/AdminLayout";
import MetaDecorator from "../components/shared/MetaDecorator/MetaDecorator";
import PageHeading from "../components/shared/PageHeading/PageHeading";
import OSAnalytics from "../components/pages/DeviceAnalytics/OSAnalytics";
import BrowserAnalytics from "../components/pages/DeviceAnalytics/BrowserAnalytics";
import PlatformAnalytics from "../components/pages/DeviceAnalytics/PlatformAnalytics";

export default function DeviceAnalytics() {
  const theme = useTheme();

  const headerStyles = {
    color: theme.palette.primary.main,
    fontWeight: 600,
  };
  return (
    <AdminLayout>
      <MetaDecorator title="Device Analytics - Admin" />
      <Container maxWidth="xl">
        <PageHeading title="App Analytics" />
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <OSAnalytics headerStyles={headerStyles} />
          </Grid>
          <Grid item xs={12} md={6}>
            <BrowserAnalytics headerStyles={headerStyles} />
          </Grid>
          <Grid item xs={12} md={6}>
            <PlatformAnalytics headerStyles={headerStyles} />
          </Grid>
        </Grid>
      </Container>
    </AdminLayout>
  );
}
