import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import NoDataAvailable from "../../../shared/EmptyTable/EmptyTable";
import { downloadBoxStyles, headCellsTwo } from "../data";
import DailySalesTotalRow from "./DailySalesTotalRow";
import DownloadCSVButton from "../DownloadCSVButton";
import { currencyFormater } from "../../../../utils";

function MerryIndoorGameV2Table({ data }) {
  return (
    <Box sx={{ padding: "10px 2px", marginBottom: "10px" }} component={Paper}>
      <Box mb={2} sx={downloadBoxStyles}>
        {data?.length > 0 && <DownloadCSVButton data={data} />}
      </Box>
      <Typography align="center" variant="h6" gutterBottom color="primary">
        MERRY INDOOR GAME SALES REPORT
      </Typography>
      <TableContainer>
        <Table size="small" sx={{ minWidth: 300 }} aria-label="sales table">
          <TableHead>
            <TableRow>
              {headCellsTwo.map((item, idx) => (
                <TableCell
                  key={item}
                  component="th"
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data?.length > 0 &&
              data?.map((row, idx) => (
                <TableRow key={`${idx}eeee`}>
                  <TableCell>{row?.createdOn}</TableCell>
                  <TableCell>
                    <div>&#8358;{currencyFormater(row?.totalSold)}</div>
                  </TableCell>
                  <TableCell>
                    <div>{currencyFormater(row?.ticketCount)}</div>
                  </TableCell>
                  <TableCell>
                    <div>&#8358;{currencyFormater(row?.totalCommission)}</div>
                  </TableCell>
                  <TableCell>
                    <div>{currencyFormater(row?.commissionCount)}</div>
                  </TableCell>

                  <TableCell>
                    <div>&#8358;{currencyFormater(row?.totalWon)}</div>
                  </TableCell>
                  <TableCell>
                    <div>&#8358;{currencyFormater(row?.profit)}</div>
                  </TableCell>
                </TableRow>
              ))}
            {data && data?.length > 0 && <DailySalesTotalRow data={data} />}
          </TableBody>
        </Table>
        {data && data?.length <= 0 && <NoDataAvailable />}
      </TableContainer>
    </Box>
  );
}

export default MerryIndoorGameV2Table;
