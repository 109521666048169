import React from "react";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import axios from "axios";
import useSWR from "swr";
import {
  checkExpiredAuthToken,
  getAuthToken,
  dialogLoaderStyles,
  previewSubTitleStyles,
  dialogMainTextStyles,
  previewDialogMainTitleStyle,
  currencyFormater,
} from "../../../../utils";
import FullScreenError from "../../../shared/FullScreenError/FullScreenError";
import FullScreenLoader from "../../../shared/FullScreenLoader/FullScreenLoader";

export default function PreviewOverdraftModal({
  openDialog,
  handleCloseDialog,
  selectedTransaction,
}) {
  const url = `${process.env.BASE_URL}transactions/fetch-transaction/${selectedTransaction?.transactionId}`;

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data?.data;
      });
  };

  const { data, error } = useSWR(url, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  if (error)
    return (
      <Dialog open={openDialog} fullWidth={true} maxWidth={"md"}>
        <DialogContent>
          <Box style={{ dialogLoaderStyles }}>
            <FullScreenError
              title=" Sorry, transaction data cannot be retrieved at this moment"
              subtitle={error.message}
            />
          </Box>
        </DialogContent>
      </Dialog>
    );
  if (!data)
    return (
      <Dialog open={true} fullWidth={true} maxWidth={"md"}>
        <DialogContent>
          <Box style={{ dialogLoaderStyles }}>
            <FullScreenLoader />
          </Box>
        </DialogContent>
      </Dialog>
    );

  return (
    <div>
      <Dialog
        open={openDialog}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleCloseDialog}
      >
        <DialogTitle color="success" sx={previewDialogMainTitleStyle}>
          OVERDRAFT PREVIEW
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box my={1}>
                  <Typography gutterBottom sx={previewSubTitleStyles}>
                    Agent Information
                  </Typography>
                  <Box my={1}>
                    <Typography variant="caption">Name:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.User?.firstname} &nbsp; {data?.User?.lastname}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Email:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.User?.email}{" "}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Phone Number:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.User?.phone}{" "}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Agent Role:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.User?.role}{" "}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Player Status:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.User?.status ? (
                        <Chip color="success" label="Active" size="small" />
                      ) : (
                        <Chip label="Not Active" color="error" size="small" />
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box my={1}>
                  <Typography gutterBottom sx={previewSubTitleStyles}>
                    Transaction Information
                  </Typography>
                  <Box my={1}>
                    <Typography variant="caption">Amount:</Typography>

                    <Typography sx={dialogMainTextStyles} align="left">
                      {currencyFormater(data?.amount)}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Transaction Type:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.transactionType}{" "}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Reference ID:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.referenceId}{" "}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">
                      Transaction Status:
                    </Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.status}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">
                      Transaction Narration:
                    </Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.narration}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Tranaction ID:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.transactionId}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
