import React, { useState, useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/CardContent";
import MenuItem from "@mui/material/MenuItem";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Formik, Form } from "formik";
import * as yup from "yup";
import axios from "axios";
import AdminLayout from "../components/shared/AdminLayout/AdminLayout";
import MetaDecorator from "../components/shared/MetaDecorator/MetaDecorator";
import PageHeading from "../components/shared/PageHeading/PageHeading";
import { AppContext } from "../context";
import {
  labelStyles,
  getAuthToken,
  checkExpiredAuthToken,
  BannerCategories,
  imageBox,
} from "../utils";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import FullScreenLoader from "../components/shared/FullScreenLoader/FullScreenLoader";
import FullScreenError from "../components/shared/FullScreenError/FullScreenError";

const WIDTH = 70;
const HEIGHT = 70;
let initialValues = {
  title: "",
  content: "",
  imageUrl: "",
  category: "",
  url: "",
  ctaText: "",
};

let bannerSchema = yup.object().shape({
  title: yup.string().required().label("Title"),
  content: yup.string().required().label("Content"),
  // imageUrl: yup.string().required().label("Image URL"),
  category: yup.string().required(),
  url: yup.string().required(),
});

function EditBanner() {
  const [backgroundImagePreview, setBackgroundImagePreview] = useState("");
  const [image, setImage] = useState("");
  const [foregroundImage, setForegroundImage] = useState("");
  const [foregroundImagePreview, setForegroundImagePreview] = useState("");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [errorValue, setErrorValue] = useState({});

  const { bannerId } = useParams();

  const { handleOpenSnackbar } = useContext(AppContext);
  const handleSubmitImage = async (value, errorMessage) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    const formData = new FormData();
    formData.append("image", value);
    try {
      const res = await axios.post(`${process.env.BASE_URL}upload`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res?.data?.data?.data?.imageUrl;
    } catch (error) {
      console.log(error);
      handleOpenSnackbar({
        message: errorMessage,
        severity: "error",
      });
    }
  };

  useEffect(() => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    axios
      .get(`${process.env.BASE_URL}banner/fetch-banner/${bannerId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setData(res.data?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setErrorValue(err);
      });
  }, []);

  const handleSubmit = async (values, helpers) => {
    checkExpiredAuthToken();
    const token = getAuthToken();

    if (image) {
      const background = await handleSubmitImage(
        image,
        "Sorry, an error occured while uploading the background image"
      );
      values.imageUrl = background;
    }
    if (foregroundImage) {
      const foreground = await handleSubmitImage(
        foregroundImage,
        "Sorry, an error occured while uploading the foreground image"
      );
      values.foregroundImage = foreground;
    }
    axios
      .put(`${process.env.BASE_URL}banner/update-banner/${bannerId}`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        handleOpenSnackbar({
          message: "Banner Updated Successfully",
          severity: "success",
        });
        helpers.setSubmitting(false);
      })
      .catch((err) => {
        console.log(err);
        handleOpenSnackbar({
          message: "Sorry, Banner Could Not Be Updated",
          severity: "error",
        });
        helpers.setSubmitting(false);
      });
  };

  if (errorValue && errorValue?.message) {
    return (
      <FullScreenError
        title="Sorry, the requested data cannot be fetched at this moment"
        subtitle={errorValue?.message}
      />
    );
  }
  if (loading) {
    return <FullScreenLoader />;
  }
  // console.log("ddddddddd", data);
  if (!loading && data?.title) {
    initialValues.title = data?.title;
    initialValues.content = data?.content;
    initialValues.imageUrl = data?.imageUrl;
    initialValues.category = data?.category;
    initialValues.url = data?.url;
    initialValues.ctaText = data?.ctaText;
    initialValues.foregroundImage = data?.foregroundImage;
  }
  return (
    <AdminLayout>
      <MetaDecorator title="Update Banner - Admin" />
      <Container maxWidth="xl">
        <PageHeading title="Update Banner" />
        <Container maxWidth="xl">
          <Container maxWidth="xl">
            <Box my={1} sx={imageBox}>
              <FormControl variant="outlined">
                <p>Select background image to upload</p>
                <input
                  accept="image/*"
                  type="file"
                  variant="outlined"
                  onChange={(e) => {
                    setImage(e.target.files[0]);
                    setBackgroundImagePreview(
                      URL.createObjectURL(e.target.files[0])
                    );
                  }}
                />
              </FormControl>
              <div>
                {backgroundImagePreview ? (
                  <img
                    src={backgroundImagePreview}
                    alt="uploaded background"
                    width={WIDTH}
                    height={HEIGHT}
                  />
                ) : (
                  <img
                    src={initialValues?.imageUrl}
                    alt="uploaded background"
                    width={WIDTH}
                    height={HEIGHT}
                  />
                )}
              </div>
            </Box>
            <Box my={1} sx={imageBox}>
              <FormControl variant="outlined">
                <p>Select foreground image to upload</p>
                <input
                  accept="image/*"
                  type="file"
                  variant="outlined"
                  onChange={(e) => {
                    setForegroundImage(e.target.files[0]);
                    setForegroundImagePreview(
                      URL.createObjectURL(e.target.files[0])
                    );
                  }}
                />
              </FormControl>
              <div>
                {foregroundImagePreview ? (
                  <img
                    src={foregroundImagePreview}
                    alt="uploaded foreground"
                    width={WIDTH}
                    height={HEIGHT}
                  />
                ) : (
                  <img
                    src={initialValues?.foregroundImage}
                    alt="uploaded foreground"
                    width={WIDTH}
                    height={HEIGHT}
                  />
                )}
              </div>
            </Box>
          </Container>
          <br />
        </Container>
        <br />
        <br />
        <Container maxWidth="xl">
          <div>
            <Formik
              initialValues={initialValues}
              validationSchema={bannerSchema}
              enableReinitialize={true}
              validateOnBlur={true}
              validateOnChange={true}
              onSubmit={(values, helpers) => handleSubmit(values, helpers)}
            >
              {({
                handleChange,
                handleBlur,
                touched,
                errors,
                setFieldValue,
                isSubmitting,
                values,
              }) => (
                <Form noValidate>
                  <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel variant="outlined" sx={labelStyles}>
                        Enter the banner title
                      </InputLabel>
                      <TextField
                        error={touched.title && errors.title}
                        value={values?.title || ""}
                        name="title"
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={touched.title && errors.title}
                      />
                    </FormControl>
                  </Box>
                  <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel
                        variant="outlined"
                        id="roleSelect"
                        sx={labelStyles}
                      >
                        Category
                      </InputLabel>
                      <Select
                        name="category"
                        value={values?.category || ""}
                        labelId="roleSelect"
                        variant="outlined"
                        id="roleSelect"
                        onChange={handleChange}
                      >
                        {BannerCategories.map((item) => (
                          <MenuItem key={item?.value} value={item?.value}>
                            {item?.title}{" "}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel variant="outlined" sx={labelStyles}>
                        Enter the content for the banner
                      </InputLabel>
                      <TextField
                        error={touched.content && errors.content}
                        value={values?.content || ""}
                        name="content"
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={touched.content && errors.content}
                      />
                    </FormControl>
                  </Box>
                  <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel variant="outlined" sx={labelStyles}>
                        Base URL (e.g https://example.com)
                      </InputLabel>
                      <TextField
                        error={touched.url && errors.url}
                        value={values?.url || ""}
                        name="url"
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={touched.url && errors.url}
                      />
                    </FormControl>
                  </Box>
                  <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel variant="outlined" sx={labelStyles}>
                        Call To Action Text
                      </InputLabel>
                      <TextField
                        error={touched.ctaText && errors.ctaText}
                        value={values?.ctaText || ""}
                        name="ctaText"
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={touched.ctaText && errors.ctaText}
                      />
                    </FormControl>
                  </Box>

                  <br />
                  <Button
                    disabled={isSubmitting}
                    startIcon={
                      isSubmitting && <CircularProgress size={"1rem"} />
                    }
                    type="submit"
                    size="large"
                    variant="contained"
                    color="primary"
                  >
                    {isSubmitting ? "Updating Banner" : "Update Banner"}
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      </Container>
    </AdminLayout>
  );
}

export default EditBanner;
