import { createRoot } from "react-dom/client";
import React from "react";
import { App } from "./App";
import { CookiesProvider } from "react-cookie";
import { AppProvider } from "./context";
import CustomizedSnackbars from "./components/shared/CustomSnackbar/CustomSnackbar";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./utils/theme";

const container = document.getElementById("app");
const root = createRoot(container);
root.render(
  <>
    <React.StrictMode>
      <CookiesProvider>
        <AppProvider>
          <ThemeProvider theme={theme}>
            <CustomizedSnackbars />
            <CssBaseline />
            <App />
          </ThemeProvider>
        </AppProvider>
      </CookiesProvider>
    </React.StrictMode>
  </>
);
