import React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import dayjs from "dayjs";
import TableLoader from "../../shared/TableLoader/TableLoader";
import NoDataAvailable from "../../shared/EmptyTable/EmptyTable";
import { useNavigate } from "react-router-dom";
import {
  boldTextCalculationStyles,
  getCommisionSum,
  getWalletSum,
  currencyFormater,
} from "../../../utils";

import Typography from "@mui/material/Typography";

function InfluencersTable({
  totalCount,
  selectedUser,
  rowsPerPage,
  rowsPerPageOptions,
  isFiltering,
  handleChangePage,
  handleSelectUser,
  handleOpenDialog,
  handleChangeRowsPerPage,
  handleOpenDeactivateUserDialog,
  handleOpenActivateUserDialog,
  handleOpenFundUserDialog,
  handleOpenDebitAccountDialog,
  page,
  data,
  hasFiltered,
}) {
  const [selectedId, setSelectedId] = React.useState(""); // For user ID when the more icon is clicked
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const navigate = useNavigate();

  const open = Boolean(anchorEl);

  const handleClick = (event, user) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(user?.userId);
    handleSelectUser(user);
  };

  const handleOpenRoleDialog = (userId) => {
    setOpenDialog(true);
    setSelectedId(userId);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedId("");
  };

  const handleCloseDialog = () => setOpenDialog(false);

  const moveToUserDetailsPage = () => {
    navigate(`/players/player-details/${selectedId}`);
  };
  const moveToAddBundlePage = () => {
    navigate(`/influencers/add-bundle?influencerId=${selectedId}`);
  };

  const headCells = [
    "First Name",
    "Last Name",
    "Email",
    "Phone",
    "Role",
    "Date Joined",
    "Status",
    "Wallet Balance",
    "Commission Balance",
    "Actions",
  ];
  const headers = [
    "First Name",
    "Last Name",
    "Email",
    "Phone",
    "Role",
    "Date Joined",
    "Status",
    "Wallet Balance",
    "Commission Balance",
  ];
  let rows = [];
  if (data?.length > 0) {
    data?.forEach((item) => {
      rows.push([
        item?.firstname,
        item?.lastname,
        item?.email,
        item?.phone,
        item?.role,
        item?.createdAt,
        item?.status,
        item?.walletBalance,
        item?.commissionBalance,
      ]);
    });

    rows = [headers, ...rows];
  }
  return isFiltering ? (
    <TableLoader />
  ) : (
    <Box sx={{ padding: "10px 0px", marginBottom: "10px" }}>
      {/* {data?.length > 0 ? (
        <TableCSVButton data={rows} filename="players.csv" />
      ) : null} */}
      {/* <AddRoleDialog
        handleCloseDialog={handleCloseDialog}
        openDialog={openDialog}
        selectedUser={selectedId}
      /> */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }} aria-label="recent users table">
          <TableHead>
            <TableRow>
              {headCells.map((item) => (
                <TableCell key={item} component="th" sx={{ fontWeight: 600 }}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data?.length > 0 &&
              data?.map((row) => (
                <TableRow key={row.userId}>
                  <TableCell>{row.firstname}</TableCell>
                  <TableCell>{row.lastname}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.phone}</TableCell>
                  <TableCell>{row.role}</TableCell>
                  <TableCell style={{ width: 160 }}>
                    {dayjs(row.createdAt).format("MMM D, YYYY")}
                  </TableCell>
                  <TableCell style={{ width: 160 }}>
                    {row.status ? (
                      <Chip color="success" label="Active" size="small" />
                    ) : (
                      <Chip label="Not Active" color="error" size="small" />
                    )}
                  </TableCell>
                  <TableCell>
                    <Typography align="left">
                      NGN{currencyFormater(row?.walletBalance)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography align="left">
                      NGN{currencyFormater(row?.commissionBalance)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={(e) => handleClick(e, row)}>
                      <MoreHorizIcon />
                    </IconButton>
                    <Menu
                      row={row}
                      anchorEl={anchorEl}
                      PaperProps={{
                        elevation: 1,
                        sx: { boxShadow: "2px 2px 4px #eee" },
                      }}
                      open={open}
                      onClose={handleClose}
                    >
                      {/* <MenuItem
                        onClick={() => handleOpenFundUserDialog()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        Fund Account
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onClick={() => handleOpenDebitAccountDialog()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        Debit Account
                      </MenuItem>
                      <Divider />
                      {selectedUser?.status ? (
                        <MenuItem
                          onClick={() => handleOpenDeactivateUserDialog()}
                          sx={{ fontSize: "12.7px" }}
                        >
                          Deactivate Account
                        </MenuItem>
                      ) : null} */}
                      {/* <Divider />
                      {!Boolean(selectedUser?.status) ? (
                        <MenuItem
                          onClick={() => handleOpenActivateUserDialog()}
                          sx={{ fontSize: "12.7px" }}
                        >
                          Activate Account
                        </MenuItem>
                      ) : null} */}
                      {/* <Divider /> */}
                      <MenuItem
                        onClick={() => handleOpenDialog()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        Preview Player
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onClick={() => moveToUserDetailsPage()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        View Player Details
                      </MenuItem>{" "}
                      <Divider />
                      <MenuItem
                        onClick={() => moveToAddBundlePage()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        Add Bundle
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            {data && data?.length > 0 && (
              <TableRow>
                <TableCell sx={boldTextCalculationStyles} colSpan={7}>
                  Total
                </TableCell>
                <TableCell align="right">
                  <Typography sx={boldTextCalculationStyles} align="left">
                    NGN{currencyFormater(getWalletSum(data))}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography sx={boldTextCalculationStyles} align="left">
                    NGN{currencyFormater(getCommisionSum(data))}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {data && data?.length <= 0 && <NoDataAvailable />}
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}

export default InfluencersTable;
