import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import useSWR, { mutate } from "swr";
import AdminLayout from "../components/shared/AdminLayout/AdminLayout";
import MetaDecorator from "../components/shared/MetaDecorator/MetaDecorator";
import PageHeading from "../components/shared/PageHeading/PageHeading";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  getAuthToken,
  checkExpiredAuthToken,
  detailsActionBoxStyles,
} from "../utils";
import FullScreenLoader from "../components/shared/FullScreenLoader/FullScreenLoader";
import FullScreenError from "../components/shared/FullScreenError/FullScreenError";
import PlayerInformation from "../components/pages/WitdrawalRequestDetailsComponents/PlayerInformation";
import WithdrawalInformation from "../components/pages/WitdrawalRequestDetailsComponents/WithdrawalInformation";
import ApproveWithdrawalDialog from "../components/pages/WithdrawalRequestsComponents/ApproveWithdrawalDialog";
import { AppContext } from "../context";

function WithdrawalRequestDetails() {
  const { requestId } = useParams();
  const [openApproveDialog, setOpenApproveDialog] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const { handleOpenSnackbar } = React.useContext(AppContext);

  const url = `${process.env.BASE_URL}transactions/fetch-withdrawal-request/${requestId}`;

  const handleCloseApproveDialog = () => setOpenApproveDialog(false);
  const handleOpenApproveDialog = () => setOpenApproveDialog(true);

  const handleSubmitApproveRequest = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    let values = {
      requestId: requestId,
    };

    axios
      .post(`${process.env.BASE_URL}admin/approve-withdrawal-request`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsSubmitting(false);
        mutate(url);
        if (res?.data?.message) {
          handleCloseApproveDialog();
          return handleOpenSnackbar({
            message: res?.data?.message,
            severity: "success",
          });
        }
        handleOpenSnackbar({
          message: "Withdrawal Request Have Been Successfully Approved",
          severity: "success",
        });

        return handleCloseApproveDialog();
      })
      .catch((err) => {
        setIsSubmitting(false);

        if (err?.response?.data?.data?.message) {
          return handleOpenSnackbar({
            message: err?.response?.data?.data?.message,
            severity: "error",
          });
        }
        if (err?.response?.data?.responsemessage) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage,
            severity: "error",
          });
        }
        handleOpenSnackbar({
          message: "Sorry, Withdrawal Request Could Not Be Approved",
          severity: "error",
        });
      });
  };
  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data?.data?.data);
        return res?.data?.data?.data;
      });
  };
  const { data, error } = useSWR(url, fetcher);

  if (error)
    return (
      <FullScreenError
        title="Sorry, we can't retrieve the requested data this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  //   console.log("dddddddddddddddd", data);

  return (
    <AdminLayout>
      <MetaDecorator
        title={`Withdrawal Request details - Admin Dashboard Merrybet Lotto`}
      />
      <ApproveWithdrawalDialog
        openDialog={openApproveDialog}
        isSubmitting={isSubmitting}
        handleCloseDialog={handleCloseApproveDialog}
        handleSubmit={handleSubmitApproveRequest}
      />
      <Container maxWidth="xl">
        <PageHeading title="Withdrawal's details" />
        <Grid container spacing={3}>
          <PlayerInformation data={data} />
          <WithdrawalInformation data={data} />
        </Grid>
        <br />
        <Box sx={detailsActionBoxStyles}>
          <Typography>Approve player's withdrawal request</Typography>
          <Button
            disabled={data?.isApproved}
            size="small"
            color="primary"
            variant="contained"
            onClick={() => handleOpenApproveDialog()}
          >
            Approve Request
          </Button>
        </Box>
      </Container>
    </AdminLayout>
  );
}

export default WithdrawalRequestDetails;
