import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ReportChart from "../ReportChart";
import useSWR from "swr";
import axios from "axios";
import {
  checkExpiredAuthToken,
  getAuthToken,
  getSumFromObj,
} from "../../../../utils";
import TableLoader from "../../../shared/TableLoader/TableLoader";
import HalfErrorScreen from "../../../shared/HalfErrorScreen/HalfErrorScreen";

import ReportTable from "../DailyReports/ReportTable";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import weekYear from "dayjs/plugin/weekYear"; // dependent on weekOfYear plugin
import weekOfYear from "dayjs/plugin/weekOfYear";
import "../playerreports.css";
import { useParams } from "react-router-dom";
import NoDataAvailable from "../../../shared/EmptyTable/EmptyTable";
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

dayjs().weekYear();
dayjs.extend(weekday);

let months = [];

for (let index = 0; index < 12; index++) {
  months.push(dayjs().month(index));
}

const MonthlySalesView = ({ value }) => {
  const [selectedMonth, setSelectedMonth] = useState(dayjs());
  const params = useParams();
  const url = `${
    process.env.BASE_URL
  }admin/fetch-specific-single-player-report?interval=monthly&userId=${
    params?.userId
  }&specificDate=${selectedMonth?.format("MMM YYYY")}`;
  const handleChange = (value) => {
    setSelectedMonth((prev) => value);
  };

  useEffect(() => {
    setSelectedMonth((curr) => dayjs());
  }, []);

  const TabsSection = () => (
    <Box display="flex" alignItems="center" justifyContent="center">
      {months.map((item, idx) => (
        <Button
          onClick={() => handleChange(item)}
          key={idx}
          variant={
            item?.format("MMM YYYY") === selectedMonth?.format("MMM YYYY")
              ? "contained"
              : "text"
          }
          disabled={item?.isAfter(dayjs(), "month")}
        >
          {`${item?.format("MMM")}`}
        </Button>
      ))}
    </Box>
  );
  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data?.data?.data);
        return res?.data?.data?.data;
      });
  };
  const { data, error } = useSWR(url, fetcher);

  if (error)
    return (
      <HalfErrorScreen
        title="Sorry, we can't retrieve the requested data this moment"
        subtitle={error.message}
      />
    );

  if (typeof data === "boolean" && data === false)
    return (
      <>
        <TabsSection />
        <NoDataAvailable />;
      </>
    );

  if (!data) return <TableLoader />;

  let stakedAmount, winnings;
  let dataSummary = {};
  // console.log("data", data);
  stakedAmount = getSumFromObj(data?.totalStakedAmount);
  winnings = getSumFromObj(data?.totalWinAmount);
  // console.log("winnings", winnings);

  dataSummary["staked amount"] = stakedAmount;
  dataSummary.winnings = winnings;

  // console.log("datasummary", dataSummary);

  return (
    <>
      <TabsSection />
      <br />
      <Box mb={2}>
        <ReportChart data={dataSummary} />
      </Box>
      <ReportTable data={data} value={value} />
    </>
  );
};

export default MonthlySalesView;
