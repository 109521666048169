import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import useSWR from "swr";
import axios from "axios";
import { GiTabletopPlayers } from "react-icons/gi";
import { AiFillDislike } from "react-icons/ai";
import { MdThumbUp } from "react-icons/md";
import { RiDeleteBackFill } from "react-icons/ri";
import { checkExpiredAuthToken, getAuthToken } from "../../../utils";
import "../../../styles/analytics.css";
import HalfErrorScreen from "../../shared/HalfErrorScreen/HalfErrorScreen";
import AnalyticsCard from "../../shared/AnalyticsCard/AnalyticsCard";

const url = `${process.env.BASE_URL}agent/analytics`;

function AgentsAnalytics() {
  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data?.data;
      });
  };

  const { data, error } = useSWR(url, fetcher, {});

  if (error)
    return (
      <HalfErrorScreen
        title=" Sorry, agents analytics data cannot be retrieved at this moment"
        subtitle={error.message}
      />
    );

  if (!data) return null;
  return (
    <Box mb={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={3}>
          <AnalyticsCard
            mainNumber={data && data?.totalAgents}
            icon={<GiTabletopPlayers className="cardIcon" />}
            text={data?.totalAgents <= 1 ? "Agent" : "Total Agents"}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <AnalyticsCard
            mainNumber={data && data?.suspendedAgents}
            icon={<AiFillDislike className="cardIcon" />}
            text={
              data && data?.suspendedAgents <= 1
                ? "Suspended Agent"
                : "Suspended Agents"
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <AnalyticsCard
            mainNumber={data && data?.totalAgents - data?.suspendedAgents}
            icon={<MdThumbUp className="cardIcon" />}
            text={
              data && data?.totalAgents - data?.suspendedAgents <= 1
                ? "Active Agent"
                : "Active Agents"
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <AnalyticsCard
            error={true}
            mainNumber={data && data?.deletedAgents}
            icon={<RiDeleteBackFill className="errorCardIcon" />}
            text={
              data && data?.deletedAgents <= 1
                ? "Deleted Agent"
                : "Deleted Agents"
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default AgentsAnalytics;
