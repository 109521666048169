import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ReportChart from "../ReportChart";
import useSWR from "swr";
import axios from "axios";
import {
  checkExpiredAuthToken,
  getAuthToken,
  getSumFromObj,
  GHANA_GAME,
  GHANA_GAME_V2,
  META4,
  METRO,
  PERFECT_BALLS,
  POOL_OF_FAME,
  SEVEN49,
  MERRY_INDOOR_GAME,
  MERRY_INDOOR_GAME_V2,
} from "../../../../utils";
import FormLoader from "../../../shared/FormLoader/FormLoader";
import HalfErrorScreen from "../../../shared/HalfErrorScreen/HalfErrorScreen";
import Meta4Table from "./Meta4Table";
import GhanaGameTable from "./GhanaGameTable";
import PerfectBallsTable from "./PerfectBallsTable";
import MerryIndoorGameTable from "./MerryIndoorGameTable";
import Seven49Table from "./Seven49Table";
import PoolOfFameTable from "./PoolOfFameTable";
import GhanaGameV2Table from "./GhanaGameV2Table";
import MerryIndoorGameV2Table from "./MerryIndoorGameV2Table";

const DailySalesView = ({ value }) => {
  const url = `${process.env.BASE_URL}admin/fetch-sales-report?interval=daily`;

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data?.data?.data);
        return res?.data?.data?.data;
      });
  };
  const { data, error } = useSWR(url, fetcher);

  if (error)
    return (
      <HalfErrorScreen
        title="Sorry, we can't retrieve the requested data this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FormLoader />;

  // console.log("ddddddddddddd", data);
  let sales, winnings, commission, profit;
  let dataSummary = {};
  sales = getSumFromObj(data?.totalSales);
  winnings = getSumFromObj(data?.totalWinnings);
  commission = getSumFromObj(data?.totalCommissions);
  profit = getSumFromObj(data?.totalProfits);

  dataSummary.sales = sales;
  dataSummary.winnings = winnings;
  dataSummary.commission = commission;
  dataSummary.profit = profit;

  return (
    <>
      <Box mb={2}>
        <ReportChart data={dataSummary} />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Meta4Table data={data?.categories[META4]} value={value} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Seven49Table data={data?.categories[SEVEN49]} value={value} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <PerfectBallsTable
            data={data?.categories[PERFECT_BALLS]}
            value={value}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <PoolOfFameTable data={data?.categories[POOL_OF_FAME]} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <GhanaGameTable data={data?.categories[GHANA_GAME]} value={value} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <GhanaGameV2Table
            data={data?.categories[GHANA_GAME_V2]}
            value={value}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <MerryIndoorGameTable
            data={data?.categories[MERRY_INDOOR_GAME]}
            value={value}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <MerryIndoorGameV2Table
            data={data?.categories[MERRY_INDOOR_GAME_V2]}
            value={value}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DailySalesView;
