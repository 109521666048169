import React, { useContext, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import InputLabel from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import AdminLayout from "../components/shared/AdminLayout/AdminLayout";
import MetaDecorator from "../components/shared/MetaDecorator/MetaDecorator";
import PageHeading from "../components/shared/PageHeading/PageHeading";
import { AppContext } from "../context";
import {
  checkExpiredAuthToken,
  getAuthToken,
  IMAGE_PREVIEW_HEIGHT,
  IMAGE_PREVIEW_WIDTH,
  imageBox,
  imagePreviewStyle,
  labelStyles,
  POOL_OF_FAME,
  WeekdayList,
} from "../utils";
import FullScreenLoader from "../components/shared/FullScreenLoader/FullScreenLoader";
import FullScreenError from "../components/shared/FullScreenError/FullScreenError";

function EditGameInstance() {
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [foregroundImage, setForegroundImage] = useState("");
  const [foregroundImagePreview, setForegroundImagePreview] = useState("");

  const [alternateDate, setAlternateDate] = useState(new Date());
  const { handleOpenSnackbar } = useContext(AppContext);

  const { lotteryId, gameId, gameCategory } = useParams();
  const url = `${process.env.BASE_URL}game/fetch-game/${gameId}`;

  const handleSubmitImage = async (value, errorMessage) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    const formData = new FormData();
    formData.append("image", value);
    try {
      const res = await axios.post(`${process.env.BASE_URL}upload`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res?.data?.data?.data?.imageUrl;
    } catch (error) {
      console.log(error);
      handleOpenSnackbar({
        message: errorMessage,
        severity: "error",
      });
    }
  };

  let initialValues = {
    name: "",
    drawMethod: "",
  };
  let gameSchema = yup.object().shape({
    name: yup.string().required(),
    drawMethod: yup.string().required().label("Draw Method"),
  });

  const fetcher = async () => {
    try {
      checkExpiredAuthToken();
      const token = getAuthToken();
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // console.log(res?.data?.data?.data);
      let startTimeArray = res?.data?.data?.data?.startTime?.split(":");
      let endTimeArray = res?.data?.data?.data?.endTime?.split(":");
      initialValues.name = res?.data?.data?.data?.name;
      initialValues.isRecurring =
        res?.data?.data?.data.recurring === true ? "true" : "false";
      initialValues.drawMethod = res?.data?.data?.data.drawMethod;
      initialValues.description = res?.data?.data?.data?.description;
      initialValues.recurringInterval =
        res?.data?.data?.data?.recurringInterval;
      if (res?.data?.data?.data?.mrf) {
        initialValues.mrf = res?.data?.data?.data?.mrf;
      }
      if (gameCategory !== POOL_OF_FAME) {
        initialValues.dayOfTheWeek = res?.data?.data?.data?.weekday;
      }
      initialValues.totalFundPool =
        res?.data?.data?.data?.totalFundPool &&
        parseFloat(res?.data?.data?.data?.totalFundPool).toFixed(0);
      let optStartNewDate = new Date();
      let optEndNewDate = new Date();
      optStartNewDate.setHours(startTimeArray[0]);
      optStartNewDate.setMinutes(startTimeArray[1]);
      optEndNewDate.setHours(endTimeArray[0]);
      optEndNewDate.setMinutes(endTimeArray[1]);

      setStartTime((curr) => optStartNewDate);
      setEndTime((curr) => optEndNewDate);
      initialValues.startTime = optStartNewDate;
      initialValues.endTime = optEndNewDate;

      if (res?.data?.data?.data?.alternateStartDate) {
        setAlternateDate((prev) => res?.data?.data?.data?.alternateStartDate);
      }
      setData(res?.data?.data?.data);
      // setLoading(false)
      return res?.data?.data?.data;
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validateOnBlur: true,
    validateOnChange: true,
    // enableReinitialize: true,
    onSubmit: async (values, helpers) => {
      checkExpiredAuthToken();
      const token = getAuthToken();

      if (foregroundImage) {
        const foreground = await handleSubmitImage(
          foregroundImage,
          "Sorry, an error occured while uploading the image"
        );
        values.imageUrl = foreground;
      }
      if (gameCategory !== POOL_OF_FAME) {
        delete values["totalFundPool"];
        delete values["alternateStartDate"];
        // delete values["mrf"];
      }

      if (values?.dayOfTheWeek) {
        values.dayOfTheWeek = Number(values.dayOfTheWeek);
      }
      if (values.endTime) {
        values.endTime = dayjs(values?.endTime).format("HH:mm:ss");
      }
      if (values.startTime) {
        values.startTime = dayjs(values?.startTime).format("HH:mm:ss");
      }

      if (values?.isRecurring === "false") {
        values.isRecurring = false;
        values.recurringInterval = 1;
      }
      if (values?.isRecurring === "true") {
        values.isRecurring = true;
      }
      if (gameCategory === POOL_OF_FAME) {
        values.startTime = "00:00";
        values.endTime = "23:59";
        if (!values?.mrf || values?.mrf === "") {
          delete values["mrf"];
        }
        if (
          !values?.alternateStartDate ||
          values?.alternateStartDate === "" ||
          new Date(values?.alternateStartDate)?.toISOString() <=
            new Date().toISOString()
        ) {
          delete values["alternateStartDate"];
        } else {
          values["alternateStartDate"] = new Date(
            values?.alternateStartDate
          )?.toISOString();
        }
      }
      axios
        .put(`${process.env.BASE_URL}game/update-game/${gameId}`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          helpers.setSubmitting(false);
          helpers.resetForm({ values: initialValues });
          if (res?.data?.message) {
            return handleOpenSnackbar({
              message: res?.data?.message,
              severity: "success",
            });
          }
          handleOpenSnackbar({
            message: "Game Updated Successfully",
            severity: "success",
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((err) => {
          // console.log(err?.response?.data?.responsemessage?.msg);
          helpers.setSubmitting(false);
          if (err?.response?.data?.responsemessage?.msg) {
            return handleOpenSnackbar({
              message: err?.response?.data?.responsemessage?.msg,
              severity: "error",
            });
          }
          if (err?.response?.data?.responsemessage) {
            return handleOpenSnackbar({
              message: err?.response?.data?.responsemessage,
              severity: "error",
            });
          }
          handleOpenSnackbar({
            message: "Sorry, Game Could Not Be Updated",
            severity: "error",
          });
        });
    },
    validationSchema: gameSchema,
  });

  useEffect(() => {
    fetcher();
  }, []);

  if (error)
    return (
      <FullScreenError
        title=" Sorry, cant retrieve game data at this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  return (
    <AdminLayout>
      <MetaDecorator title="Update Game Instance - Admin Dashboard" />
      <Container maxWidth="xl">
        <PageHeading title="Update Game Instance" />

        <form onSubmit={formik.handleSubmit}>
          <Box my={1} sx={imageBox}>
            <FormControl variant="outlined">
              <p>Select image to upload</p>
              <input
                accept="image/*"
                type="file"
                variant="outlined"
                onChange={(e) => {
                  setForegroundImage(e.target.files[0]);
                  setForegroundImagePreview(
                    URL.createObjectURL(e.target.files[0])
                  );
                }}
              />
            </FormControl>
            {data?.imageUrl && !foregroundImagePreview ? (
              <img
                src={data?.imageUrl}
                alt="game"
                width={IMAGE_PREVIEW_WIDTH}
                height={IMAGE_PREVIEW_HEIGHT}
                style={imagePreviewStyle}
              />
            ) : (
              <div>
                {foregroundImagePreview && (
                  <img
                    src={foregroundImagePreview}
                    alt="game"
                    width={IMAGE_PREVIEW_WIDTH}
                    height={IMAGE_PREVIEW_HEIGHT}
                    style={imagePreviewStyle}
                  />
                )}
              </div>
            )}
          </Box>
          <Box my={1}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel variant="outlined" sx={labelStyles}>
                Enter Game Title
              </InputLabel>
              <TextField
                error={formik.touched.name && formik.errors.name}
                value={formik.values.name}
                name="name"
                variant="outlined"
                fullWidth
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                helperText={formik.touched.name && formik.errors.name}
              />
            </FormControl>
          </Box>
          {data?.Lottery?.category === POOL_OF_FAME ? null : (
            <Box
              mt={4}
              mb={2}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Stack direction="row" spacing={3}>
                <FormControl>
                  <FormLabel sx={labelStyles}>
                    Is this a Recurring Game?
                  </FormLabel>
                  <RadioGroup
                    value={formik.values.isRecurring}
                    row
                    name="isRecurring"
                    onChange={(e) => {
                      formik.setFieldValue("isRecurring", e.target.value);
                      formik.setFieldValue("startTime", "");
                      setStartTime("");
                      formik.setFieldValue("endTime", "");
                      setEndTime("");
                      formik.setFieldValue("dayOfTheWeek", "");
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio color="primary" name="isRecurring" />}
                      label="Yes, It's recurring"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio color="primary" name="isRecurring" />}
                      label="No, It's not recurring"
                    />
                  </RadioGroup>
                </FormControl>
              </Stack>
              <Stack direction="row" spacing={3}>
                <FormControl>
                  <FormLabel sx={labelStyles}>
                    Select Game Draw Method
                  </FormLabel>
                  <RadioGroup
                    value={formik.values.drawMethod}
                    row
                    name="drawMethod"
                    onChange={formik.handleChange}
                  >
                    <FormControlLabel
                      value="manual"
                      control={<Radio color="primary" name="drawMethod" />}
                      label="Manual"
                    />
                    <FormControlLabel
                      value="RNG"
                      control={<Radio color="primary" name="drawMethod" />}
                      label="RNG"
                    />
                  </RadioGroup>
                </FormControl>
              </Stack>{" "}
              &nbsp;
            </Box>
          )}

          {data?.Lottery?.category === POOL_OF_FAME ? null : (
            <>
              {" "}
              {formik.values?.isRecurring === "false" ? (
                <Box
                  mt={4}
                  mb={2}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Stack direction="row" spacing={3}>
                    <FormControl>
                      <FormLabel sx={labelStyles}>Days of the Week</FormLabel>
                      <RadioGroup
                        value={formik.values.dayOfTheWeek}
                        row
                        name="dayOfTheWeek"
                        onChange={(e) => {
                          formik.setFieldValue("dayOfTheWeek", e.target.value);
                        }}
                      >
                        {WeekdayList?.map((item, idx) => (
                          <FormControlLabel
                            key={idx}
                            value={item?.value}
                            control={
                              <Radio color="primary" name="dayOfTheWeek" />
                            }
                            label={item?.day}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </Stack>
                </Box>
              ) : null}
            </>
          )}
          {data?.Lottery?.category === POOL_OF_FAME ? (
            <Box my={2}>
              <Grid container spacing={2}>
                <Grid xs={12} sm={6} item>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      label="Total Fund Pool"
                      type="number"
                      name="totalFundPool"
                      value={formik.values.totalFundPool}
                      variant="outlined"
                      fullWidth
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.totalFundPool &&
                        formik.errors.totalFundPool
                      }
                      helperText={
                        formik.touched.totalFundPool &&
                        formik.errors.totalFundPool
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid xs={12} sm={6} item>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label="Alternate Draw Date"
                      value={alternateDate}
                      onChange={(value) => {
                        setAlternateDate(value);
                        formik.setFieldValue("alternateStartDate", value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          error={
                            formik.touched.alternateStartDate &&
                            formik.errors.alternateStartDate
                          }
                          helperText={
                            formik.touched.alternateStartDate &&
                            formik.errors.alternateStartDate
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Box>
          ) : null}
          {formik.values?.isRecurring === "false" ? (
            <Box mt={2} mb={1}>
              {data?.Lottery?.category === POOL_OF_FAME ? null : (
                <Grid container spacing={2}>
                  <Grid xs={12} sm={6} item>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label="Start Time"
                        value={startTime}
                        onChange={(value) => {
                          setStartTime(value);
                          formik.setFieldValue("startTime", value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            error={
                              formik.touched.startTime &&
                              formik.errors.startTime
                            }
                            helperText={
                              formik.touched.startTime &&
                              formik.errors.startTime
                            }
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid xs={12} sm={6} item>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label="End Time"
                        value={endTime}
                        onChange={(value) => {
                          setEndTime(value);
                          formik.setFieldValue("endTime", value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            error={
                              formik.touched.endTime && formik.errors.endTime
                            }
                            helperText={
                              formik.touched.endTime && formik.errors.endTime
                            }
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              )}
            </Box>
          ) : null}
          {/* {data?.Lottery?.category === POOL_OF_FAME ? (
                <Box my={1}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      label="Manual Raffle Function"
                      name="mrf"
                      variant="outlined"
                      value={formik.values.mrf}
                      fullWidth
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={formik.touched.mrf && formik.errors.mrf}
                      helperText={formik.touched.mrf && formik.errors.mrf}
                    />
                  </FormControl>
                </Box>
              ) : null} */}
          <Box my={1}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                label="Description"
                name="description"
                variant="outlined"
                value={formik.values.description}
                fullWidth
                multiline
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.description && formik.errors.description}
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />
            </FormControl>
          </Box>

          {formik.values?.isRecurring === "true" ? (
            <Box my={1}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel variant="outlined" sx={labelStyles}>
                  Recuring Interval (In Minutes)
                </InputLabel>
                <TextField
                  disabled={!formik.values?.isRecurring}
                  value={formik.values.recurringInterval}
                  name="recurringInterval"
                  variant="outlined"
                  fullWidth
                  type="number"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.recurringInterval &&
                    formik.errors.recurringInterval
                  }
                  helperText={
                    formik.touched.recurringInterval &&
                    formik.errors.recurringInterval
                  }
                />
              </FormControl>
            </Box>
          ) : null}
          <br />
          <Button
            startIcon={
              formik.isSubmitting && (
                <CircularProgress severity="primary" size={"1rem"} />
              )
            }
            disabled={formik.isSubmitting}
            type="submit"
            size="large"
            variant="contained"
            color="primary"
          >
            {formik.isSubmitting ? "Updating Game" : "Update Game"}
          </Button>
        </form>
      </Container>
    </AdminLayout>
  );
}

export default EditGameInstance;
