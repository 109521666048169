import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import InputLabel from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import dayjs from "dayjs";
import { useParams, useNavigate } from "react-router-dom";
import AdminLayout from "../components/shared/AdminLayout/AdminLayout";
import MetaDecorator from "../components/shared/MetaDecorator/MetaDecorator";
import PageHeading from "../components/shared/PageHeading/PageHeading";
import { AppContext } from "../context";
import {
  checkExpiredAuthToken,
  getAuthToken,
  imageBox,
  labelStyles,
  POOL_OF_FAME,
  WeekdayList,
} from "../utils";

function AddGameInstance() {
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [alternateDate, setAlternateDate] = useState(new Date());
  const [foregroundImage, setForegroundImage] = useState("");
  const [foregroundImagePreview, setForegroundImagePreview] = useState("");
  const { handleOpenSnackbar } = useContext(AppContext);

  const { lotteryId, gameCategory } = useParams();
  const navigate = useNavigate();

  const WIDTH = 70;
  const HEIGHT = 70;

  const handleSubmitImage = async (value, errorMessage) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    const formData = new FormData();
    formData.append("image", value);
    try {
      const res = await axios.post(`${process.env.BASE_URL}upload`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res?.data?.data?.data?.imageUrl;
    } catch (error) {
      console.log(error);
      handleOpenSnackbar({
        message: errorMessage,
        severity: "error",
      });
    }
  };

  let initialValues = {
    name: "",
    description: "",
    alternateStartDate: "",
    startTime: "",
    endTime: "",
    drawMethod: "RNG",
    dayOfTheWeek: new Date().getDay(),
    lotteryId: "",
    isRecurring: "false",
    recurringInterval: 30,
    totalFundPool: "",
    mrf: "",
    imageUrl: "",
  };

  let gameSchema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required(),
    isRecurring: yup.string(),
    drawMethod: yup.string(),
    mrf: yup.string().min(5).max(5),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values, helpers) => {
      checkExpiredAuthToken();
      const token = getAuthToken();
      values.lotteryId = lotteryId;
      values.dayOfTheWeek = Number(values.dayOfTheWeek);
      if (foregroundImage) {
        const foreground = await handleSubmitImage(
          foregroundImage,
          "Sorry, an error occured while uploading the image"
        );
        values.imageUrl = foreground;
      }
      if (gameCategory !== POOL_OF_FAME) {
        delete values["totalFundPool"];
        delete values["alternateStartDate"];
        delete values["mrf"];
      }
      values.endTime = values?.endTime
        ? dayjs(values?.endTime).format("HH:mm")
        : "";
      values.startTime = values?.startTime
        ? dayjs(values?.startTime).format("HH:mm")
        : "";
      if (values?.isRecurring === "false") {
        values.isRecurring = false;
      }
      if (values?.isRecurring === "true") {
        values.isRecurring = true;
        values.startTime = "";
        values.endTime = "";
        values.dayOfTheWeek = "";
      }
      if (gameCategory === POOL_OF_FAME) {
        values.startTime = "00:00";
        values.endTime = "23:59";
        if (!values?.mrf || values?.mrf === "") {
          delete values["mrf"];
        }

        if (
          !values?.alternateStartDate ||
          values?.alternateStartDate === "" ||
          new Date(values?.alternateStartDate)?.toISOString() <=
            new Date().toISOString()
        ) {
          delete values["alternateStartDate"];
        } else {
          values["alternateStartDate"] = new Date(
            values?.alternateStartDate
          )?.toISOString();
        }
      }
      // helpers.setSubmitting(false);

      // return console.log("second", values);
      axios
        .post(`${process.env.BASE_URL}game/create-game`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          helpers.setSubmitting(false);
          helpers.resetForm({ values: initialValues });
          if (res?.data?.message) {
            handleOpenSnackbar({
              message: res?.data?.message,
              severity: "success",
            });
            setTimeout(() => {
              return navigate("/games-instance");
            }, 2000);
          }
          handleOpenSnackbar({
            message: "Game Created Successfully",
            severity: "success",
          });
          setTimeout(() => {
            return navigate("/games-instance");
          }, 2000);
        })
        .catch((err) => {
          // console.log(err?.response?.data?.responsemessage?.msg);
          helpers.setSubmitting(false);
          if (err?.response?.data?.responsemessage?.msg) {
            return handleOpenSnackbar({
              message: err?.response?.data?.responsemessage?.msg,
              severity: "error",
            });
          }
          if (err?.response?.data?.responsemessage) {
            return handleOpenSnackbar({
              message: err?.response?.data?.responsemessage,
              severity: "error",
            });
          }
          handleOpenSnackbar({
            message: "Sorry, Game Could Not Be Created",
            severity: "error",
          });
        });
    },
    validationSchema: gameSchema,
  });

  return (
    <AdminLayout>
      <MetaDecorator title="Add New Game Instance - Admin Dashboard" />
      <Container maxWidth="xl">
        <PageHeading title="New Game Instance" />

        <div>
          <form onSubmit={formik.handleSubmit}>
            <Box my={1}>
              <Typography>
                The day of the week must be from 0 to 6, 0 representing Sunday,
                1 representing Monday, 2 representing Tuesday, 3 representing
                Wednesday, 4 representing Thursday, 5 representing Friday, 6
                representing Saturday
              </Typography>
            </Box>
            <Box my={1} sx={imageBox}>
              <FormControl variant="outlined">
                <p>Select image to upload</p>
                <input
                  accept="image/*"
                  type="file"
                  variant="outlined"
                  onChange={(e) => {
                    setForegroundImage(e.target.files[0]);
                    setForegroundImagePreview(
                      URL.createObjectURL(e.target.files[0])
                    );
                  }}
                />
              </FormControl>
              <div>
                {foregroundImagePreview && (
                  <img
                    src={foregroundImagePreview}
                    alt="uploaded background"
                    width={WIDTH}
                    height={HEIGHT}
                  />
                )}
              </div>
            </Box>
            <Box my={1}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel variant="outlined" sx={labelStyles}>
                  Enter Game Title
                </InputLabel>
                <TextField
                  error={formik.touched.name && formik.errors.name}
                  value={formik.values.name}
                  name="name"
                  variant="outlined"
                  fullWidth
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </FormControl>
            </Box>
            {gameCategory === POOL_OF_FAME ? null : (
              <Box
                mt={4}
                mb={2}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Stack direction="row" spacing={3}>
                  <FormControl>
                    <FormLabel sx={labelStyles}>
                      Is this a Recurring Game?
                    </FormLabel>
                    <RadioGroup
                      value={formik.values.isRecurring}
                      row
                      name="isRecurring"
                      onChange={(e) => {
                        formik.setFieldValue("isRecurring", e.target.value);
                        formik.setFieldValue("startTime", "");
                        setStartTime("");
                        formik.setFieldValue("endTime", "");
                        setEndTime("");
                        formik.setFieldValue("dayOfTheWeek", "");
                      }}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio color="primary" name="isRecurring" />}
                        label="Yes, It's recurring"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio color="primary" name="isRecurring" />}
                        label="No, It's not recurring"
                      />
                    </RadioGroup>
                  </FormControl>
                </Stack>
                <Stack direction="row" spacing={3}>
                  <FormControl>
                    <FormLabel sx={labelStyles}>
                      Select Game Draw Method
                    </FormLabel>
                    <RadioGroup
                      value={formik.values.drawMethod}
                      row
                      name="drawMethod"
                      onChange={formik.handleChange}
                    >
                      <FormControlLabel
                        value="manual"
                        control={<Radio color="primary" name="drawMethod" />}
                        label="Manual"
                      />
                      <FormControlLabel
                        value="RNG"
                        control={<Radio color="primary" name="drawMethod" />}
                        label="RNG"
                      />
                    </RadioGroup>
                  </FormControl>
                </Stack>{" "}
                &nbsp;
              </Box>
            )}

            {gameCategory === POOL_OF_FAME ||
            formik.values?.isRecurring === "true" ||
            formik.values?.isRecurring === true ? null : (
              <Box
                mt={4}
                mb={2}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Stack direction="row" spacing={3}>
                  <FormControl>
                    <FormLabel sx={labelStyles}>Days of the Week</FormLabel>
                    <RadioGroup
                      value={formik.values.dayOfTheWeek}
                      row
                      name="dayOfTheWeek"
                      onChange={(e) => {
                        formik.setFieldValue("dayOfTheWeek", e.target.value);
                      }}
                    >
                      {WeekdayList?.map((item, idx) => (
                        <FormControlLabel
                          key={idx}
                          value={item?.value}
                          control={
                            <Radio color="primary" name="dayOfTheWeek" />
                          }
                          label={item?.day}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Stack>
              </Box>
            )}
            {gameCategory === POOL_OF_FAME ? (
              <Box my={2}>
                <Grid container spacing={2}>
                  <Grid xs={12} sm={6} item>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        label="Total Fund Pool"
                        type="number"
                        name="totalFundPool"
                        variant="outlined"
                        fullWidth
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.totalFundPool &&
                          formik.errors.totalFundPool
                        }
                        helperText={
                          formik.touched.totalFundPool &&
                          formik.errors.totalFundPool
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid xs={12} sm={6} item>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="Alternate Draw Date"
                        value={alternateDate}
                        onChange={(value) => {
                          setAlternateDate(value);
                          formik.setFieldValue("alternateStartDate", value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            error={
                              formik.touched.alternateStartDate &&
                              formik.errors.alternateStartDate
                            }
                            helperText={
                              formik.touched.alternateStartDate &&
                              formik.errors.alternateStartDate
                            }
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Box>
            ) : null}
            {formik.values?.isRecurring === "false" ||
            formik.values?.isRecurring === false ? (
              <Box mt={2} mb={1}>
                {gameCategory === POOL_OF_FAME ? null : (
                  <Grid container spacing={2}>
                    <Grid xs={12} sm={6} item>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          label="Start Time"
                          value={startTime}
                          onChange={(value) => {
                            setStartTime(value);
                            formik.setFieldValue("startTime", value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              error={
                                formik.touched.startTime &&
                                formik.errors.startTime
                              }
                              helperText={
                                formik.touched.startTime &&
                                formik.errors.startTime
                              }
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid xs={12} sm={6} item>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          label="End Time"
                          value={endTime}
                          onChange={(value) => {
                            setEndTime(value);
                            formik.setFieldValue("endTime", value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              error={
                                formik.touched.endTime && formik.errors.endTime
                              }
                              helperText={
                                formik.touched.endTime && formik.errors.endTime
                              }
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                )}
              </Box>
            ) : null}

            {gameCategory === POOL_OF_FAME ? (
              <Box my={1}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    label="Manual Raffle Function"
                    name="mrf"
                    variant="outlined"
                    value={formik.values.mrf}
                    fullWidth
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.mrf && formik.errors.mrf}
                    helperText={formik.touched.mrf && formik.errors.mrf}
                  />
                </FormControl>
              </Box>
            ) : null}
            <Box my={1}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  label="Description"
                  name="description"
                  variant="outlined"
                  value={formik.values.description}
                  fullWidth
                  multiline
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.description && formik.errors.description
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
              </FormControl>
            </Box>

            {formik.values?.isRecurring === "true" ? (
              <Box my={1}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel variant="outlined" sx={labelStyles}>
                    Recuring Interval (In Minutes)
                  </InputLabel>
                  <TextField
                    disabled={!formik.values?.isRecurring}
                    value={formik.values.recurringInterval}
                    name="recurringInterval"
                    variant="outlined"
                    fullWidth
                    type="number"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.recurringInterval &&
                      formik.errors.recurringInterval
                    }
                    helperText={
                      formik.touched.recurringInterval &&
                      formik.errors.recurringInterval
                    }
                  />
                </FormControl>
              </Box>
            ) : null}
            <Button
              startIcon={
                formik.isSubmitting && (
                  <CircularProgress severity="primary" size={"1rem"} />
                )
              }
              disabled={formik.isSubmitting}
              type="submit"
              size="large"
              variant="contained"
              color="primary"
            >
              {formik.isSubmitting ? "Adding Game" : " Add Game"}
            </Button>
          </form>
        </div>
      </Container>
    </AdminLayout>
  );
}

export default AddGameInstance;
