import React from "react";
import { useState, useEffect } from "react";
import useSWR from "swr";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import NoDataAvailable from "../../shared/EmptyTable/EmptyTable";
import HalfErrorScreen from "../../shared/HalfErrorScreen/HalfErrorScreen";
import TableLoader from "../../shared/TableLoader/TableLoader";
import { checkExpiredAuthToken, getAuthToken } from "../../../utils";
import axios from "axios";

function GameInstanceDrawInformation({
  handleOpenDrawStatusDialog,
  selectedDraw,
  handleSelectDraw,
}) {
  const { gameId } = useParams();
  const [selectedId, setSelectedId] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const url = `${process.env.BASE_URL}game/fetch-result-history-by-game/${gameId}?limit=40`;

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data?.data;
      });
  };

  const { data, error } = useSWR(url, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  if (error)
    return (
      <Box>
        <HalfErrorScreen
          title=" Sorry, data cannot be retrieved at this moment"
          subtitle={error.message}
        />
      </Box>
    );

  if (!data)
    return (
      <Box>
        <TableLoader />
      </Box>
    );

  const handleClick = (event, result) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(result.resultId);
    handleSelectDraw(result);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedId("");
  };

  const headCells = [
    "Draw Name",
    "Game Date",
    "Results",
    "Date Created",
    "Date Last Modified",
    "Status",
    "Actions",
  ];

  return (
    <Box sx={{ padding: "10px 0px", marginBottom: "10px" }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }} aria-label="games table">
          <TableHead>
            <TableRow>
              {headCells.map((item) => (
                <TableCell key={item} component="th" sx={{ fontWeight: 600 }}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data?.length > 0 &&
              data?.map((row) => (
                <TableRow key={row?.resultId}>
                  <TableCell>{row?.drawName}</TableCell>
                  <TableCell>{row?.date}</TableCell>
                  <TableCell> {row?.results}</TableCell>
                  <TableCell style={{ width: 160 }}>
                    {dayjs(row?.createdAt).format("MMM D, YYYY")}
                  </TableCell>
                  <TableCell style={{ width: 160 }}>
                    {dayjs(row?.updatedAt).format("MMM D, YYYY")}
                  </TableCell>
                  <TableCell style={{ width: 120 }}>
                    {row?.isApproved ? (
                      <Chip color="success" label="Approved" size="small" />
                    ) : (
                      <Chip label="Not Approved" color="error" size="small" />
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={(e) => handleClick(e, row)}>
                      <MoreHorizIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      PaperProps={{
                        elevation: 1,
                        sx: { boxShadow: "2px 2px 4px #eee" },
                      }}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={() => handleOpenDrawStatusDialog()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        {!selectedDraw?.isApproved
                          ? "Approve Draw"
                          : "Reverse Draw"}
                      </MenuItem>
                      <Divider />
                      {/* <MenuItem
                        onClick={() => handleClose()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        Update Details
                      </MenuItem> */}
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {data && data?.length <= 0 && <NoDataAvailable />}
      </TableContainer>
    </Box>
  );
}

export default GameInstanceDrawInformation;
