import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import axios from "axios";
import useSWR from "swr";
import TableLoader from "../../shared/TableLoader/TableLoader";
import { getAuthToken } from "../../../utils";
import HalfErrorScreen from "../../shared/HalfErrorScreen/HalfErrorScreen";
import NoDataAvailable from "../../shared/EmptyTable/EmptyTable";
import ReactApexChart from "react-apexcharts";

const colors = ["blue", "green", "pink", "purple", "red"];
function BrowserAnalytics({ headerStyles }) {
  const url = `${process.env.BASE_URL}analytics/fetch-devices?filterBy=browser`;

  const fetcher = () => {
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data;
      });
  };
  const { data, error } = useSWR(url, fetcher);

  if (error)
    return (
      <HalfErrorScreen
        title="Sorry, cant retrieve recent analytics at this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <TableLoader />;

  //   console.log("nnnnnnnnnnnnnn", data?.data);
  const series = Object.values(data?.data);
  let lables = Object.keys(data?.data);
  let chartOptions = {
    series: [
      {
        data: series,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
        events: {
          click: function (chart, w, e) {
            // console.log(chart, w, e)
          },
        },
      },
      // colors: colors,
      plotOptions: {
        bar: {
          columnWidth: "45%",
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },

      xaxis: {
        categories: lables,
        labels: {
          style: {
            // colors: colors,
            fontSize: "12px",
          },
        },
      },
    },
  };
  return (
    <Box component={Paper} sx={{ padding: "10px 5px" }}>
      <Box sx={{ paddingLeft: "15px" }}>
        <Typography gutterBottom sx={headerStyles}>
          Browsers
        </Typography>
      </Box>
      <Box>
        <ReactApexChart
          options={chartOptions?.options}
          series={chartOptions?.series}
          type="bar"
          height={350}
        />
        {data && data?.data?.length <= 0 && <NoDataAvailable />}
      </Box>
    </Box>
  );
}

export default BrowserAnalytics;
