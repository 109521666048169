import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  singleDetailsBoxStyle,
  detailsActionBoxStyles,
  currencyFormater,
} from "../../../utils";

function WalletInformation({
  data,
  handleOpenDialog,
  handleOpenToggleAgentDialog,
  handleOpenBetTypeDialog,
}) {
  return (
    <Grid item xs={12} md={5} lg={5}>
      <Typography gutterBottom variant="h6" sx={{ fontWeight: 500 }}>
        Wallet Information
      </Typography>
      <Box py={2}>
        <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
          <Typography color="text.secondary">Commission Balance:</Typography>
          <Typography align="left">
            NGN{currencyFormater(data?.commissionBalance)}
          </Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
          <Typography color="text.secondary">Wallet Balance:</Typography>

          <Typography align="left">
            NGN{currencyFormater(data?.walletBalance)}
          </Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
          <Typography color="text.secondary">Daily Wallet Limit:</Typography>

          <Typography align="left">
            NGN{currencyFormater(data?.dailyLimit)}
          </Typography>
        </Box>
      </Box>

      <Typography gutterBottom variant="h6" sx={{ fontWeight: 500 }}>
        Manage Account
      </Typography>
      <Box py={2}>
        <Box mb={1} pb={1} pr={2} sx={detailsActionBoxStyles}>
          <Typography>Modify Agent Multiplier:</Typography>
          <Button
            sx={{ textTransform: "capitalize" }}
            variant="outlined"
            size="small"
            onClick={() => handleOpenDialog()}
          >
            Change Multiplier
          </Button>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={detailsActionBoxStyles}>
          <Typography>
            {" "}
            {data?.status
              ? "Deactivate Agent's Account:"
              : "Activate Agent's Account:"}
          </Typography>
          <Button
            sx={{ textTransform: "capitalize" }}
            variant="outlined"
            size="small"
            onClick={() => handleOpenToggleAgentDialog()}
          >
            {data?.status ? "Deactivate Account" : "Activate Account"}
          </Button>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={detailsActionBoxStyles}>
          <Typography>Manage Bet Types Exclusion</Typography>
          <Button
            sx={{ textTransform: "capitalize" }}
            variant="outlined"
            size="small"
            onClick={() => handleOpenBetTypeDialog()}
          >
            Manage
          </Button>
        </Box>
      </Box>
    </Grid>
  );
}

export default WalletInformation;
