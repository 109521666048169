import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import useSWR, { mutate } from "swr";
import AdminLayout from "../components/shared/AdminLayout/AdminLayout";
import MetaDecorator from "../components/shared/MetaDecorator/MetaDecorator";
import PageHeading from "../components/shared/PageHeading/PageHeading";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  getAuthToken,
  checkExpiredAuthToken,
  limit,
  AddButtonBoxStyles,
} from "../utils";
import FullScreenLoader from "../components/shared/FullScreenLoader/FullScreenLoader";
import FullScreenError from "../components/shared/FullScreenError/FullScreenError";
import { AppContext } from "../context";
import BonusTable from "../components/pages/BonusComponents/BonusTable";
import PreviewBonusModal from "../components/pages/BonusComponents/PreviewBonusModal";
import ToggleBonusDialog from "../components/pages/BonusComponents/ToggleBonusDialog";

function BonusConfigurations() {
  const [page, setPage] = React.useState(1);
  const [status, setStatus] = React.useState("");
  const [search, setSearch] = React.useState("");
  const [isFiltering, setIsFiltering] = React.useState(false);
  const [hasFiltered, setHasFiltered] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openToggleDialog, setOpenToggleDialog] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [selectedBonus, setSelectedBonus] = React.useState({});

  const navigate = useNavigate();

  const url = `${process.env.BASE_URL}bonus/fetch-all-bonuses?limit=${limit}&page=${page}`;
  let filterURL = `${
    process.env.BASE_URL
  }bonus/fetch-all-bonuses?limit=${limit}&page=${page}${
    search && `&search=${search}`
  }`;
  if (status) {
    filterURL = filterURL + `&status=${status}`;
  }

  const handleRefetch = () =>
    mutate(url, async () => {
      setHasFiltered(false);
      checkExpiredAuthToken();
      const token = getAuthToken();
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data?.data;
    });

  const { handleOpenSnackbar } = React.useContext(AppContext);

  const handleChangeStatus = (e) => setStatus(e.target.value);
  const handleChangeSearch = (e) => setSearch(e.target.value);

  const handleSelectBonus = (page) => setSelectedBonus(page);
  const handleCloseDialog = () => {
    if (isSubmitting) return;
    setOpenDialog(false);
  };
  const handleOpenDialog = () => setOpenDialog(true);

  const handleCloseToggleDialog = () => {
    if (isSubmitting) return;
    setOpenToggleDialog(false);
  };
  const handleOpenToggleDialog = () => setOpenToggleDialog(true);
  const handleResetFields = () => {
    setStatus("");
    setSearch("");
  };

  React.useEffect(() => {
    handleRefetch();
  }, []);
  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data;
      });
  };

  const handleFiterSubmit = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setIsFiltering(true);
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        mutate(url, res.data?.data, false);
        setIsFiltering(false);
        return setHasFiltered(true);
      })
      .catch((err) => {
        setIsFiltering(false);
        return err;
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(
        `${process.env.BASE_URL}content/fetch-contents?limit=${limit}&page=${
          newPage + 1
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        mutate(url, res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmitToggleStatus = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();

    let values = {
      bonusId: selectedBonus?.bonusId,
    };
    let submiitURL =
      selectedBonus && selectedBonus.status
        ? `${process.env.BASE_URL}bonus/deactivate-bonus`
        : `${process.env.BASE_URL}bonus/activate-bonus`;
    return axios
      .post(submiitURL, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsSubmitting(false);

        if (res?.data?.data?.message) {
          handleOpenSnackbar({
            message: res?.data?.data?.message,
            severity: "success",
          });
        }

        mutate(url);
        return handleCloseToggleDialog();
      })
      .catch((err) => {
        setIsSubmitting(false);

        if (err?.response?.data?.data?.message) {
          return handleOpenSnackbar({
            message: err?.response?.data?.data?.message,
            severity: "error",
          });
        }
        if (err?.response?.data?.message) {
          return handleOpenSnackbar({
            message: err?.response?.data?.message,
            severity: "error",
          });
        }
        handleOpenSnackbar({
          message: "Sorry, An Unexpected Error Occured",
          severity: "error",
        });
      });
  };
  const handleCancelFilters = () => {
    handleResetFields();
    setHasFiltered(false);
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return mutate(url, res.data?.data, false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const { data, error } = useSWR(url, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  if (error)
    return (
      <FullScreenError
        title="Sorry, data cannot be retrieved at this time"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  return (
    <AdminLayout>
      <MetaDecorator title={`Bonus Configurations - Admin`} />
      <Container maxWidth="xl">
        <PageHeading title="Bonus Configurations" />
        <Box sx={AddButtonBoxStyles}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("add-bonus")}
          >
            Add Bonus
          </Button>
        </Box>
        {openDialog ? (
          <PreviewBonusModal
            selectedBonus={selectedBonus}
            openDialog={openDialog}
            isSubmitting={isSubmitting}
            handleCloseDialog={handleCloseDialog}
            handleSubmit={handleSubmitToggleStatus}
          />
        ) : null}
        {openToggleDialog ? (
          <ToggleBonusDialog
            selectedBonus={selectedBonus}
            openDialog={openToggleDialog}
            isSubmitting={isSubmitting}
            handleCloseDialog={handleCloseToggleDialog}
            handleSubmit={handleSubmitToggleStatus}
          />
        ) : null}
        <BonusTable
          page={page}
          selectedBonus={selectedBonus}
          handleSelectBonus={handleSelectBonus}
          handleChangePage={handleChangePage}
          handleOpenDialog={handleOpenDialog}
          data={data?.data}
          totalCount={data?.totalCount}
          handleOpenToggleDialog={handleOpenToggleDialog}
        />
      </Container>
    </AdminLayout>
  );
}

export default BonusConfigurations;
