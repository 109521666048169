import React, { useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import axios from "axios";
import AdminLayout from "../components/shared/AdminLayout/AdminLayout";
import MetaDecorator from "../components/shared/MetaDecorator/MetaDecorator";
import PageHeading from "../components/shared/PageHeading/PageHeading";
import {
  checkExpiredAuthToken,
  getAuthToken,
  AddButtonBoxStyles,
} from "../utils";
import useSWR from "swr";
import AddAvatarFormDialog from "../components/pages/AvatarSettingsComponents/AddAvatarFormDialog";
import "../styles/avatarsettings.css";
import FullScreenLoader from "../components/shared/FullScreenLoader/FullScreenLoader";
import FullScreenError from "../components/shared/FullScreenError/FullScreenError";

function AvatarSettings() {
  const url = `${process.env.BASE_URL}fetch-default-avatar`;

  const [openAvatarForm, setOpenAvatarForm] = useState(false);

  const handleOpenAvatarForm = () => setOpenAvatarForm(true);
  const handleCloseAvatarForm = () => setOpenAvatarForm(false);

  const fetcher = async () => {
    checkExpiredAuthToken();
    const token = getAuthToken();

    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res?.data?.data?.data;
  };

  const { data, error } = useSWR(url, fetcher);

  if (error) {
    if (error?.response?.data?.responsecode === 404) {
      return (
        <AdminLayout>
          <AddAvatarFormDialog
            openDialog={true}
            handleCloseDialog={handleCloseAvatarForm}
            url={url}
          />
        </AdminLayout>
      );
    }
    return (
      <FullScreenError
        title={
          error?.response?.data?.responsecode === 404
            ? "Oops, No default avatar found"
            : "Sorry, we cannot retrieve the data at this moment"
        }
        subtitle={error.message}
      />
    );
  }

  if (!data) return <FullScreenLoader />;

  return (
    <AdminLayout>
      <MetaDecorator title="Avatar Settings Configuration - Admin" />
      <Container maxWidth="xl">
        <PageHeading title="Avatar Settings" />
        {openAvatarForm ? (
          <AddAvatarFormDialog
            openDialog={openAvatarForm}
            handleCloseDialog={handleCloseAvatarForm}
            url={url}
          />
        ) : null}
        <Box sx={AddButtonBoxStyles}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpenAvatarForm()}
          >
            Update Default Avatar
          </Button>
        </Box>
        <Box mt={2} mb={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Typography gutterBottom variant="h6">
                Setting's Description
              </Typography>
              <Typography>
                Hi there, the avatar setting configuration helps the admin
                manage the default avatar for every end user of the platform, if
                the user have not uploaded a desired image, the image uploaded
                by the user is used as default
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography gutterBottom variant="h6">
                Current Default Avatar
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  //   alignItems: "center",
                  //   justifyContent: "center",
                  minHeight: "50vh",
                }}
              >
                {data?.avatarUrl ? (
                  <img
                    src={data?.avatarUrl}
                    width="120px"
                    height="120px"
                    alt="default user avatar"
                  />
                ) : (
                  <Typography gutterBottom>No Avatar Uploaded yet</Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </AdminLayout>
  );
}

export default AvatarSettings;
