import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import { Formik, Form } from "formik";
import * as yup from "yup";
import axios from "axios";
import { useParams } from "react-router-dom";
import useSWR, { mutate } from "swr";
import AdminLayout from "../components/shared/AdminLayout/AdminLayout";
import MetaDecorator from "../components/shared/MetaDecorator/MetaDecorator";
import PageHeading from "../components/shared/PageHeading/PageHeading";
import { AppContext } from "../context";
import { checkExpiredAuthToken, getAuthToken, labelStyles } from "../utils";
import FullScreenError from "../components/shared/FullScreenError/FullScreenError";
import FullScreenLoader from "../components/shared/FullScreenLoader/FullScreenLoader";

let bonusSchema = yup.object().shape({
  title: yup.string().required(),
  depositRound: yup.string().required().label("Deposit Round"),
  gameType: yup.string().required().label("Game Type"),
  betType: yup.string().required().label("Bet Type"),
  expiration: yup.string().required().label("Expiration"),
  winCount: yup.number().positive().min(1).required().label("Win Count"),
  minimumDeposit: yup
    .number()
    .positive()
    .min(1)
    .required()
    .label("Minimum Deposit"),
  prize: yup.number().positive().min(1).required().label("Prize"),
  gamePlayCount: yup
    .number()
    .positive()
    .min(1)
    .required()
    .label("Game Play Count"),
});

function EditBonusConfiguration() {
  const [endTime, setEndTime] = React.useState(new Date());
  const { bonusId } = useParams();
  const { handleOpenSnackbar } = React.useContext(AppContext);

  const url = `${process.env.BASE_URL}bonus/fetch-bonus/${bonusId}`;

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data?.data;
      });
  };

  let initialValues = {
    title: "",
    depositRound: "",
    winCount: 1,
    gameType: "",
    betType: "",
    expiration: "",
    minimumDeposit: 1,
    prize: 1,
    gamePlayCount: 1,
    description: "",
  };

  const handleSubmit = (values, helpers) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    // values.expiration = dayjs(values?.expiration).format("DD-MM-YYYY");

    axios
      .put(`${process.env.BASE_URL}bonus/update-bonus/${bonusId}`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        helpers.setSubmitting(false);
        helpers.resetForm(initialValues);
        mutate(url);
        if (res?.data?.data?.message) {
          return handleOpenSnackbar({
            message: res?.data?.data?.message,
            severity: "success",
          });
        }
        return handleOpenSnackbar({
          message: "Bonus Updated Successfully",
          severity: "success",
        });
      })
      .catch((err) => {
        console.log(err?.response?.data?.responsemessage);
        helpers.setSubmitting(false);
        if (err?.response?.data?.responsemessage) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage,
            severity: "error",
          });
        }
        if (err?.response?.data?.responsemessage) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage,
            severity: "error",
          });
        }
        handleOpenSnackbar({
          message: "Sorry, Bonus Could Not Be Updated",
          severity: "error",
        });
      });
  };

  const { data, error } = useSWR(url, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  if (error)
    return (
      <FullScreenError
        title=" Sorry, bonus cannot be retrieved at this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  if (data?.title) {
    initialValues.title = data?.title;
    initialValues.depositRound = data?.depositRound;
    initialValues.winCount = data?.winCount;
    initialValues.gameType = data?.gameType;
    initialValues.betType = data?.betType;
    initialValues.expiration = data?.expiration;
    initialValues.minimumDeposit = data?.minimumDeposit;
    initialValues.prize = data?.prize;
    initialValues.gamePlayCount = data?.gamePlayCount;
    initialValues.description = data?.description;
  }

  return (
    <AdminLayout>
      <MetaDecorator title="Update Bonus Configuration - Admin" />
      <Container maxWidth="xl">
        <PageHeading title="Update Bonus" />
        <Container maxWidth="xl">
          <div>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              validationSchema={bonusSchema}
              validateOnBlur={true}
              validateOnChange={true}
              //   onSubmit={(values) => console.log(values)}
              onSubmit={(values, helpers) => handleSubmit(values, helpers)}
            >
              {({
                handleChange,
                handleBlur,
                touched,
                errors,
                setFieldValue,
                isSubmitting,
                values,
              }) => (
                <Form noValidate>
                  <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel variant="outlined" sx={labelStyles}>
                        Enter Bonus Title
                      </InputLabel>
                      <TextField
                        error={touched.title && errors.title}
                        value={values.title}
                        name="title"
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={touched.title && errors.title}
                      />
                    </FormControl>
                  </Box>
                  <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel variant="outlined" sx={labelStyles}>
                        Deposit Round
                      </InputLabel>
                      <TextField
                        name="depositRound"
                        value={values.depositRound}
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={touched.depositRound && errors.depositRound}
                        helperText={touched.depositRound && errors.depositRound}
                      />
                    </FormControl>
                  </Box>
                  <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel variant="outlined" sx={labelStyles}>
                        Game Type
                      </InputLabel>
                      <TextField
                        name="gameType"
                        value={values.gameType}
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={touched.gameType && errors.gameType}
                        helperText={touched.gameType && errors.gameType}
                      />
                    </FormControl>
                  </Box>
                  <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel variant="outlined" sx={labelStyles}>
                        Bet Type
                      </InputLabel>
                      <TextField
                        name="betType"
                        value={values.betType}
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={touched.betType && errors.betType}
                        helperText={touched.betType && errors.betType}
                      />
                    </FormControl>
                  </Box>
                  <Box mt={2} mb={1}>
                    <Grid container spacing={2}>
                      <Grid xs={12} sm={6} item>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel variant="outlined" sx={labelStyles}>
                            Minimum Deposit
                          </InputLabel>
                          <TextField
                            name="minimumDeposit"
                            value={values.minimumDeposit}
                            type="number"
                            variant="outlined"
                            fullWidth
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={
                              touched.minimumDeposit && errors.minimumDeposit
                            }
                            helperText={
                              touched.minimumDeposit && errors.minimumDeposit
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid xs={12} sm={6} item>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel variant="outlined" sx={labelStyles}>
                            Prize
                          </InputLabel>
                          <TextField
                            name="prize"
                            value={values.prize}
                            type="number"
                            variant="outlined"
                            fullWidth
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={touched.prize && errors.prize}
                            helperText={touched.prize && errors.prize}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mt={2} mb={2}>
                    <Grid container spacing={2}>
                      <Grid xs={12} sm={6} item>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel variant="outlined" sx={labelStyles}>
                            Win Count
                          </InputLabel>
                          <TextField
                            name="winCount"
                            value={values.winCount}
                            type="number"
                            variant="outlined"
                            fullWidth
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={touched.winCount && errors.winCount}
                            helperText={touched.winCount && errors.winCount}
                          />
                        </FormControl>
                      </Grid>
                      <Grid xs={12} sm={6} item>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel variant="outlined" sx={labelStyles}>
                            Game Play Count
                          </InputLabel>
                          <TextField
                            name="gamePlayCount"
                            value={values.gamePlayCount}
                            type="number"
                            variant="outlined"
                            fullWidth
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={
                              touched.gamePlayCount && errors.gamePlayCount
                            }
                            helperText={
                              touched.gamePlayCount && errors.gamePlayCount
                            }
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mt={2} my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel variant="outlined" sx={labelStyles}>
                        Bonus Expiration Period (In Days)
                      </InputLabel>
                      <TextField
                        name="expiration"
                        value={values.expiration}
                        type="number"
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={touched.expiration && errors.expiration}
                        helperText={touched.expiration && errors.expiration}
                      />
                    </FormControl>
                  </Box>
                  <Box mt={2} my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel variant="outlined" sx={labelStyles}>
                        Description
                      </InputLabel>
                      <TextField
                        multiline
                        value={values.description}
                        name="description"
                        type="number"
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={touched.description && errors.description}
                        helperText={touched.description && errors.description}
                      />
                    </FormControl>
                  </Box>
                  <br />
                  <Button
                    startIcon={
                      isSubmitting && (
                        <CircularProgress severity="primary" size={"1rem"} />
                      )
                    }
                    disabled={isSubmitting}
                    type="submit"
                    size="large"
                    variant="contained"
                    color="primary"
                  >
                    {isSubmitting ? "Updating Bonus" : "Update Bonus"}
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      </Container>
    </AdminLayout>
  );
}

export default EditBonusConfiguration;
