import * as React from "react";
import Chip from "@mui/material/Chip";

export const renderStatus = (stat) => {
  if (stat === "blacklisted") {
    return (
      <Chip
        sx={{ background: "black", color: "#fff" }}
        label={stat}
        size="small"
      />
    );
  }

  if (stat === "held") {
    return (
      <Chip
        sx={{ background: "black", color: "#fff" }}
        label={stat}
        size="small"
      />
    );
  }
  if (stat === "canceled") {
    return (
      <Chip
        sx={{ background: "black", color: "#fff" }}
        label={stat}
        size="small"
      />
    );
  }
  if (stat === "pending") {
    return <Chip color="warning" label={stat} size="small" />;
  }
  if (stat === "lost" || stat === "inactive") {
    return <Chip color="error" label={stat} size="small" />;
  }

  if (stat === "ongoing") {
    return <Chip color="primary" label={stat} size="small" />;
  }

  return <Chip label={stat} color="success" size="small" />;
};
