import React from "react";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { singleDetailsBoxStyle } from "../../../utils";
import dayjs from "dayjs";

function PersonalDetails({ data }) {
  return (
    <Grid item xs={12} md={7} lg={6}>
      <Typography gutterBottom variant="h6" sx={{ fontWeight: 500 }}>
        Personal Information
      </Typography>
      <Box py={2}>
        <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
          <Typography color="text.secondary">Full Name:</Typography>
          <Typography align="left" sx={{ textTransform: "capitalize" }}>
            {data && data?.firstname} {data && data?.lastname}
          </Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
          <Typography color="text.secondary">ID:</Typography>
          <Typography align="left" sx={{ textTransform: "capitalize" }}>
            {data && data?.userId}
          </Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
          <Typography color="text.secondary">Email:</Typography>
          <Typography align="left">{data && data?.email}</Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
          <Typography color="text.secondary">Email Verification:</Typography>
          {data?.hasVerifiedEmail ? (
            <Chip color="success" label="Verified" size="small" />
          ) : (
            <Chip label="Not Verified" color="error" size="small" />
          )}
        </Box>
        <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
          <Typography color="text.secondary">Phone:</Typography>
          <Typography align="left">{data && data?.phone}</Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
          <Typography color="text.secondary">Referral Code:</Typography>
          <Typography align="left">{data && data?.referralCode}</Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
          <Typography color="text.secondary">Can Create Role:</Typography>
          <Typography align="left">{data && data?.canCreateRole}</Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
          <Typography color="text.secondary">Max Create Count:</Typography>
          <Typography align="left">{data && data?.maxCreateCount}</Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
          <Typography color="text.secondary">Agent Multiplier:</Typography>
          <Typography align="left">{data && data?.multiplier}</Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
          <Typography color="text.secondary">Role:</Typography>
          <Typography align="left">{data && data?.role}</Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
          <Typography color="text.secondary">Date Joined:</Typography>
          <Typography align="left">
            {dayjs(data?.createdAt).format("DD, MMM YYYY hh:ss a")}
          </Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
          <Typography color="text.secondary">Date Last Updated:</Typography>
          <Typography align="left">
            {dayjs(data?.updatedAt).format("DD, MMM YYYY hh:ss a")}
          </Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
          <Typography color="text.secondary">Status:</Typography>
          {data?.status ? (
            <Chip color="success" label="Active" size="small" />
          ) : (
            <Chip label="Not Active" color="error" size="small" />
          )}
        </Box>
      </Box>
    </Grid>
  );
}

export default PersonalDetails;
