import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ReportChart from "../ReportChart";
import useSWR from "swr";
import axios from "axios";
import {
  checkExpiredAuthToken,
  getAuthToken,
  getSumFromObj,
} from "../../../../utils";
import HalfErrorScreen from "../../../shared/HalfErrorScreen/HalfErrorScreen";
import ReportTable from "./ReportTable";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import FormLoader from "../../../shared/FormLoader/FormLoader";
import { useParams } from "react-router-dom";
import NoDataAvailable from "../../../shared/EmptyTable/EmptyTable";

dayjs.extend(weekday);

let days = [];
// console.log("ggggggg");
for (let index = 0; index < 7; index++) {
  const element = dayjs().day(index);
  days.push(element);
}

// for (let index = 0; index < dayjs().daysInMonth(); index++) {
//   const element = dayjs().day(index);
//   days.push(element);
//   console.log(
//     " dayjs().startOf('month').add(index,'day')",
//     dayjs().startOf("month").add(index, "day")
//   );
// }

const DailySalesView = ({ value }) => {
  const [selectedDay, setSelectedDay] = useState(dayjs()?.format("DD-MM-YYYY"));
  const params = useParams();
  const url = `${process.env.BASE_URL}admin/fetch-specific-single-player-report?interval=daily&userId=${params?.userId}&specificDate=${selectedDay}`;

  // const url = `${process.env.BASE_URL}admin/fetch-specific-player-report?interval=daily&specificDate=10-04-2023`;

  const handleChange = (newValue) => {
    // console.log("newvalue", newValue);
    setSelectedDay((prev) => newValue)?.format("DD-MM-YYYY");
  };
  useEffect(() => {
    setSelectedDay((curr) => dayjs()?.format("DD-MM-YYYY"));
  }, []);

  const TabsSection = () => {
    return (
      <Box display="flex" gap={2} alignItems="center" justifyContent="center">
        {days.map((item, idx) => (
          <Button
            onClick={() => handleChange(item)}
            key={idx}
            variant={
              item?.format("DD-MM-YYYY") === selectedDay ? "contained" : "text"
            }
            disabled={item?.isAfter(dayjs())}
          >
            {`${item?.format("ddd")}`}
          </Button>
        ))}
      </Box>
    );
  };
  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data?.data?.data);
        return res?.data?.data?.data;
      });
  };
  const { data, error } = useSWR(url, fetcher);

  if (error)
    return (
      <HalfErrorScreen
        title="Sorry, we can't retrieve the requested data this moment"
        subtitle={error.message}
      />
    );

  if (typeof data === "boolean" && data === false)
    return (
      <>
        <TabsSection />
        <NoDataAvailable />{" "}
      </>
    );

  if (!data) return <FormLoader />;

  let stakedAmount, winnings, commission, profit;
  let dataSummary = {};
  stakedAmount = getSumFromObj(data?.totalStakedAmount);
  winnings = getSumFromObj(data?.totalWinAmount);

  dataSummary["staked amount"] = stakedAmount;
  dataSummary.winnings = winnings;

  return (
    <>
      <TabsSection />

      <Box mb={2} mt={2}>
        <ReportChart data={dataSummary} />
      </Box>

      <ReportTable data={data} value={value} />
    </>
  );
};

export default DailySalesView;
