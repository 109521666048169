import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
// import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import Logo from "../images/logo.jpg";
import { PREV_PATH, TOKEN_NAME } from "../utils/index";
import { AppContext } from "../context";
import "../styles/Login.css";

export default function Login() {
  const [cookies, setCookie] = useCookies([TOKEN_NAME]);
  const navigate = useNavigate();
  const previousPath = sessionStorage.getItem(PREV_PATH);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const { handleOpenSnackbar, handleSetAdminData } =
    React.useContext(AppContext);

  // console.log("locaation pathnalmelss", location);
  React.useEffect(() => {
    let token = cookies[TOKEN_NAME];

    if (token) {
      try {
        const decodedToken = jwtDecode(token);

        if (decodedToken) {
          navigate("/dashboard");
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  }, []);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (values, helpers) => {
      return axios
        .post(`${process.env.BASE_URL}admin/login`, values)
        .then((res) => {
          let decodedToken = jwtDecode(res.data?.data?.data?.token);
          handleSetAdminData(decodedToken);
          // console.log("nnnnnnn", decodedToken);
          setCookie(TOKEN_NAME, res.data?.data?.data?.token, { path: "/" });
          if (previousPath && previousPath !== "/login") {
            return navigate(previousPath, { replace: true });
          }
          return navigate("/dashboard", { replace: true });
        })
        .catch((err) => {
          helpers.setSubmitting(false);
          if (err?.response?.data?.responsemessage?.msg) {
            return handleOpenSnackbar({
              message: err?.response?.data?.responsemessage?.msg,
              severity: "error",
            });
          }
          if (err?.response?.data?.responsemessage) {
            return handleOpenSnackbar({
              message: err?.response?.data?.responsemessage,
              severity: "error",
            });
          }
          if (err?.message) {
            return handleOpenSnackbar({
              message: err?.message,
              severity: "error",
            });
          }
        });
    },
    validationSchema: yup.object().shape({
      email: yup.string().required().label("Email"),
      password: yup.string().min(6).required().label("Password"),
    }),
  });

  const formBoxStyle = {
    width: "100%",
  };
  const containerBoxStyles = {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };
  const imageBoxStyles = {
    width: "100%",
    minHeight: "65vh",
    height: "100%",
    background: `radial-gradient( #ffffff, #ffffff 79%, #ecaa03 5%)`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  return (
    <Box sx={containerBoxStyles}>
      <Box
        sx={{
          width: "100%",
          maxWidth: "800px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
        component={Paper}
        elevation={1}
      >
        <Grid container>
          {matches ? (
            <Grid item md={4}>
              <Box mb={2} py={3} sx={imageBoxStyles}>
                <div className="imageDiv">
                  <img
                    src={Logo}
                    alt="Merrybet Lotto"
                    style={{ width: "120px", height: "120px" }}
                  />
                </div>
              </Box>
            </Grid>
          ) : null}
          <Grid item xs={12} sm={8}>
            <form
              className="loginForm"
              noValidate
              onSubmit={formik.handleSubmit}
            >
              {!matches && (
                <div className="imageDiv">
                  <img
                    src={Logo}
                    alt="Merrybet Lotto"
                    style={{ width: "100px", height: "100px" }}
                  />
                </div>
              )}
              <Typography
                align="center"
                color="warning"
                gutterBottom
                variant="h5"
                sx={{ fontWeight: 500 }}
              >
                Admin Login
              </Typography>
              <Typography align="center" gutterBottom sx={{ fontWeight: 500 }}>
                Welcome Back, Sign In to Continue
              </Typography>
              <br />
              <Box sx={formBoxStyle} my={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    label="Email"
                    type="email"
                    error={formik.errors.email && formik.touched.email}
                    name="email"
                    variant="outlined"
                    fullWidth
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </FormControl>
              </Box>
              <Box sx={formBoxStyle} my={2}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    label="Password"
                    type="password"
                    error={formik.errors.password && formik.touched.password}
                    name="password"
                    variant="outlined"
                    fullWidth
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                  />
                </FormControl>
              </Box>

              <Box mt={2} mb={3} sx={{ width: "100%" }}>
                <Button
                  color="primary"
                  fullWidth
                  disabled={formik.isSubmitting}
                  variant="contained"
                  size="large"
                  type="submit"
                  // style={{ background: "#ecaa03" }}
                >
                  {formik.isSubmitting ? "Logging in..." : "Sign in"}
                </Button>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
