import React from "react";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { agentRoles, labelStyles } from "../../../../utils";

function CommissionFilter({
  userName,
  agentLevel,
  minCommission,
  maxCommission,
  handleChangeMinimumComm,
  handleChangeMaximumComm,
  handleChangeUserName,
  handleFilterSubmit,
  handleChangeAgentLevel,
}) {
  return (
    <Box mb={3}>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item xs={12} sm={4} md={3} lg={3}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" id="statusSelect" sx={labelStyles}>
              User
            </InputLabel>
            <TextField
              value={userName}
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) => handleChangeUserName(e)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={3} lg={2}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" id="statusSelect" sx={labelStyles}>
              Minimum Commission
            </InputLabel>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              value={minCommission}
              fullWidth
              onChange={(e) => handleChangeMinimumComm(e)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={3} lg={2}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" id="statusSelect" sx={labelStyles}>
              Maximum Commission
            </InputLabel>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              value={maxCommission}
              fullWidth
              onChange={(e) => handleChangeMaximumComm(e)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={3} lg={2}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" id="statusSelect" sx={labelStyles}>
              Agent Level
            </InputLabel>
            <Select
              labelId="statusSelect"
              variant="outlined"
              id="statusSelect"
              value={agentLevel}
              onChange={(e) => handleChangeAgentLevel(e)}
            >
              {agentRoles?.slice(1, 4)?.map((item) => (
                <MenuItem key={item?.value} value={item?.value}>
                  {item?.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {/* <Grid item xs={12} sm={4} md={3} lg={2}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" id="statusSelect" sx={labelStyles}>
              Minimum Sales Amount
            </InputLabel>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              value={minSale}
              fullWidth
              onChange={(e) => handleChangeMinSale(e)}
            />
          </FormControl>
        </Grid> */}
        {/* <Grid item xs={12} sm={4} md={3} lg={2}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" id="statusSelect" sx={labelStyles}>
              Maximum Sales Amount
            </InputLabel>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              value={maxSale}
              fullWidth
              onChange={(e) => handleChangeMaxSale(e)}
            />
          </FormControl>
        </Grid> */}
        {/* 
        <Grid item xs={12} sm={4} md={3} lg={2}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" id="statusSelect" sx={labelStyles}>
              Minimum Won Amount
            </InputLabel>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              value={minAmount}
              fullWidth
              onChange={(e) => handleChangeMinimumAmount(e)}
            />
          </FormControl>
        </Grid> */}
        {/* <Grid item xs={12} sm={4} md={3} lg={2}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" id="statusSelect" sx={labelStyles}>
              Maximum Won Amount
            </InputLabel>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              value={maxAmount}
              fullWidth
              onChange={(e) => handleChangeMaximumAmount(e)}
            />
          </FormControl>
        </Grid> */}

        <Grid item xs={12} sm={4} md={2}>
          <Button
            color="primary"
            size="large"
            variant="contained"
            endIcon={<SearchIcon />}
            onClick={() => handleFilterSubmit()}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CommissionFilter;
