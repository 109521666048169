import React from "react";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axios from "axios";
import useSWR from "swr";
import {
  checkExpiredAuthToken,
  getAuthToken,
  dialogLoaderStyles,
  previewDialogMainTitleStyle,
} from "../../../utils";

import FullScreenError from "../../shared/FullScreenError/FullScreenError";
import FullScreenLoader from "../../shared/FullScreenLoader/FullScreenLoader";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import NoDataAvailable from "../../shared/EmptyTable/EmptyTable";

export default function DrawHistoryModal({
  openDialog,
  handleCloseDialog,
  selectedGame,
}) {
  const url = `${process.env.BASE_URL}game/fetch-result-history-by-game/${selectedGame?.gameId}`;

  const navigate = useNavigate();

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data?.data;
      });
  };
  const headCells = [
    "Draw Name",
    "Game Date",
    "Results",
    "Date Created",
    "Status",
  ];
  const { data, error } = useSWR(url, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  if (error)
    return (
      <Dialog open={openDialog} fullWidth={true} maxWidth={"lg"}>
        <DialogContent>
          <Box style={{ dialogLoaderStyles }}>
            <FullScreenError
              title=" Sorry, data cannot be retrieved at this moment"
              subtitle={error.message}
            />
          </Box>
        </DialogContent>
      </Dialog>
    );

  if (!data)
    return (
      <Dialog open={true} fullWidth={true} maxWidth={"lg"}>
        <DialogContent>
          <Box style={{ dialogLoaderStyles }}>
            <FullScreenLoader />
          </Box>
        </DialogContent>
      </Dialog>
    );

  return (
    <div>
      <Dialog
        open={openDialog}
        fullWidth={true}
        maxWidth={"lg"}
        onClose={handleCloseDialog}
      >
        <DialogTitle color="success" sx={previewDialogMainTitleStyle}>
          {selectedGame?.name}'s DRAW HISTORY
        </DialogTitle>
        <DialogContent>
          <Box sx={{ padding: "10px 0px", marginBottom: "10px" }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 300 }} aria-label="games table">
                <TableHead>
                  <TableRow>
                    {headCells.map((item) => (
                      <TableCell
                        key={item}
                        component="th"
                        sx={{ fontWeight: 600 }}
                      >
                        {item}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data &&
                    data?.length > 0 &&
                    data?.slice(0, 6)?.map((row) => (
                      <TableRow key={row?.resultId}>
                        <TableCell>{row?.drawName}</TableCell>
                        <TableCell>{row?.date}</TableCell>
                        <TableCell> {row?.results}</TableCell>
                        <TableCell style={{ width: 160 }}>
                          {dayjs(row?.createdAt).format("MMM D, YYYY")}
                        </TableCell>
                        <TableCell style={{ width: 120 }}>
                          {row?.isApproved ? (
                            <Chip
                              color="success"
                              label="Approved"
                              size="small"
                            />
                          ) : (
                            <Chip
                              label="Not Approved"
                              color="error"
                              size="small"
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              {data && data?.length <= 0 && <NoDataAvailable />}
            </TableContainer>
          </Box>
          <Box
            my={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {data && data?.length > 1 && (
              <Button
                variant="outlined"
                color="primary"
                endIcon={<VisibilityIcon />}
                align="center"
                onClick={() => navigate(`more-details/${selectedGame?.gameId}`)}
              >
                View More
              </Button>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
