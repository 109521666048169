// import "babel-polyfill";
import React from "react";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import useSWR from "swr";
import AdminLayout from "../components/shared/AdminLayout/AdminLayout";
import MetaDecorator from "../components/shared/MetaDecorator/MetaDecorator";
import PageHeading from "../components/shared/PageHeading/PageHeading";
import { AppContext } from "../context";
import {
  checkExpiredAuthToken,
  getAuthToken,
  labelStyles,
  labelStylesTwo,
  labelStylesThree,
  FIVE_OF_NINETY,
  META4,
  META4_OVER_OPTIONS,
  META4_UNDER_OPTIONS,
  PERFECT_BALLS,
  PERFECT_BALLS_BET_OPTIONS,
  SEVEN49_BETOPTIONS,
  SEVEN49_OVEROPTIONS,
  SEVEN49_UNDEROPTIONS,
  SEVEN49,
  POOL_OF_FAME_BETOPTIONS,
  POOL_OF_FAME,
  safeJSONParse,
  META4_BETOPTIONS,
  FIVE_OF_NINETY_BET_OPTIONS,
  FIVE_OF_NINETY_BOOSTER_OPTIONS,
  FIVE_OF_NINETY_RESULT_OPTIONS,
  GHANA_GAME_V2_RESULTOPTIONS,
  GHANA_GAME_BETOPTIONS,
  GHANA_GAME_V2_BETOPTIONS,
  GHANA_GAME_RESULTOPTIONS,
  MERRY_INDOOR_GAME_V2_RESULT_OPTIONS,
  MERRY_INDOOR_GAME_BETOPTIONS,
  MERRY_INDOOR_GAME_RESULT_OPTIONS,
  MERRY_INDOOR_GAME_V2_BETOPTIONS,
  MERRY_INDOOR_GAME,
  MERRY_INDOOR_GAME_V2,
  GHANA_GAME,
  GHANA_GAME_V2,
  OVER_BET_OPTIONS,
  UNDER_BET_OPTIONS,
} from "../utils";
import FullScreenLoader from "../components/shared/FullScreenLoader/FullScreenLoader";
import FullScreenError from "../components/shared/FullScreenError/FullScreenError";
import FormLoader from "../components/shared/FormLoader/FormLoader";
import produce from "immer";

function AddGame() {
  const [selectedSlug, setSelectedSlug] = React.useState("");
  const [booster, setBoosterOption] = React.useState([]);
  const [betOption, setBetOption] = React.useState([]);
  const [checkedBetOptions, setCheckedBetOptions] = React.useState([]);
  const [betOptionsValues, setBetOptionsValues] = React.useState([]);
  const [over, setOverOption] = React.useState([]);
  const [under, setUnderOption] = React.useState([]);
  const [isSubmitting, setisSubmitting] = React.useState(false);
  const [loadingbooster, setLoadingBoosterOption] = React.useState(false);
  const [loadingbetOption, setLoadingBetOption] = React.useState(false);
  const [loadingover, setLoadingOverOption] = React.useState(false);
  const [loadingunder, setLoadingUnderOption] = React.useState(false);
  const [resultTypes, setResultTypes] = React.useState([]);
  const [loadingResultTypes, setLoadingResultTypes] = React.useState(false);

  const { handleOpenSnackbar } = React.useContext(AppContext);
  const specialInputRef = React.useRef();

  // const settingsURL = `${process.env.BASE_URL}site-settings/fetch-settings`;
  const settingsURL = `${process.env.BASE_URL}site-settings/fetch-setting-by-slug/game-categories`;

  const handleBetOptionChange = (e) => {
    const value = e.target.value;
    const index = betOptionsValues?.indexOf(value);

    if (index === -1) {
      setBetOptionsValues((prevValue) => [...prevValue, value]);
      setCheckedBetOptions((prevState) => [
        ...prevState,
        { name: value, multiplier: 1 },
      ]);
    } else {
      setBetOptionsValues((prevValue) =>
        prevValue.filter((item) => item !== value)
      );
      setCheckedBetOptions((prevState) =>
        prevState.filter((item) => item?.name !== value)
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      lotteryName: "",
      category: "",
      slug: "",
      imageUrl: "",
      audioUrl: "",
      resultOptions: [],
      betOptions: [],
      boosterOptions: [],
      overOptions: [],
      underOptions: [],
      setA: false,
      setB: false,
    },
    onSubmit: async (values, helpers) => {
      let lotterySlug = values.lotteryName;
      lotterySlug = lotterySlug.replaceAll(" ", "-");
      lotterySlug = lotterySlug.toLowerCase();
      // setisSubmitting((prev) => true);
      checkExpiredAuthToken();
      const token = getAuthToken();
      // return console.log(checkedBetOptions);
      values.overOptions = JSON.stringify(values.overOptions);
      values.underOptions = JSON.stringify(values.underOptions);
      values.betOptions = JSON.stringify(checkedBetOptions);
      values.boosterOptions = JSON.stringify(values.boosterOptions);
      values.resultOptions = JSON.stringify(values.resultOptions);
      values.category = selectedSlug;

      if (selectedSlug === FIVE_OF_NINETY) {
        values.setA = true;
        values.setB = true;
      }
      const formData = new FormData();

      formData.append("lotteryName", values.lotteryName);
      formData.append("category", values.category);
      formData.append("slug", lotterySlug);
      formData.append("imageUrl", values.imageUrl);
      formData.append("audioUrl", values.audioUrl);
      formData.append("resultOptions", values.resultOptions);
      formData.append("betOptions", values.betOptions);
      formData.append("boosterOptions", values.boosterOptions);
      formData.append("overOptions", values.overOptions);
      formData.append("underOptions", values.underOptions);
      formData.append("setA", values.setA);
      formData.append("setB", values.setB);

      axios
        .post(`${process.env.BASE_URL}game/create-lottery`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          helpers.setSubmitting(false);
          setisSubmitting((prev) => false);
          helpers.resetForm({});
          if (res?.data?.message) {
            return handleOpenSnackbar({
              message: res?.data?.message,
              severity: "success",
            });
          }
          handleOpenSnackbar({
            message: "Lottery Created Successfully",
            severity: "success",
          });
          return setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((err) => {
          helpers.setSubmitting(false);
          setisSubmitting((prev) => false);
          if (err?.response?.data?.responsemessage?.msg) {
            return handleOpenSnackbar({
              message: err?.response?.data?.responsemessage?.msg,
              severity: "error",
            });
          }
          if (err?.response?.data?.responsemessage) {
            return handleOpenSnackbar({
              message: err?.response?.data?.responsemessage,
              severity: "error",
            });
          }
          handleOpenSnackbar({
            message: "Sorry, Game Category Could Not Be Created",
            severity: "error",
          });
        });
    },
    validationSchema: yup.object().shape({
      lotteryName: yup.string().required().label("Lottery Name"),
      // slug: yup.string().required().label("Alternate Name"),
    }),
  });
  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(settingsURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data?.data;
      });
  };

  const resetFields = () => {
    setCheckedBetOptions((prevState) => []);
    setBetOptionsValues((prevState) => []);
    setBoosterOption((prevState) => []);
    setResultTypes((prevState) => []);
    setOverOption((prevState) => []);
    setUnderOption((prevState) => []);
  };

  const getOptions = () => {
    // console.log("zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz");
    resetFields();
    if (selectedSlug === FIVE_OF_NINETY) {
      getFiveNinetyOptions();
    } else if (selectedSlug === META4) {
      getMeta4Options();
    } else if (selectedSlug === PERFECT_BALLS) {
      getPerfectBallsOptions();
    } else if (selectedSlug === SEVEN49) {
      get749Option();
    } else if (selectedSlug === POOL_OF_FAME) {
      getPoolofFameOptions();
    } else if (selectedSlug === MERRY_INDOOR_GAME) {
      getMerryIndoorOptions();
    } else if (selectedSlug === MERRY_INDOOR_GAME_V2) {
      getMerryIndoorV2Options();
    } else if (selectedSlug === GHANA_GAME) {
      getGhanaGameOptions();
    } else if (selectedSlug === GHANA_GAME_V2) {
      getGhanaGameV2Options();
    }
  };

  const getFiveNinetyOptions = () => {
    getResultTypes(FIVE_OF_NINETY_RESULT_OPTIONS);
    getBetOption(FIVE_OF_NINETY_BET_OPTIONS);
    getBoosterOption(FIVE_OF_NINETY_BOOSTER_OPTIONS);
    getOverOption("over-bet-options");
    getUnderOption("under-bet-options");
  };

  const get749Option = () => {
    setResultTypes([]);
    setBoosterOption([]);
    getBetOption(SEVEN49_BETOPTIONS);
    getOverOption(SEVEN49_OVEROPTIONS);
    getUnderOption(SEVEN49_UNDEROPTIONS);
  };

  const getMeta4Options = () => {
    setResultTypes([]);
    setBoosterOption([]);
    getBetOption(META4_BETOPTIONS);
    getOverOption(META4_OVER_OPTIONS);
    getUnderOption(META4_UNDER_OPTIONS);
  };

  const getPerfectBallsOptions = () => {
    getBetOption(PERFECT_BALLS_BET_OPTIONS);
    setResultTypes([]);
    setBoosterOption([]);
    setOverOption([]);
    setUnderOption([]);
  };

  const getPoolofFameOptions = () => {
    getBetOption(POOL_OF_FAME_BETOPTIONS);
    setResultTypes([]);
    setBoosterOption([]);
    setOverOption([]);
    setUnderOption([]);
  };

  const getGhanaGameOptions = () => {
    getResultTypes(GHANA_GAME_RESULTOPTIONS);
    getBetOption(GHANA_GAME_BETOPTIONS);
    setBoosterOption([]);
    getOverOption(OVER_BET_OPTIONS);
    getUnderOption(UNDER_BET_OPTIONS);
  };

  const getGhanaGameV2Options = () => {
    getResultTypes(GHANA_GAME_V2_RESULTOPTIONS);
    getBetOption(GHANA_GAME_V2_BETOPTIONS);
    setBoosterOption([]);
    getOverOption(OVER_BET_OPTIONS);
    getUnderOption(UNDER_BET_OPTIONS);
  };

  const getMerryIndoorOptions = () => {
    getResultTypes(MERRY_INDOOR_GAME_RESULT_OPTIONS);
    getBetOption(MERRY_INDOOR_GAME_BETOPTIONS);
    setBoosterOption([]);
    getOverOption(OVER_BET_OPTIONS);
    getUnderOption(UNDER_BET_OPTIONS);
  };
  const getMerryIndoorV2Options = () => {
    getResultTypes(MERRY_INDOOR_GAME_V2_RESULT_OPTIONS);
    getBetOption(MERRY_INDOOR_GAME_V2_BETOPTIONS);
    setBoosterOption([]);
    getOverOption(OVER_BET_OPTIONS);
    getUnderOption(UNDER_BET_OPTIONS);
  };

  const getBetOption = async (value) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setLoadingBetOption(true);
    return axios
      .get(
        `${process.env.BASE_URL}site-settings/fetch-setting-by-slug/${value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        let { content } = res?.data?.data?.data;
        content = safeJSONParse(content);
        setBetOption(content);
        setLoadingBetOption(false);
      })
      .catch((err) => {
        return console.log(err);
      });
  };

  const getResultTypes = async (value) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setLoadingResultTypes(true);
    return axios
      .get(
        `${process.env.BASE_URL}site-settings/fetch-setting-by-slug/${value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        let { content } = res?.data?.data?.data;
        content = safeJSONParse(content);
        setResultTypes(content);
        setLoadingResultTypes(false);
      })
      .catch((err) => {
        return console.log(err);
      });
  };

  const getBoosterOption = async (value) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setLoadingBoosterOption(true);
    return axios
      .get(
        `${process.env.BASE_URL}site-settings/fetch-setting-by-slug/${value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        let { content } = res?.data?.data?.data;
        content = safeJSONParse(content);
        setBoosterOption(content);
        setLoadingBoosterOption(false);
      })
      .catch((err) => {
        return console.log(err);
      });
  };

  const getOverOption = async (value) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setLoadingOverOption(true);
    return axios
      .get(
        `${process.env.BASE_URL}site-settings/fetch-setting-by-slug/${value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        let { content } = res?.data?.data?.data;
        content = safeJSONParse(content);

        setOverOption(content);
        setLoadingOverOption(false);
      })
      .catch((err) => {
        return console.log(err);
      });
  };

  const getUnderOption = async (value) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setLoadingUnderOption(true);
    return axios
      .get(
        `${process.env.BASE_URL}site-settings/fetch-setting-by-slug/${value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        let { content } = res?.data?.data?.data;

        setUnderOption(safeJSONParse(content));
        setLoadingUnderOption(false);
      })
      .catch((err) => {
        return console.log(err);
      });
  };

  const handleSelectCategory = (e) => {
    const value = e.target.value;
    setCheckedBetOptions([]);
    setSelectedSlug(value);
    setTimeout(() => {
      specialInputRef.current.click();
    }, 1000);

    // return getOptions();
  };
  const specialOnchange = () => {
    getOptions();
  };

  const formPaperStyle = { padding: 3 };

  const { data, error } = useSWR(settingsURL, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  if (error)
    return (
      <FullScreenError
        title=" Sorry, data cannot be retrieved at this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  let Categories = data && data?.content ? safeJSONParse(data?.content) : [];

  return (
    <AdminLayout>
      <MetaDecorator title="Add New Lottery- Admin Dashboard" />
      <Container maxWidth="xl">
        <PageHeading title="Add New Lottery" />
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item xs={12} md={8} lg={7}>
              <Paper sx={formPaperStyle}>
                <div>
                  <form noValidate onSubmit={formik.handleSubmit}>
                    <Box my={1}>
                      <FormControl>
                        <FormLabel sx={labelStyles}>
                          Select Display Image (Best Fit Images are PNG types)
                        </FormLabel>
                        <br />
                        <input
                          type="file"
                          name="imageUrl"
                          accept="image/png"
                          onBlur={formik.handleBlur}
                          onChange={(e) =>
                            formik.setFieldValue("imageUrl", e.target.files[0])
                          }
                        />
                      </FormControl>
                    </Box>
                    <Box my={2}>
                      <FormControl>
                        <FormLabel sx={labelStyles}>
                          Select Lottery TV Screen Music (Max of 2mb)
                        </FormLabel>
                        <br />
                        <input
                          type="file"
                          name="audioUrl"
                          accept=".mp3,audio/*"
                          onBlur={formik.handleBlur}
                          onChange={(e) => {
                            formik.setFieldValue("audioUrl", e.target.files[0]);
                          }}
                        />
                      </FormControl>
                    </Box>
                    <Box my={1}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          label="Enter Game Category Title"
                          error={
                            formik.touched.lotteryName &&
                            formik.errors.lotteryName
                          }
                          name="lotteryName"
                          variant="outlined"
                          fullWidth
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          helperText={
                            formik.touched.lotteryName &&
                            formik.errors.lotteryName
                          }
                        />
                      </FormControl>
                    </Box>

                    <Box my={1}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        error={
                          formik.touched.category && formik.errors.category
                        }
                      >
                        <InputLabel variant="outlined" sx={labelStylesThree}>
                          Select Category
                        </InputLabel>
                        {/* <br />
                  <br /> */}
                        <Select
                          // label="Select Category"
                          name="category"
                          // variant="outlined"
                          value={selectedSlug}
                          onChange={handleSelectCategory}
                        >
                          {Categories?.map((category, idx) => (
                            <MenuItem
                              key={`222${idx}${category}`}
                              value={category}
                            >
                              {category}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          {formik.touched.category && formik.errors.category}
                        </FormHelperText>
                      </FormControl>
                    </Box>

                    <button
                      style={{ display: "none" }}
                      type="button"
                      ref={specialInputRef}
                      onClick={specialOnchange}
                    >
                      Click
                    </button>
                    {selectedSlug === FIVE_OF_NINETY ||
                    selectedSlug === GHANA_GAME ||
                    selectedSlug === GHANA_GAME_V2 ||
                    selectedSlug === MERRY_INDOOR_GAME_V2 ||
                    selectedSlug === MERRY_INDOOR_GAME ? (
                      <>
                        {loadingResultTypes ? (
                          <FormLoader />
                        ) : (
                          <Box>
                            {resultTypes && resultTypes?.length > 0 && (
                              <Box my={1}>
                                <FormControl variant="outlined" fullWidth>
                                  <InputLabel
                                    variant="outlined"
                                    sx={labelStylesTwo}
                                  >
                                    Result Options
                                  </InputLabel>
                                  <br />
                                  <FormGroup
                                    aria-label="result options"
                                    row
                                    name="resultOptions"
                                  >
                                    {resultTypes &&
                                      resultTypes?.length > 0 &&
                                      resultTypes?.map((item, idx) => (
                                        <FormControlLabel
                                          key={`${item?.value}a0${item?.name}${idx}`}
                                          value={item?.value}
                                          control={
                                            <Checkbox
                                              color="primary"
                                              onChange={formik.handleChange}
                                              name="resultOptions"
                                            />
                                          }
                                          label={item?.name}
                                        />
                                      ))}
                                  </FormGroup>
                                </FormControl>
                              </Box>
                            )}
                          </Box>
                        )}
                      </>
                    ) : null}
                    {loadingbetOption ? (
                      <FormLoader />
                    ) : (
                      <Box>
                        {betOption && betOption?.length > 0 && (
                          <Box my={1}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel
                                variant="outlined"
                                sx={labelStylesTwo}
                              >
                                Bet Options
                              </InputLabel>
                              <br />
                              <FormGroup
                                aria-label="bet options"
                                row
                                name="betOptions"
                              >
                                {betOption &&
                                  betOption?.length > 0 &&
                                  betOption?.map((item, idx) => (
                                    <FormControlLabel
                                      key={`${item?.value}0${item?.name}${idx}`}
                                      value={item?.value}
                                      control={
                                        <Checkbox
                                          color="primary"
                                          checked={betOptionsValues?.includes(
                                            item?.value
                                          )}
                                          onChange={handleBetOptionChange}
                                          name="betOptions"
                                        />
                                      }
                                      label={item?.name}
                                    />
                                  ))}
                              </FormGroup>
                            </FormControl>
                          </Box>
                        )}
                      </Box>
                    )}

                    {loadingbooster ? (
                      <FormLoader />
                    ) : (
                      <Box>
                        {booster && booster?.length > 0 && (
                          <Box my={1}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel
                                variant="outlined"
                                sx={labelStylesTwo}
                              >
                                Booster Options
                              </InputLabel>
                              <br />
                              <FormGroup
                                aria-label="booster options"
                                row
                                name="boosterOptions"
                                onChange={formik.handleChange}
                              >
                                {booster &&
                                  booster?.length > 0 &&
                                  booster?.map((item) => (
                                    <FormControlLabel
                                      key={item.value}
                                      value={item.value}
                                      control={
                                        <Checkbox
                                          color="primary"
                                          onChange={formik.handleChange}
                                          name="boosterOptions"
                                        />
                                      }
                                      label={item.name}
                                    />
                                  ))}
                              </FormGroup>
                            </FormControl>
                          </Box>
                        )}
                      </Box>
                    )}

                    {loadingover ? (
                      <FormLoader />
                    ) : (
                      <Box>
                        {over && over?.length > 0 && (
                          <Box my={1}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel
                                variant="outlined"
                                sx={labelStylesTwo}
                              >
                                Over Options
                              </InputLabel>
                              <br />
                              <FormGroup
                                aria-label="over options"
                                row
                                name="overOptions"
                                onChange={formik.handleChange}
                              >
                                {over &&
                                  over?.length > 0 &&
                                  over?.map((item, idx) => (
                                    <FormControlLabel
                                      key={`${item?.value}p${idx}`}
                                      value={item?.value}
                                      control={
                                        <Checkbox
                                          color="primary"
                                          onChange={formik.handleChange}
                                          name="overOptions"
                                        />
                                      }
                                      label={item?.name}
                                    />
                                  ))}
                              </FormGroup>
                            </FormControl>
                          </Box>
                        )}
                      </Box>
                    )}

                    {loadingunder ? (
                      <FormLoader />
                    ) : (
                      <Box>
                        {under && under?.length > 0 && (
                          <Box my={1}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel
                                variant="outlined"
                                sx={labelStylesTwo}
                              >
                                Under Options
                              </InputLabel>
                              <br />
                              <FormGroup
                                aria-label="under options"
                                row
                                name="underOptions"
                                onChange={formik.handleChange}
                              >
                                {under &&
                                  under?.length > 0 &&
                                  under?.map((item, idx) => (
                                    <FormControlLabel
                                      key={`${item?.value}ew${idx}`}
                                      value={item?.value}
                                      control={
                                        <Checkbox
                                          color="primary"
                                          onChange={formik.handleChange}
                                          name="underOptions"
                                        />
                                      }
                                      label={item?.name}
                                    />
                                  ))}
                              </FormGroup>
                            </FormControl>
                          </Box>
                        )}
                      </Box>
                    )}

                    <br />
                    <Button
                      startIcon={
                        isSubmitting && (
                          <CircularProgress severity="success" size={"1rem"} />
                        )
                      }
                      type="submit"
                      disabled={isSubmitting}
                      size="large"
                      variant="contained"
                      color="primary"
                    >
                      {isSubmitting ? "Adding Lottery" : " Add Lottery"}
                    </Button>
                  </form>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={5}>
              <Paper sx={formPaperStyle}>
                {checkedBetOptions?.length > 0 &&
                  checkedBetOptions?.map((item, idx) => (
                    <Box
                      key={`${item?.name}pp`}
                      my={1}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormControl variant="outlined">
                        <TextField
                          label="Bet Option"
                          variant="outlined"
                          size="small"
                          value={item?.name}
                          readOnly
                        />
                      </FormControl>
                      &nbsp;
                      <FormControl variant="outlined">
                        <TextField
                          label="Multiplier"
                          variant="outlined"
                          size="small"
                          value={item?.multiplier}
                          onChange={(e) => {
                            const value = e.target.value;
                            setCheckedBetOptions((currentValue) =>
                              produce(currentValue, (draft) => {
                                draft[idx].multiplier = value;
                              })
                            );
                          }}
                        />
                      </FormControl>
                    </Box>
                  ))}
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </AdminLayout>
  );
}

export default AddGame;
