import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import axios from "axios";
import { FaRegFileExcel } from "react-icons/fa";
import { AppContext } from "../../../context";
import CircularProgress from "@mui/material/CircularProgress";
import { checkExpiredAuthToken, getAuthToken } from "../../../utils";
import { useFormik } from "formik";
import { mutate } from "swr";
import * as yup from "yup";

export default function UploadDrawDialog({
  openDialog,
  handleCloseDialog,
  selectedGame,
  agentsURL,
}) {
  const [gameDate, setGameDate] = React.useState(new Date());
  const [selectedImage, setSelectedImage] = React.useState("");

  const imgRef = React.useRef();
  const handleOpenImage = () => {
    imgRef.current.click();
  };

  const initialValues = {
    gameId: "",
    gameDate: "",
    selections: "",
  };
  let validationSchema =
    (selectedGame && selectedGame?.Lottery?.category === "legendarylotto") ||
    (selectedGame && selectedGame?.Lottery?.category === "legendary-lotto")
      ? null
      : yup.object().shape({
          selections: yup.string().required().label("Selections"),
          gameDate: yup.string().required().label("Game Date"),
        });
  const { handleOpenSnackbar } = React.useContext(AppContext);
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      let newValues = {};
      values.drawName = selectedGame?.name;
      values.gameId = selectedGame?.gameId;
      values.gameDate = dayjs(gameDate).format("DD-MM-YYYY");
      const formData = new FormData();
      if (
        (selectedGame &&
          selectedGame?.Lottery?.category !== "legendarylotto") ||
        (selectedGame && selectedGame?.Lottery?.category !== "legendary-lotto")
      ) {
        newValues = values;
      }
      if (
        (selectedGame &&
          selectedGame?.Lottery?.category === "legendarylotto") ||
        (selectedGame && selectedGame?.Lottery?.category === "legendary-lotto")
      ) {
        formData.append("legendaryLottoResult", values?.image);
        newValues = formData;
      }

      checkExpiredAuthToken();
      const token = getAuthToken();
      let uploadURL =
        (selectedGame &&
          selectedGame?.Lottery?.category === "legendarylotto") ||
        (selectedGame && selectedGame?.Lottery?.category === "legendary-lotto")
          ? `${process.env.BASE_URL}game/upload-legendary-lotto-result`
          : `${process.env.BASE_URL}game/create-result`;
      return axios
        .post(uploadURL, newValues, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setSubmitting(false);
          resetForm();
          handleCloseDialog();
          if (res?.data?.data?.message) {
            return handleOpenSnackbar({
              message: res?.data?.data?.message,
              severity: "success",
            });
          }

          handleOpenSnackbar({
            message: "Game Draw Have Been Successfully Uploaded",
            severity: "success",
          });
        })
        .catch((err) => {
          console.log("eeeee", err.response.data);
          setSubmitting(false);

          if (err?.response?.data?.responsemessage?.msg) {
            return handleOpenSnackbar({
              message: err?.response?.data?.responsemessage?.msg,
              severity: "error",
            });
          }
          if (err?.response?.data?.responsemessage) {
            return handleOpenSnackbar({
              message: err?.response?.data?.responsemessage,
              severity: "error",
            });
          }

          handleOpenSnackbar({
            message: "Draw Upload Was Not Successful",
            severity: "error",
          });
        });
    },
    validationSchema: validationSchema,
  });

  if (
    (selectedGame && selectedGame?.Lottery?.category === "legendarylotto") ||
    (selectedGame && selectedGame?.Lottery?.category === "legendary-lotto")
  ) {
    return (
      <div>
        <Dialog
          fullWidth
          open={openDialog}
          onClose={handleCloseDialog}
          maxWidth="md"
        >
          <form noValidate onSubmit={formik.handleSubmit}>
            <DialogTitle sx={{ textAlign: "center" }}>UPLOAD DRAW</DialogTitle>
            <DialogContent>
              <Box my={2}>
                <Box my={2} p={1}>
                  <div
                    style={{
                      cursor: "pointer",
                      height: "100px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "4px dotted #eee",
                    }}
                    onClick={() => handleOpenImage()}
                  >
                    <FaRegFileExcel className="uploadImage" />
                  </div>
                  {selectedImage && (
                    <Typography align="center" color="green">
                      {selectedImage?.name}
                    </Typography>
                  )}
                  <Typography align="center">
                    Select file in .xlsx/.csv extension{" "}
                  </Typography>
                </Box>

                <Box my={2}>
                  <input
                    accept=".xlsx, .xls, .csv"
                    style={{ display: "none" }}
                    ref={imgRef}
                    type="file"
                    name="image"
                    onChange={(e) => {
                      setSelectedImage(e.target.files[0]);
                      formik.setFieldValue("image", e.target.files[0]);
                    }}
                  />
                </Box>
                <br />
              </Box>
              <br />
            </DialogContent>
            <DialogActions sx={{ paddingRight: "10px" }}>
              <Button
                type="button"
                disabled={formik.isSubmitting}
                color="error"
                onClick={() => handleCloseDialog()}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={formik.isSubmitting}
                startIcon={
                  formik.isSubmitting && <CircularProgress size={"1rem"} />
                }
                variant="contained"
                color="primary"
              >
                {formik.isSubmitting ? "Processing" : "Upload Draw"}
              </Button>
            </DialogActions>{" "}
          </form>
        </Dialog>
      </div>
    );
  }

  return (
    <div>
      <Dialog
        fullWidth
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
      >
        <form noValidate onSubmit={formik.handleSubmit}>
          <DialogTitle sx={{ textAlign: "center" }}>UPLOAD DRAW</DialogTitle>
          <DialogContent>
            <Box my={2}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  label="Draw Selections e.g 12-13-20-24"
                  value={formik.values.selections}
                  error={formik.touched.selections && formik.errors.selections}
                  name="selections"
                  variant="outlined"
                  fullWidth
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched.selections && formik.errors.selections
                  }
                />
              </FormControl>
            </Box>
            <Box my={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  value={gameDate}
                  onChange={(value) => {
                    setGameDate(value);
                    formik.setFieldValue("gameDate", value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={formik.touched.gameDate && formik.errors.gameDate}
                      helperText={
                        formik.touched.gameDate && formik.errors.gameDate
                      }
                      label="Game Date"
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <br />
          </DialogContent>
          <DialogActions sx={{ paddingRight: "10px" }}>
            <Button
              type="button"
              disabled={formik.isSubmitting}
              color="error"
              onClick={() => handleCloseDialog()}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={formik.isSubmitting}
              startIcon={
                formik.isSubmitting && <CircularProgress size={"1rem"} />
              }
              variant="contained"
              color="primary"
            >
              {formik.isSubmitting ? "Processing" : "Upload Draw"}
            </Button>
          </DialogActions>{" "}
        </form>
      </Dialog>
    </div>
  );
}
