import React, { useMemo, useRef } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { checkExpiredAuthToken, getAuthToken } from "../../../utils";

const PageEditor = ({
  bannerContent,
  setBannerContent,
  placeHolder,
  onChange,
  name,
}) => {
  const quillRef = useRef(null);

  function imageHandler(e) {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async function () {
      checkExpiredAuthToken();
      const token = getAuthToken();

      try {
        const formData = new FormData();

        formData.append("image", input.files[0]);
        const res = await axios.post(
          `${process.env.BASE_URL}upload`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log("fffffffffffffffffffff", res?.data?.data?.data?.imageUrl);
        let range = quillRef?.current.getEditorSelection();
        let position = range ? range.index : 0;

        console.log("res.data", res?.data?.data?.data?.imageUrl);
        quillRef?.current
          .getEditor()
          .insertEmbed(position, "image", res?.data?.data?.data?.imageUrl);
        quillRef?.current.setEditorSelection(position + 1);
        // quillRef?.current
        //   .getEditor()
        //   .insertEmbed(null, "image", res?.data?.data?.data?.imageUrl);
      } catch (error) {
        console.log("eee", error);
      }
    };
  }

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: "1" }, { header: "2" }],
          [{ size: ["small", false, "large", "huge"] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          [{ color: [] }, { background: [] }],
          ["link", "image"],
          // ["link"],
          ["clean"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
      // history: {
      //   delay: 500,
      //   maxStack: 100,
      //   userOnly: true,
      // },
    }),
    []
  );

  return (
    <div className="text-editor">
      <ReactQuill
        ref={quillRef}
        theme="snow"
        value={bannerContent}
        onChange={(value) => {
          setBannerContent(value);
          onChange(name, value);
        }}
        placeholder={placeHolder}
        modules={modules}
        // formats={formats}
      />
    </div>
  );
};

PageEditor.propTypes = {
  bannerContent: PropTypes.string.isRequired,
  placeHolder: PropTypes.string.isRequired,
};

export default PageEditor;
