import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ReportChart from "../ReportChart";
import useSWR from "swr";
import axios from "axios";
import {
  CASH_OUT,
  checkExpiredAuthToken,
  EAZY_WIN,
  getAuthToken,
  getSumFromObj,
  GHANA_GAME,
  POOL_OF_FAME,
  META4,
  METRO,
  PERFECT_BALLS,
  SEVEN49,
  GHANA_GAME_V2,
  MERRY_INDOOR_GAME,
  MERRY_INDOOR_GAME_V2,
} from "../../../../utils";
import FormLoader from "../../../shared/FormLoader/FormLoader";
import HalfErrorScreen from "../../../shared/HalfErrorScreen/HalfErrorScreen";
import GhanaGameTable from "../DailyReports/GhanaGameTable";
import Meta4Table from "../DailyReports/Meta4Table";
import Seven49Table from "../DailyReports/Seven49Table";

import MerryIndoorGameTable from "../DailyReports/MerryIndoorGameTable";
import PerfectBallsTable from "../DailyReports/PerfectBallsTable";
import PoolOfFameTable from "../DailyReports/PoolOfFameTable";
import GhanaGameV2Table from "../DailyReports/GhanaGameV2Table";
import MerryIndoorGameV2Table from "../DailyReports/MerryIndoorGameV2Table";

const WeeklySalesView = ({ value }) => {
  const url = `${process.env.BASE_URL}admin/fetch-sales-report?interval=weekly`;

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data?.data?.data);
        return res?.data?.data?.data;
      });
  };
  const { data, error } = useSWR(url, fetcher);

  if (error)
    return (
      <HalfErrorScreen
        title="Sorry, we can't retrieve the requested data this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FormLoader />;

  // console.log("ddddddddddddd", data);
  let sales, winnings, commission, profit;
  let dataSummary = {};
  sales = getSumFromObj(data?.totalSales);
  winnings = getSumFromObj(data?.totalWinnings);
  commission = getSumFromObj(data?.totalCommissions);
  profit = getSumFromObj(data?.totalProfits);

  dataSummary.sales = sales;
  dataSummary.winnings = winnings;
  dataSummary.commission = commission;
  dataSummary.profit = profit;

  return (
    <>
      <Box mb={2}>
        <ReportChart data={dataSummary} />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Meta4Table data={data?.categories[META4]} value={value} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Seven49Table data={data?.categories[SEVEN49]} value={value} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <PerfectBallsTable
            data={data?.categories[PERFECT_BALLS]}
            value={value}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <PoolOfFameTable data={data?.categories[POOL_OF_FAME]} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <GhanaGameTable data={data?.categories[GHANA_GAME]} value={value} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <GhanaGameV2Table
            data={data?.categories[GHANA_GAME_V2]}
            value={value}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <MerryIndoorGameTable
            data={data?.categories[MERRY_INDOOR_GAME]}
            value={value}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <MerryIndoorGameV2Table
            data={data?.categories[MERRY_INDOOR_GAME_V2]}
            value={value}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default WeeklySalesView;
