import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import useSWR from "swr";
import axios from "axios";
import { FaBorderNone } from "react-icons/fa";
import { MdOutlineAdminPanelSettings, MdThumbUp } from "react-icons/md";
import { RiDeleteBackFill } from "react-icons/ri";
import { checkExpiredAuthToken, getAuthToken } from "../../../utils";
import "../../../styles/analytics.css";
import HalfErrorScreen from "../../shared/HalfErrorScreen/HalfErrorScreen";
import AnalyticsCard from "../../shared/AnalyticsCard/AnalyticsCard";

const url = `${process.env.BASE_URL}game/instances/analytics`;

function GameInstanceAnalytics() {
  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data?.data;
      });
  };

  const { data, error } = useSWR(url, fetcher, {});

  if (error)
    return (
      <HalfErrorScreen
        title=" Sorry, games instance analytics data cannot be retrieved at this moment"
        subtitle={error.message}
      />
    );

  if (!data) return null;

  //   console.log("gggggggggggg", data);

  return (
    <Box mb={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <AnalyticsCard
            mainNumber={data && data?.totalGameInstances}
            icon={<MdOutlineAdminPanelSettings className="cardIcon" />}
            text={data?.totalGameInstances <= 1 ? "Game" : "Games"}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AnalyticsCard
            mainNumber={data && data?.inactiveGameInstances}
            icon={<FaBorderNone className="cardIcon" />}
            text={
              data && data?.inactiveGameInstances <= 1
                ? "Inactive Game"
                : "Inactive Games"
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AnalyticsCard
            mainNumber={data && data?.activeGameInstances}
            icon={<MdThumbUp className="cardIcon" />}
            text={
              data && data?.activeGameInstances <= 1
                ? "Active Game"
                : "Active Games"
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AnalyticsCard
            error={true}
            mainNumber={data && data?.deletedGameInstances}
            icon={<RiDeleteBackFill className="errorCardIcon" />}
            text={
              data && data?.deletedGameInstances <= 1
                ? "Deleted Game"
                : "Deleted Games"
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default GameInstanceAnalytics;
