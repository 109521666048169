import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AdminLayout from "../components/shared/AdminLayout/AdminLayout";
import MetaDecorator from "../components/shared/MetaDecorator/MetaDecorator";
import DashboardStatsCard from "../components/pages/DashboardComponents/DashboardStatsCard";

export default function Dashboard() {
  return (
    <AdminLayout>
      <MetaDecorator title="Admin Dashboard" />
      <Container maxWidth="xl">
        <DashboardStatsCard tickets={10} players={5} />
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            {/* <TicketAnalytics /> */}
          </Grid>
          <Grid item xs={12} md={6}>
            {/* <TicketAnalytics title="No. of Transactions for Last 6 Months" /> */}
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            {/* <RecentUsers headerStyles={headerStyles} /> */}
          </Grid>
          <Grid item xs={12} md={6}>
            {/* <RecentGames headerStyles={headerStyles} /> */}
          </Grid>
        </Grid>
      </Container>
    </AdminLayout>
  );
}
