import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ReportChart from "../ReportChart";
import useSWR from "swr";
import axios from "axios";
import {
  checkExpiredAuthToken,
  getAuthToken,
  getSumFromObj,
} from "../../../../utils";
import TableLoader from "../../../shared/TableLoader/TableLoader";
import HalfErrorScreen from "../../../shared/HalfErrorScreen/HalfErrorScreen";

import ReportTable from "../DailyReports/ReportTable";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import weekYear from "dayjs/plugin/weekYear"; // dependent on weekOfYear plugin
import weekOfYear from "dayjs/plugin/weekOfYear";
import "../playerreports.css";
import NoDataAvailable from "../../../shared/EmptyTable/EmptyTable";
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

dayjs().weekYear();
dayjs.extend(weekday);

let months = [];

for (let index = 0; index < 12; index++) {
  months.push(dayjs().month(index));
}

const MonthlySalesView = ({ value }) => {
  const [selectedMonth, setSelectedMonth] = useState(dayjs());
  const url = `${
    process.env.BASE_URL
  }admin/fetch-specific-player-report?interval=monthly&specificDate=${selectedMonth?.format(
    "MMM YYYY"
  )}`;

  const handleChange = (value) => {
    setSelectedMonth((prev) => value);
  };

  useEffect(() => {
    setSelectedMonth((curr) => dayjs());
  }, []);

  const TabsSection = () => {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        {months.map((item, idx) => (
          <Button
            onClick={() => handleChange(item)}
            key={idx}
            variant={
              item?.month() === selectedMonth?.month() ? "contained" : "text"
            }
            disabled={item?.isAfter(dayjs(), "month")}
          >
            {`${item?.format("MMM")}`}
          </Button>
        ))}
      </Box>
    );
  };
  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data?.data;
      });
  };
  const { data, error } = useSWR(url, fetcher);

  if (error)
    return (
      <HalfErrorScreen
        title="Sorry, we can't retrieve the requested data this moment"
        subtitle={error.message}
      />
    );

  if (typeof data === "boolean" && data === false)
    return (
      <>
        <TabsSection />
        <NoDataAvailable />
      </>
    );

  if (!data) return <TableLoader />;

  let stakedAmount, winnings;
  let dataSummary = {};
  stakedAmount = getSumFromObj(data?.totalStakedAmount);
  winnings = getSumFromObj(data?.totalWinAmount);

  dataSummary["staked amount"] = stakedAmount;
  dataSummary.winnings = winnings;

  return (
    <>
      <TabsSection />
      <br />
      <Box mb={2}>
        <ReportChart data={dataSummary} />
      </Box>
      <ReportTable data={data} value={value} />
    </>
  );
};

export default MonthlySalesView;
