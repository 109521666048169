import React from "react";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputLabel from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { labelStyles } from "../../../utils";

function UsersFilter({
  userName,
  status,
  loginDate,
  startDate,
  endDate,
  minimumWalletBal,
  maximumWalletBal,
  handleChangeStatus,
  handleChangeUserName,
  handleChangeLoginDate,
  handleChangeStartDate,
  handleChangeEndDate,
  handleChangeMinimumBal,
  handleChangeMaximumBal,
  handleFiterSubmit,
}) {
  return (
    <Box mb={3}>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item xs={12} sm={4} md={3}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" id="statusSelect" sx={labelStyles}>
              Player's Name
            </InputLabel>
            <TextField
              // label="Player's Name"
              variant="outlined"
              size="small"
              value={userName}
              fullWidth
              onChange={(e) => handleChangeUserName(e)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" id="statusSelect" sx={labelStyles}>
              Minimum Wallet Balance
            </InputLabel>
            <TextField
              // label="Player's Name"
              type="number"
              variant="outlined"
              size="small"
              value={minimumWalletBal}
              fullWidth
              onChange={(e) => handleChangeMinimumBal(e)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" id="statusSelect" sx={labelStyles}>
              Maximum Wallet Balance
            </InputLabel>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              value={maximumWalletBal}
              fullWidth
              onChange={(e) => handleChangeMaximumBal(e)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" id="statusSelect" sx={labelStyles}>
              Status
            </InputLabel>
            <Select
              // label="Status"
              labelId="statusSelect"
              variant="outlined"
              id="statusSelect"
              value={status}
              onChange={(e) => handleChangeStatus(e)}
            >
              <MenuItem value={""}>Select</MenuItem>
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Non Active</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel variant="outlined" id="statusSelect" sx={labelStyles}>
              Last Login Date
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                size="small"
                // label="Last Login Date"
                value={loginDate}
                onChange={(value) => {
                  handleChangeLoginDate(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth size="small" />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel variant="outlined" id="statusSelect" sx={labelStyles}>
              Date Joined (From)
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                size="small"
                value={startDate}
                onChange={(value) => {
                  handleChangeStartDate(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth size="small" />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel variant="outlined" id="statusSelect" sx={labelStyles}>
              Date Joined ( To )
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                size="small"
                // label="Date Joined"
                value={endDate}
                onChange={(value) => {
                  handleChangeEndDate(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth size="small" />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <Button
            size="large"
            variant="contained"
            endIcon={<SearchIcon />}
            color="primary"
            onClick={() => handleFiterSubmit()}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default UsersFilter;
