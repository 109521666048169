import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";
import { Formik, Form } from "formik";
import * as yup from "yup";
import axios from "axios";
import useSWR from "swr";
import { useParams } from "react-router-dom";
import AdminLayout from "../components/shared/AdminLayout/AdminLayout";
import MetaDecorator from "../components/shared/MetaDecorator/MetaDecorator";
import PageHeading from "../components/shared/PageHeading/PageHeading";
import { labelStyles, getAuthToken, checkExpiredAuthToken } from "../utils";
import { AppContext } from "../context";
import FullScreenError from "../components/shared/FullScreenError/FullScreenError";
import FullScreenLoader from "../components/shared/FullScreenLoader/FullScreenLoader";

let initialValues = {
  firstname: "",
  lastname: "",
  email: "",
  password: "",
  confirmPassword: "",
  role: "",
};
let adminSchema = yup.object().shape({
  firstname: yup.string().required().label("First name"),
  lastname: yup.string().required().label("Last name"),
  email: yup.string().email().required().label("Email"),
  //   password: yup.string().required(),
  //   confirmPassword: yup
  //     .string()
  //     .required()
  //     .oneOf([yup.ref("password"), null], "Both passwords must match")
  //     .label("Password"),
  role: yup.string().required(),
});

const url = `${process.env.BASE_URL}fetch-roles?userType=admin`;

function EditAdmin() {
  const { handleOpenSnackbar } = React.useContext(AppContext);
  const { adminId } = useParams();

  const adminURL = `${process.env.BASE_URL}admin/fetch-profile/${adminId}`;

  const fetchInitialAdminData = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(adminURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data?.data?.data);
        return res?.data?.data?.data;
      });
  };

  const { data, error: adminError } = useSWR(adminURL, fetchInitialAdminData);

  if (data) {
    initialValues.firstname = data?.name.split(" ")[0];
    initialValues.lastname = data?.name.split(" ")[1];
    initialValues.email = data?.email;
    initialValues.role = data?.role;
  }

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data?.data?.data);
        return res?.data?.data?.data;
      });
  };
  const { data: roles, error } = useSWR(url, fetcher);

  if (error)
    return (
      <FullScreenError
        title="Sorry, we can't retrieve the requested data this moment"
        subtitle={error.message}
      />
    );

  if (!roles) return <FullScreenLoader />;

  const handleSubmit = (values, helpers) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    axios
      .put(`${process.env.BASE_URL}admin/update-profile/${adminId}`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res.data);
        helpers.resetForm({});
        helpers.setSubmitting(false);
        handleOpenSnackbar({
          message: "Admin Details Updated Successfully",
          severity: "success",
        });
      })
      .catch((err) => {
        console.log(err?.response?.data?.responsemessage?.msg);
        helpers.setSubmitting(false);
        if (err?.response?.data?.responsemessage?.msg) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage?.msg,
            severity: "error",
          });
        }
        if (err?.response?.data?.responsemessage) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage,
            severity: "error",
          });
        }
        handleOpenSnackbar({
          message: "Sorry, Admin Could Not Be Updated",
          severity: "error",
        });
      });
  };
  return (
    <AdminLayout>
      <MetaDecorator title="Update Admin - Admin" />
      <Container maxWidth="xl">
        <PageHeading title="Update Admin Details" />
        <Container maxWidth="md">
          <div>
            <Formik
              initialValues={initialValues}
              validationSchema={adminSchema}
              enableReinitialize={true}
              validateOnBlur={true}
              validateOnChange={true}
              onSubmit={(values, helpers) => handleSubmit(values, helpers)}
            >
              {({
                handleChange,
                handleBlur,
                touched,
                errors,
                setFieldValue,
                isSubmitting,
                values,
              }) => (
                <Form noValidate>
                  <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel variant="outlined" sx={labelStyles}>
                        Enter the first name
                      </InputLabel>
                      <TextField
                        error={touched.firstname && errors.firstname}
                        value={values.firstname}
                        name="firstname"
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={touched.firstname && errors.firstname}
                      />
                    </FormControl>
                  </Box>
                  <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel variant="outlined" sx={labelStyles}>
                        Enter the last name
                      </InputLabel>
                      <TextField
                        value={values.lastname}
                        error={touched.lastname && errors.lastname}
                        name="lastname"
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={touched.lastname && errors.lastname}
                      />
                    </FormControl>
                  </Box>
                  <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel variant="outlined" sx={labelStyles}>
                        Enter the email
                      </InputLabel>
                      <TextField
                        value={values.email}
                        error={touched.email && errors.email}
                        name="email"
                        type="email"
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={touched.email && errors.email}
                      />
                    </FormControl>
                  </Box>
                  <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel variant="outlined" sx={labelStyles}>
                        Enter the password
                      </InputLabel>
                      <TextField
                        error={touched.password && errors.password}
                        name="password"
                        type="password"
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={touched.password && errors.password}
                      />
                    </FormControl>
                  </Box>
                  <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel variant="outlined" sx={labelStyles}>
                        Confirm the password
                      </InputLabel>
                      <TextField
                        error={
                          touched.confirmPassword && errors.confirmPassword
                        }
                        name="confirmPassword"
                        type="password"
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={
                          touched.confirmPassword && errors.confirmPassword
                        }
                      />
                    </FormControl>
                  </Box>
                  <Box my={1}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      error={touched.role && errors.role}
                    >
                      <InputLabel variant="outlined" sx={labelStyles}>
                        Select Role
                      </InputLabel>
                      <Select
                        value={values.role}
                        name="role"
                        variant="outlined"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      >
                        {roles.map((role) => (
                          <MenuItem key={role} value={role}>
                            {role}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {touched.role && errors.role}
                      </FormHelperText>
                    </FormControl>
                  </Box>

                  <br />
                  <Button
                    startIcon={
                      isSubmitting && (
                        <CircularProgress
                          style={{ color: "white" }}
                          size={"1rem"}
                        />
                      )
                    }
                    type="submit"
                    size="large"
                    variant="contained"
                    color="primary"
                  >
                    {isSubmitting ? "Submitting" : " Update Admin"}
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      </Container>
    </AdminLayout>
  );
}

export default EditAdmin;
