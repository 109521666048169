import React from "react";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { dialogMainTextStyles, singleDetailsBoxStyle } from "../../../utils";

function PlayerInformation({ data }) {
  return (
    <Grid item xs={12} md={6}>
      <Typography gutterBottom variant="h6" sx={{ fontWeight: 500 }}>
        Player Information
      </Typography>
      <Box my={2}>
        <Box my={1} sx={singleDetailsBoxStyle}>
          <Typography>First Name:</Typography>
          <Typography sx={dialogMainTextStyles}>
            {data?.User?.firstname}
          </Typography>
        </Box>
        <Box my={1} sx={singleDetailsBoxStyle}>
          <Typography>Last Name:</Typography>
          <Typography sx={dialogMainTextStyles}>
            {data?.User?.lastname}
          </Typography>
        </Box>
        <Box my={1} sx={singleDetailsBoxStyle}>
          <Typography>Email:</Typography>
          <Typography sx={dialogMainTextStyles}>
            {data?.User?.email}{" "}
          </Typography>
        </Box>
        <Box my={1} sx={singleDetailsBoxStyle}>
          <Typography>Phone Number:</Typography>
          <Typography sx={dialogMainTextStyles}>
            {data?.User?.phone}{" "}
          </Typography>
        </Box>
        <Box my={1} sx={singleDetailsBoxStyle}>
          <Typography>Player Role:</Typography>
          <Typography sx={dialogMainTextStyles}>{data?.User?.role}</Typography>
        </Box>
        <Box my={1} sx={singleDetailsBoxStyle}>
          <Typography>Player Status:</Typography>
          <Box component="span" sx={dialogMainTextStyles}>
            {data?.User?.status ? (
              <Chip color="success" label="Active" size="small" />
            ) : (
              <Chip label="Not Active" color="error" size="small" />
            )}
          </Box>
        </Box>
      </Box>
      <br />
      <Typography gutterBottom variant="h6" sx={{ fontWeight: 500 }}>
        Bank Account Information
      </Typography>
      <Box my={2}>
        <Box my={1} sx={singleDetailsBoxStyle}>
          <Typography>Account Name:</Typography>
          <Typography sx={dialogMainTextStyles}>
            {JSON.parse(data?.bankDetails)?.accountName}
          </Typography>
        </Box>
        <Box my={1} sx={singleDetailsBoxStyle}>
          <Typography>Account Number:</Typography>
          <Typography sx={dialogMainTextStyles}>
            {JSON.parse(data?.bankDetails)?.accountNumber}
          </Typography>
        </Box>
        <Box my={1} sx={singleDetailsBoxStyle}>
          <Typography>Bank Name:</Typography>
          <Typography sx={dialogMainTextStyles}>
            {JSON.parse(data?.bankDetails)?.bankName}
          </Typography>
        </Box>
        <Box my={1} sx={singleDetailsBoxStyle}>
          <Typography>Bank Code:</Typography>
          <Typography sx={dialogMainTextStyles}>
            {JSON.parse(data?.bankDetails)?.bankCode}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
}

export default PlayerInformation;
