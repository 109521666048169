import React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import dayjs from "dayjs";
import NoDataAvailable from "../../shared/EmptyTable/EmptyTable";
import { useNavigate } from "react-router-dom";
import { tableButtonStyles } from "../../../utils";
import TableCSVButton from "../../shared/TableCSVButton/TableCSVButton";

function GamesTable({
  data,
  page,
  selectedGame,
  rowsPerPageOptions,
  rowsPerPage,
  handleChangeRowsPerPage,
  handleSelectGame,
  handleOpenDrawDialog,
  handleChangePage,
  handleOpenActivateGameDialog,
  handleOpenForecastModal,
  handleOpenStatisticsModal,
  handleOpenDeactivateGameDialog,
  handleOpenPreviewGameDialog,
  handleOpenHistoryModal,
  totalCount,
}) {
  const [selectedId, setSelectedId] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event, game) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(game.gameId);
    handleSelectGame(game);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedId("");
  };

  const pushOpenHistoryModal = (game) => {
    handleSelectGame(game);
    handleOpenHistoryModal();
  };

  const pushOpenForecastModal = (game) => {
    handleSelectGame(game);
    handleOpenForecastModal();
  };

  const pushOpenStatisticsModal = (game) => {
    handleSelectGame(game);
    handleOpenStatisticsModal();
  };
  const moveToEditGamePage = () => {
    // console.log("gaaaaaaaaaaaaaaaa", selectedGame);
    navigate(
      `edit-game/${selectedGame?.gameId}/${selectedGame?.Lottery?.category}`
    );
  };

  const headCells = [
    "Name",
    "Lottery",
    "Weekday",
    "Start Time",
    "End Time",
    "Date Created",
    "Status",
    "Actions",
  ];
  const headers = [
    "Name",
    "Lottery",
    "Weekday",
    "Start Time",
    "End Time",
    "Date Created",
    "Status",
  ];

  let rows = [];
  if (data?.length > 0) {
    data?.forEach((item) => {
      rows.push([
        item?.name,
        item?.lotteryName,
        item?.weekday,
        item?.startTime,
        item?.endTime,
        item?.createdAt,
        item?.status,
      ]);
    });

    rows = [headers, ...rows];
  }
  return (
    <Box sx={{ padding: "10px 0px", marginBottom: "10px" }}>
      {data?.length > 0 ? (
        <TableCSVButton data={rows} filename="games.csv" />
      ) : null}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }} aria-label="games table">
          <TableHead>
            <TableRow>
              {headCells.map((item) => (
                <TableCell
                  key={item}
                  component="th"
                  sx={{ fontWeight: 600 }}
                  colSpan={item === "Actions" ? 6 : 1}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data?.length > 0 &&
              data?.map((row) => (
                <TableRow key={row?.gameId}>
                  <TableCell>{row?.name}</TableCell>
                  <TableCell>{row?.lotteryName}</TableCell>
                  <TableCell>{row?.weekday}</TableCell>
                  <TableCell> {row?.startTime}</TableCell>
                  <TableCell> {row?.endTime}</TableCell>
                  <TableCell style={{ width: 160 }}>
                    {dayjs(row?.createdAt).format("MMM D, YYYY")}
                  </TableCell>
                  <TableCell style={{ width: 120 }}>
                    {row?.status ? (
                      <Chip color="success" label="Active" size="small" />
                    ) : (
                      <Chip label="Not Active" color="error" size="small" />
                    )}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      sx={tableButtonStyles}
                      color="success"
                      onClick={() => pushOpenForecastModal(row)}
                      size="smaill"
                    >
                      Forecast
                    </Button>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <Button
                      variant="outlined"
                      onClick={() => pushOpenHistoryModal(row)}
                      sx={tableButtonStyles}
                      color="success"
                      size="smaill"
                    >
                      View History
                    </Button>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <Button
                      variant="outlined"
                      onClick={() => pushOpenStatisticsModal(row)}
                      sx={tableButtonStyles}
                      color="success"
                      size="smaill"
                    >
                      View Statistics
                    </Button>
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={(e) => handleClick(e, row)}>
                      <MoreHorizIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      PaperProps={{
                        elevation: 1,
                        sx: { boxShadow: "2px 2px 4px #eee" },
                      }}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={() => handleOpenPreviewGameDialog()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        Preview Game
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onClick={() => handleOpenDrawDialog()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        Upload Draw
                      </MenuItem>
                      <Divider />

                      {!selectedGame?.status ? (
                        <MenuItem
                          onClick={() => handleOpenActivateGameDialog()}
                          sx={{ fontSize: "12.7px" }}
                        >
                          Activate Game
                        </MenuItem>
                      ) : null}
                      <Divider />
                      {selectedGame?.status ? (
                        <MenuItem
                          onClick={() => handleOpenDeactivateGameDialog()}
                          sx={{ fontSize: "12.7px" }}
                        >
                          Deactivate Game
                        </MenuItem>
                      ) : null}
                      <Divider />
                      <MenuItem
                        onClick={() => moveToEditGamePage()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        Edit Details
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onClick={() =>
                          navigate(`more-details/${selectedGame?.gameId}`)
                        }
                        sx={{ fontSize: "12.7px" }}
                      >
                        View More Details
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {data && data?.length <= 0 && <NoDataAvailable />}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}

export default GamesTable;
