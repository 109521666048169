import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useNavigate } from "react-router-dom";
import NoDataAvailable from "../../../shared/EmptyTable/EmptyTable";
import { downloadBoxStyles } from "../data";
import DownloadCSVButton from "../DownloadCSVButton";
import { currencyFormater, sortGamesLabel } from "../../../../utils";

function ReportTable({ data }) {
  const thStyle = {
    fontWeight: 600,
  };
  const [selectedUser, setSelectedUser] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event, user) => {
    setAnchorEl(event.currentTarget);
    setSelectedUser(user);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedUser("");
  };
  const handleViewPlayerReports = () =>
    navigate(
      `/agent-reports/single-player/${selectedUser?.userId}/${selectedUser?.username}`
    );
  const handleViewPlayerDetails = () =>
    navigate(`/agents/agent-details/${selectedUser?.userId}`);
  return (
    <Box sx={{ padding: "10px 2px", marginBottom: "10px" }} component={Paper}>
      <Box mb={2} sx={downloadBoxStyles}>
        {data?.length > 0 && <DownloadCSVButton data={data} />}
      </Box>
      <TableContainer>
        <Table size="small" sx={{ minWidth: 300 }} aria-label="sales table">
          <TableHead>
            <TableRow>
              <TableCell component="th" sx={thStyle}>
                S/N
              </TableCell>
              <TableCell component="th" sx={thStyle}>
                User Name
              </TableCell>
              <TableCell component="th" sx={thStyle}>
                User ID
              </TableCell>
              {data?.games?.map((item, idx) => (
                <TableCell key={`${idx}nn`} component="th" sx={thStyle}>
                  {item}
                </TableCell>
              ))}
              <TableCell component="th" sx={thStyle}>
                No. of Ticket
              </TableCell>
              <TableCell component="th" sx={thStyle}>
                Total Staked
              </TableCell>
              <TableCell component="th" sx={thStyle}>
                Winnings
              </TableCell>
              <TableCell component="th" sx={thStyle}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data?.result?.length > 0 &&
              data?.result?.map((row, idx) => (
                <TableRow key={`${idx}eeee`}>
                  <TableCell>{idx + 1}</TableCell>
                  <TableCell>{row?.username}</TableCell>
                  <TableCell>{row?.userId}</TableCell>
                  {row?.gameName?.map((game, idx) => (
                    <TableCell>
                      <div>&#8358;{currencyFormater(game?.amount, 2)}</div>
                    </TableCell>
                  ))}
                  <TableCell>
                    <div>{currencyFormater(row?.totalticketCount)}</div>
                  </TableCell>
                  <TableCell>
                    <div>&#8358;{currencyFormater(row?.totalStakedAmount)}</div>
                  </TableCell>
                  <TableCell>
                    <div>&#8358;{currencyFormater(row?.totalWinAmount)}</div>
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={(e) => handleClick(e, row)}>
                      <MoreHorizIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      PaperProps={{
                        elevation: 1,
                        sx: { boxShadow: "2px 2px 4px #eee" },
                      }}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={() => handleViewPlayerReports()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        View Full Player's Report
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onClick={() => handleViewPlayerDetails()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        View Player Info
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {data && data?.length <= 0 && <NoDataAvailable />}
      </TableContainer>
    </Box>
  );
}

export default ReportTable;
