import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";
import { Formik, Form } from "formik";
import * as yup from "yup";
import axios from "axios";
import AdminLayout from "../components/shared/AdminLayout/AdminLayout";
import MetaDecorator from "../components/shared/MetaDecorator/MetaDecorator";
import PageHeading from "../components/shared/PageHeading/PageHeading";
import { AppContext } from "../context";
import {
  labelStyles,
  getAuthToken,
  checkExpiredAuthToken,
  BannerCategories,
  imageBox,
} from "../utils";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

const initialValues = {
  title: "",
  content: "",
  imageUrl: "",
  category: "",
  url: "",
  ctaText: "",
};
let bannerSchema = yup.object().shape({
  title: yup.string().required().label("Title"),
  content: yup.string().required().label("Content"),
  // imageUrl: yup.string().required().label("Image URL"),
  category: yup.string().required(),
  url: yup.string().required(),
});

function AddBanner() {
  const [backgroundImagePreview, setBackgroundImagePreview] = useState("");
  const [image, setImage] = useState("");
  const [foregroundImage, setForegroundImage] = useState("");
  const [foregroundImagePreview, setForegroundImagePreview] = useState("");
  const navigate = useNavigate();
  const { handleOpenSnackbar } = useContext(AppContext);

  const WIDTH = 70;
  const HEIGHT = 70;

  const handleSubmitImage = async (value, errorMessage) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    const formData = new FormData();
    formData.append("image", value);
    try {
      const res = await axios.post(`${process.env.BASE_URL}upload`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res?.data?.data?.data?.imageUrl;
    } catch (error) {
      console.log(error);
      handleOpenSnackbar({
        message: errorMessage,
        severity: "error",
      });
    }
  };

  const handleSubmit = async (values, helpers) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    // const formData = new FormData();

    // formData.append("title", values.title);
    // formData.append("content", values.content);
    // formData.append("imageUrl", values.imageUrl);
    const background = await handleSubmitImage(
      image,
      "Sorry, an error occured while uploading the background image"
    );
    const foreground = await handleSubmitImage(
      foregroundImage,
      "Sorry, an error occured while uploading the foreground image"
    );
    values.imageUrl = background;
    values.foregroundImage = foreground;
    axios
      .post(`${process.env.BASE_URL}banner/create-banner`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res.data);
        handleOpenSnackbar({
          message: "Banner Created Successfully",
          severity: "success",
        });
        helpers.setSubmitting(false);
        setTimeout(() => {
          navigate("/banners");
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
        handleOpenSnackbar({
          message: "Sorry, Banner Could Not Be Created",
          severity: "error",
        });
        helpers.setSubmitting(false);
      });
  };
  return (
    <AdminLayout>
      <MetaDecorator title="Add Banner - Admin Dashboard" />
      <Container maxWidth="xl">
        <PageHeading title="Add Banner" />
        <Container maxWidth="xl">
          <Box my={1}>
            {/* <Typography gutterBottom>
              To Add a Banner, first upload the image, then copy the uploaded
              image URL and paste in the field for the image URL
            </Typography> */}
          </Box>
        </Container>
        <Container maxWidth="xl">
          <Box my={1} sx={imageBox}>
            <FormControl variant="outlined">
              <p>Select background image to upload</p>
              <input
                accept="image/*"
                type="file"
                variant="outlined"
                onChange={(e) => {
                  setImage(e.target.files[0]);
                  setBackgroundImagePreview(
                    URL.createObjectURL(e.target.files[0])
                  );
                }}
              />
            </FormControl>
            <div>
              {backgroundImagePreview && (
                <img
                  src={backgroundImagePreview}
                  alt="uploaded background"
                  width={WIDTH}
                  height={HEIGHT}
                />
              )}
            </div>
          </Box>
          <Box my={1} sx={imageBox}>
            <FormControl variant="outlined">
              <p>Select foreground image to upload</p>
              <input
                accept="image/*"
                type="file"
                variant="outlined"
                onChange={(e) => {
                  setForegroundImage(e.target.files[0]);
                  setForegroundImagePreview(
                    URL.createObjectURL(e.target.files[0])
                  );
                }}
              />
            </FormControl>
            <div>
              {foregroundImagePreview && (
                <img
                  src={foregroundImagePreview}
                  alt="uploaded background"
                  width={WIDTH}
                  height={HEIGHT}
                />
              )}
            </div>
          </Box>
        </Container>
        <br />
        <br />
        <Container maxWidth="xl">
          <div>
            <Formik
              initialValues={initialValues}
              validationSchema={bannerSchema}
              validateOnBlur={true}
              validateOnChange={true}
              onSubmit={(values, helpers) => handleSubmit(values, helpers)}
            >
              {({
                handleChange,
                handleBlur,
                touched,
                errors,
                setFieldValue,
                isSubmitting,
              }) => (
                <Form noValidate>
                  <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        label="Enter the banner title"
                        error={touched.title && errors.title}
                        name="title"
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={touched.title && errors.title}
                      />
                    </FormControl>
                  </Box>
                  <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel
                        variant="outlined"
                        id="roleSelect"
                        sx={labelStyles}
                      >
                        Category
                      </InputLabel>
                      <Select
                        name="category"
                        labelId="roleSelect"
                        variant="outlined"
                        id="roleSelect"
                        onChange={handleChange}
                      >
                        {BannerCategories.map((item) => (
                          <MenuItem key={item?.value} value={item?.value}>
                            {item?.title}{" "}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      {/* <InputLabel variant="outlined" sx={labelStyles}>
                                              </InputLabel> */}
                      <TextField
                        label="Enter the content for the banner"
                        error={touched.content && errors.content}
                        name="content"
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={touched.content && errors.content}
                      />
                    </FormControl>
                  </Box>
                  <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        label="Base URL (e.g https://example.com)"
                        error={touched.url && errors.url}
                        name="url"
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={touched.url && errors.url}
                      />
                    </FormControl>
                  </Box>
                  <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        label="Call To Action Text"
                        error={touched.ctaText && errors.ctaText}
                        name="ctaText"
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={touched.ctaText && errors.ctaText}
                      />
                    </FormControl>
                  </Box>
                  <br />
                  <Button
                    disabled={isSubmitting}
                    startIcon={
                      isSubmitting && <CircularProgress size={"1rem"} />
                    }
                    type="submit"
                    size="large"
                    variant="contained"
                    color="primary"
                  >
                    {isSubmitting ? "Adding Banner" : "Add Banner"}
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      </Container>
    </AdminLayout>
  );
}

export default AddBanner;
