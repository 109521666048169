import React from "react";
import dayjs from "dayjs";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import InputLabel from "@mui/material/CardContent";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import useSWR from "swr";
import {
  labelStyles,
  checkExpiredAuthToken,
  getAuthToken,
} from "../../../utils";
import HalfErrorScreen from "../../shared/HalfErrorScreen/HalfErrorScreen";

const url = `${process.env.BASE_URL}fetch-roles?userType=admin`;

function AdminsFilter({
  userName,
  userRole,
  loginDate,
  status,
  handleChangeStatus,
  handleChangeUserName,
  handleChangeUserRole,
  handleChangeLoginDate,
  handleFiterSubmit,
}) {
  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data?.data?.data);
        return res?.data?.data?.data;
      });
  };
  const { data: roles, error } = useSWR(url, fetcher);

  if (error)
    return (
      <HalfErrorScreen
        title="Sorry, we can't retrieve the requested data this moment"
        subtitle={error.message}
      />
    );

  if (!roles) return null;

  return (
    <Box mb={3}>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item xs={12} sm={6} md={3}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" id="statusSelect" sx={labelStyles}>
              Enter Name
            </InputLabel>
            <TextField
              value={userName}
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) => handleChangeUserName(e)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" id="statusSelect" sx={labelStyles}>
              Status
            </InputLabel>
            <Select
              value={status}
              labelId="statusSelect"
              variant="outlined"
              id="statusSelect"
              onChange={(e) => handleChangeStatus(e)}
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Non Active</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" id="roleSelect" sx={labelStyles}>
              Role
            </InputLabel>
            <Select
              labelId="roleSelect"
              variant="outlined"
              id="roleSelect"
              value={userRole}
              onChange={(e) => handleChangeUserRole(e)}
            >
              {roles.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel variant="outlined" id="statusSelect" sx={labelStyles}>
              Last Login Date
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                size="small"
                // label="Last Login Date"
                value={loginDate}
                onChange={(value) => {
                  handleChangeLoginDate(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth size="small" />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            endIcon={<SearchIcon />}
            onClick={() => handleFiterSubmit()}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AdminsFilter;
