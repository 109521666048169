import React from "react";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import useSWR from "swr";
import AdminLayout from "../components/shared/AdminLayout/AdminLayout";
import MetaDecorator from "../components/shared/MetaDecorator/MetaDecorator";
import PageHeading from "../components/shared/PageHeading/PageHeading";
import axios from "axios";
import { useParams } from "react-router-dom";
import { getAuthToken, checkExpiredAuthToken } from "../utils";
import FullScreenLoader from "../components/shared/FullScreenLoader/FullScreenLoader";
import FullScreenError from "../components/shared/FullScreenError/FullScreenError";
import dayjs from "dayjs";
import GameOptions from "../components/pages/GameDetailsComponents/GameOptions";
import GameInstancesTable from "../components/pages/GameDetailsComponents/GameInstancesTable";

function GameDetailsPage() {
  const { lotteryId } = useParams();

  const url = `${process.env.BASE_URL}game/fetch-lottery/${lotteryId}`;

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data?.data?.data);
        return res?.data?.data?.data;
      });
  };
  const { data, error } = useSWR(url, fetcher);

  if (error)
    return (
      <FullScreenError
        title="Sorry, we can't retrieve the requested data this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  return (
    <AdminLayout>
      <MetaDecorator title={`${data && data?.name} game details - Admin`} />
      <Container maxWidth="xl">
        <PageHeading title="Game details" />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box my={1}>
              {data?.imageUrl ? (
                <img
                  src={data?.imageUrl}
                  width="100"
                  height="100"
                  alt={data?.name}
                />
              ) : (
                <Typography align="left" sx={{ textTransform: "capitalize" }}>
                  No Display Image Available for this game category
                </Typography>
              )}
            </Box>
            <Box my={1} py={1}>
              {data?.audioUrl ? (
                <audio controls>
                  <source src={data?.audioUrl} type="audio/ogg" />
                  Your browser does not support the audio element.
                </audio>
              ) : (
                <Typography align="left" sx={{ textTransform: "capitalize" }}>
                  No music available for this lottery
                </Typography>
              )}
            </Box>
            <Box py={2}>
              <Box
                mb={1}
                pb={1}
                pr={2}
                sx={{
                  maxWidth: "500px",
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #eee",
                }}
              >
                <Typography color="text.secondary">Name:</Typography>
                <Typography align="left" sx={{ textTransform: "capitalize" }}>
                  {data && data?.name}
                </Typography>
              </Box>
              <Box
                mb={1}
                pb={1}
                pr={2}
                sx={{
                  maxWidth: "500px",
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #eee",
                }}
              >
                <Typography color="text.secondary">Category:</Typography>
                <Typography align="left">{data && data?.category}</Typography>
              </Box>
              <Box
                mb={1}
                pb={1}
                pr={2}
                sx={{
                  maxWidth: "500px",
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #eee",
                }}
              >
                <Typography color="text.secondary">Date Created:</Typography>
                <Typography align="left">
                  {dayjs(data?.createdAt).format("DD MMM YYYY, HH:MM a")}
                </Typography>
              </Box>
              <Box
                mb={1}
                pb={1}
                pr={2}
                sx={{
                  maxWidth: "500px",
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #eee",
                }}
              >
                <Typography color="text.secondary">Last Updated:</Typography>
                <Typography align="left">
                  {dayjs(data?.updatedAt).format("DD MMM YYYY, HH:MM a")}
                </Typography>
              </Box>
              <Box
                mb={1}
                pb={1}
                pr={2}
                sx={{
                  maxWidth: "500px",
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #eee",
                }}
              >
                <Typography color="text.secondary">Game Count:</Typography>
                <Typography align="left">{data && data?.gameCount}</Typography>
              </Box>
              <Box
                mb={1}
                pb={1}
                pr={2}
                sx={{
                  maxWidth: "500px",
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #eee",
                }}
              >
                <Typography color="text.secondary">Result Count:</Typography>
                <Typography align="left">
                  {data && data?.resultCount}
                </Typography>
              </Box>

              <Box
                mb={1}
                pb={1}
                pr={2}
                sx={{
                  maxWidth: "500px",
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #eee",
                }}
              >
                <Typography color="text.secondary">Status:</Typography>
                {data?.status ? (
                  <Chip color="success" label="Active" size="small" />
                ) : (
                  <Chip label="Not Active" color="error" size="small" />
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <GameOptions data={data} />
          </Grid>
        </Grid>
        <Box my={2}>
          <Typography gutterBottom variant="h6" sx={{ fontWeight: 500 }}>
            Game Instances
          </Typography>
          <GameInstancesTable data={data?.Games} />
        </Box>
      </Container>
    </AdminLayout>
  );
}

export default GameDetailsPage;
