import React from "react";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { getReportSum, reportTotalStyles, whiteColorStyles } from "../data";
import { currencyFormater } from "../../../../utils";

function DailySalesTotalRow({ data }) {
  return (
    <TableRow sx={reportTotalStyles}>
      <TableCell sx={whiteColorStyles}>Total</TableCell>
      <TableCell align="right">
        <Typography sx={whiteColorStyles} align="left">
          &#8358;{currencyFormater(getReportSum(data, "totalSold"))}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography sx={whiteColorStyles} align="left">
          {currencyFormater(getReportSum(data, "ticketCount"))}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography sx={whiteColorStyles} align="left">
          &#8358;{currencyFormater(getReportSum(data, "totalCommission"))}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography sx={whiteColorStyles} align="left">
          {currencyFormater(getReportSum(data, "commissionCount"))}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography sx={whiteColorStyles} align="left">
          &#8358;{currencyFormater(getReportSum(data, "totalWon"))}
        </Typography>
      </TableCell>
      <TableCell align="right" colSpan={2}>
        <Typography sx={whiteColorStyles} align="left">
          &#8358;{currencyFormater(getReportSum(data, "profit"))}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

export default DailySalesTotalRow;
