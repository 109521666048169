import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import axios from "axios";
import useSWR, { mutate } from "swr";
import NoDataAvailable from "../../shared/EmptyTable/EmptyTable";
import {
  getAuthToken,
  checkExpiredAuthToken,
  currencyFormater,
} from "../../../utils";
import TableLoader from "../../shared/TableLoader/TableLoader";
import HalfErrorScreen from "../../shared/HalfErrorScreen/HalfErrorScreen";
import dayjs from "dayjs";

function BookingCodeUsersTable({ bookingCode }) {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const [isFiltering, setIsFiltering] = useState(false);

  const url = `${process.env.BASE_URL}game/fetch-games?limit=${rowsPerPage}&page=${page}`;
  let filterURL = `${process.env.BASE_URL}game/fetch-booking-code-users/${bookingCode}?limit=${rowsPerPage}&page=${page}`;

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  const rowsPerPageOptions = [20, 50, 100, 200];

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data);
        return res?.data?.data;
      });
  };

  const handleChangePage = (event, newPage) => {
    setIsFiltering(true);
    setPage(newPage + 1);
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsFiltering(false);
        mutate(filterURL, res?.data?.data);
      })
      .catch((err) => {
        setIsFiltering(false);
        console.log(err);
      });
  };
  const headCells = [
    "Player",
    "Phone",
    "Email",
    "Ticket ID",
    "Staked Amount",
    "Commission Earned",

    "Status",
    "Date Created",
  ];

  const { data, error } = useSWR(filterURL, fetcher);

  if (error)
    return (
      <HalfErrorScreen
        title=" Sorry, we cannot retrieve booking code users data at this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <TableLoader />;

  return (
    <Box sx={{ padding: "10px 0px", marginBottom: "10px" }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }} aria-label="recent users table">
          <TableHead>
            <TableRow>
              {headCells.map((item) => (
                <TableCell key={item} component="th" sx={{ fontWeight: 600 }}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data?.data?.length > 0 &&
              data?.data?.map((row) => (
                <TableRow key={row?.ticketId}>
                  <TableCell>
                    {row?.firstname} {row?.lastname}
                  </TableCell>
                  <TableCell>{row?.phone}</TableCell>
                  <TableCell>{row?.email}</TableCell>
                  <TableCell>{row?.ticketId}</TableCell>
                  <TableCell>
                    <div>NGN{currencyFormater(row?.totalStakedAmount)}</div>
                  </TableCell>
                  <TableCell>
                    <div>
                      NGN
                      {currencyFormater(parseFloat(row?.commission).toFixed(2))}
                    </div>
                  </TableCell>
                  <TableCell>{row.status}</TableCell>
                  <TableCell style={{ width: 160 }}>
                    {dayjs(row.createdAt).format("MMM D, YYYY")}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {data && data?.data?.length <= 0 && <NoDataAvailable />}
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={data?.totalCount}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}

export default BookingCodeUsersTable;
