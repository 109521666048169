import React, { useContext, useRef, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import Container from "@mui/material/Container";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import useSWRImmutable from "swr/immutable";
import { useNavigate, useParams } from "react-router-dom";
import AdminLayout from "../components/shared/AdminLayout/AdminLayout";
import MetaDecorator from "../components/shared/MetaDecorator/MetaDecorator";
import PageHeading from "../components/shared/PageHeading/PageHeading";
import { AppContext } from "../context";
import {
  checkExpiredAuthToken,
  getAuthToken,
  labelStyles,
  labelStylesTwo,
  labelStylesThree,
  FIVE_OF_NINETY,
  META4,
  META4_OVER_OPTIONS,
  META4_UNDER_OPTIONS,
  PERFECT_BALLS,
  PERFECT_BALLS_BET_OPTIONS,
  SEVEN49_BETOPTIONS,
  SEVEN49_OVEROPTIONS,
  SEVEN49_UNDEROPTIONS,
  SEVEN49,
  POOL_OF_FAME_BETOPTIONS,
  POOL_OF_FAME,
  safeJSONParse,
  META4_BETOPTIONS,
  FIVE_OF_NINETY_BET_OPTIONS,
  FIVE_OF_NINETY_BOOSTER_OPTIONS,
  GHANA_GAME_RESULTOPTIONS,
  GHANA_GAME_BETOPTIONS,
  GHANA_GAME_V2_RESULTOPTIONS,
  GHANA_GAME_V2_BETOPTIONS,
  MERRY_INDOOR_GAME_RESULT_OPTIONS,
  MERRY_INDOOR_GAME_BETOPTIONS,
  MERRY_INDOOR_GAME_V2_RESULT_OPTIONS,
  MERRY_INDOOR_GAME_V2_BETOPTIONS,
  MERRY_INDOOR_GAME,
  MERRY_INDOOR_GAME_V2,
  GHANA_GAME,
  GHANA_GAME_V2,
  OVER_BET_OPTIONS,
  UNDER_BET_OPTIONS,
} from "../utils";
import FullScreenLoader from "../components/shared/FullScreenLoader/FullScreenLoader";
import FullScreenError from "../components/shared/FullScreenError/FullScreenError";
import FormLoader from "../components/shared/FormLoader/FormLoader";
import produce from "immer";

function EditGame() {
  const [selectedSlug, setSelectedSlug] = useState("");
  const [booster, setBoosterOption] = useState([]);
  const [betOption, setBetOption] = useState([]);
  const [betOptionsValues, setBetOptionsValues] = useState([]);
  const [resultOptionsValues, setResultOptionsValues] = useState([]);
  const [underOptionsValues, setUnderOptionsValues] = useState([]);
  const [overOptionsValues, setOverOptionsValues] = useState([]);
  const [checkedBetOptions, setCheckedBetOptions] = useState([]);
  const [over, setOverOption] = useState([]);
  const [under, setUnderOption] = useState([]);
  const [loadingbooster, setLoadingBoosterOption] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [singleLotteryData, setSingleLotteryData] = useState({});
  const [loadingSingleLotteryData, setLoadingSingleLotteryData] =
    useState(false);
  const [loadingbetOption, setLoadingBetOption] = useState(false);
  const [loadingOver, setLoadingOverOption] = useState(false);
  const [loadingUnder, setLoadingUnder] = useState(false);
  const [resultTypes, setResultTypes] = useState([]);
  const [loadingResultTypes, setLoadingResultTypes] = useState(false);

  const { handleOpenSnackbar } = useContext(AppContext);
  const specialInputRef = useRef();

  const { lotteryId } = useParams();
  const navigate = useNavigate();
  const singleLotteryURL = `${process.env.BASE_URL}game/fetch-lottery/${lotteryId}`;
  const settingsURL = `${process.env.BASE_URL}site-settings/fetch-setting-by-slug/game-categories`;

  // Bet Options Onchange handler
  const handleBetOptionChange = (e) => {
    const value = e.target.value;
    const index = betOptionsValues?.indexOf(value);
    const found = checkedBetOptions.find((element) => value === element?.name);

    // console.log("nnnnnnnnnn", found);
    if (index === -1 && !found) {
      setBetOptionsValues((prevValue) => [...prevValue, value]);
      setCheckedBetOptions((prevState) => [
        ...prevState,
        { name: value, multiplier: "" },
      ]);
    } else if (found) {
      setBetOptionsValues((prevValue) =>
        prevValue.filter((item) => item !== value)
      );
      setCheckedBetOptions((prevState) =>
        prevState.filter((item) => item?.name !== value)
      );
    } else {
      setBetOptionsValues((prevValue) =>
        prevValue.filter((item) => item !== value)
      );
      setCheckedBetOptions((prevState) =>
        prevState.filter((item) => item?.name !== value)
      );
    }
  };

  // Result Options Onchange Handler
  const handleResultOptionsChange = (e) => {
    const value = e.target.value;
    const index = resultOptionsValues?.indexOf(value);
    if (index === -1) {
      setResultOptionsValues((prevValue) => [...prevValue, value]);
    } else {
      setResultOptionsValues((prevValue) =>
        prevValue?.filter((item) => item !== value)
      );
    }
  };

  // Over Options Onchange Handler
  const handleOverOptionsChange = (e) => {
    const value = e.target.value;
    const index = overOptionsValues?.indexOf(value);
    if (index === -1) {
      setOverOptionsValues((prevValue) => [...prevValue, value]);
    } else {
      setOverOptionsValues((prevValue) =>
        prevValue?.filter((item) => item !== value)
      );
    }
  };

  // Under Options Onchange Handler
  const handleUnderOptionsChange = (e) => {
    const value = e.target.value;
    const index = underOptionsValues?.indexOf(value);
    if (index === -1) {
      setUnderOptionsValues((prevValue) => [...prevValue, value]);
    } else {
      setUnderOptionsValues((prevValue) =>
        prevValue?.filter((item) => item !== value)
      );
    }
  };

  const fetcherSingleLottery = async () => {
    try {
      checkExpiredAuthToken();
      const token = getAuthToken();
      const res = await axios.get(singleLotteryURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      await setSelectedSlug((prevState) => res?.data?.data?.data?.category);
      const category = res?.data?.data?.data?.category;
      if (category === META4) {
        await getMeta4Options();
      } else if (category === FIVE_OF_NINETY) {
        await getFiveNinetyOptions();
      } else if (category === SEVEN49) {
        await get749Option();
      } else if (category === PERFECT_BALLS) {
        await getPerfectBallsOptions();
      } else if (category === POOL_OF_FAME) {
        await getPoolofFameOptions();
      } else if (category === MERRY_INDOOR_GAME) {
        await getMerryIndoorOptions();
      } else if (category === MERRY_INDOOR_GAME_V2) {
        await getMerryIndoorV2Options();
      } else if (category === GHANA_GAME) {
        await getGhanaGameOptions();
      } else if (category === GHANA_GAME_V2) {
        await getGhanaGameV2Options();
      }
      let parsedOptions = safeJSONParse(res?.data?.data?.data?.betOptions);
      setSingleLotteryData(res?.data?.data?.data);
      setCheckedBetOptions(parsedOptions);
      let tempData, betOptions;
      betOptions = safeJSONParse(res?.data?.data?.data?.betOptions);
      tempData = betOptions.map((option) => option?.name);
      setBetOptionsValues(tempData);

      setResultOptionsValues(
        safeJSONParse(res?.data?.data?.data?.resultOptions)
      );
      setUnderOptionsValues((prev) =>
        safeJSONParse(res?.data?.data?.data?.underOptions)
      );
      setOverOptionsValues((prev) =>
        safeJSONParse(res?.data?.data?.data?.overOptions)
      );

      setLoadingSingleLotteryData(false);
    } catch (error) {
      setLoadingSingleLotteryData(false);
      console.log("error fetching lottery data", error);
    }
  };

  useEffect(() => {
    fetcherSingleLottery();
  }, []);

  let initialValues = {
    lotteryName: "",
    category: "",
    slug: "",
    imageUrl: "",
    audioUrl: "",
    resultOptions: [],
    betOptions: [],
  };

  if (singleLotteryData && singleLotteryData?.lotteryId) {
    // console.log("single lottttttttttttt", singleLotteryData);
    try {
      initialValues.lotteryName = singleLotteryData?.name;
      initialValues.category = singleLotteryData?.category;
      initialValues.slug = singleLotteryData?.slug;
      initialValues.imageUrl = singleLotteryData?.imageUrl;
    } catch (error) {
      console.log("ee", error);
    }
  }

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: async (values, helpers) => {
      let lotterySlug = values.lotteryName;
      lotterySlug = lotterySlug.replaceAll(" ", "-");
      lotterySlug = lotterySlug.toLowerCase();
      checkExpiredAuthToken();
      const token = getAuthToken();

      // return console.log(
      //   "resutldddddddddddddddddddddddd uot",
      //   overOptionsValues
      // );
      helpers.setSubmitting(true);
      setIsSubmitting((prevState) => true);

      values.overOptions = JSON.stringify(overOptionsValues);
      values.underOptions = JSON.stringify(underOptionsValues);
      values.betOptions = JSON.stringify(checkedBetOptions);
      values.resultOptions = JSON.stringify(resultOptionsValues);
      values.category = selectedSlug;
      const formData = new FormData();

      formData.append("lotteryName", values.lotteryName);
      formData.append("category", values.category);
      formData.append("slug", lotterySlug);
      formData.append("imageUrl", values.imageUrl);
      formData.append("audioUrl", values.audioUrl);
      formData.append("resultOptions", values.resultOptions);
      formData.append("betOptions", values.betOptions);
      formData.append("overOptions", values.overOptions);
      formData.append("underOptions", values.underOptions);

      axios
        .put(
          `${process.env.BASE_URL}game/update-lottery/${lotteryId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          helpers.setSubmitting(false);
          setIsSubmitting((prevState) => false);
          helpers.resetForm();
          window.scrollTo(0, 0);
          if (res?.data?.message) {
            handleOpenSnackbar({
              message: res?.data?.message,
              severity: "success",
            });
            setTimeout(() => {
              return navigate("/games");
            }, 1500);
          }
          handleOpenSnackbar({
            message: "Game Updated Successfully",
            severity: "success",
          });
          setTimeout(() => {
            return navigate("/games");
          }, 1500);
        })
        .catch((err) => {
          helpers.setSubmitting(false);
          setIsSubmitting((prevState) => false);
          if (err?.response?.data?.responsemessage?.msg) {
            return handleOpenSnackbar({
              message: err?.response?.data?.responsemessage?.msg,
              severity: "error",
            });
          }
          if (err?.response?.data?.responsemessage) {
            return handleOpenSnackbar({
              message: err?.response?.data?.responsemessage,
              severity: "error",
            });
          }
          handleOpenSnackbar({
            message: "Sorry, Game Could Not Be Updated",
            severity: "error",
          });
        });
    },
    validationSchema: yup.object().shape({
      lotteryName: yup.string().required().label("Lottery Name"),
    }),
  });
  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(settingsURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data?.data;
      });
  };

  const getOptions = () => {
    if (selectedSlug === FIVE_OF_NINETY) {
      getFiveNinetyOptions();
    } else if (selectedSlug === META4) {
      getMeta4Options();
    } else if (selectedSlug === PERFECT_BALLS) {
      getPerfectBallsOptions();
    } else if (selectedSlug === SEVEN49) {
      get749Option();
    } else if (selectedSlug === POOL_OF_FAME) {
      getPoolofFameOptions();
    } else if (selectedSlug === MERRY_INDOOR_GAME) {
      getMerryIndoorOptions();
    } else if (selectedSlug === MERRY_INDOOR_GAME_V2) {
      getMerryIndoorV2Options();
    } else if (selectedSlug === GHANA_GAME) {
      getGhanaGameOptions();
    } else if (selectedSlug === GHANA_GAME_V2) {
      getGhanaGameV2Options();
    }
  };

  const get749Option = () => {
    setResultTypes([]);
    setBoosterOption([]);
    getBetOption(SEVEN49_BETOPTIONS);
    getOverOption(SEVEN49_OVEROPTIONS);
    getUnderOption(SEVEN49_UNDEROPTIONS);
  };

  const getMeta4Options = () => {
    setResultTypes([]);
    setBoosterOption([]);
    getBetOption(META4_BETOPTIONS);
    getOverOption(META4_OVER_OPTIONS);
    getUnderOption(META4_UNDER_OPTIONS);
  };

  const getPerfectBallsOptions = () => {
    getBetOption(PERFECT_BALLS_BET_OPTIONS);
    setResultTypes([]);
    setBoosterOption([]);
    setOverOptionsValues([]);
    setUnderOptionsValues([]);
  };

  const getPoolofFameOptions = () => {
    getBetOption(POOL_OF_FAME_BETOPTIONS);
    setResultTypes([]);
    setBoosterOption([]);
    setOverOptionsValues([]);
    setUnderOptionsValues([]);
  };

  const getGhanaGameOptions = () => {
    getResultTypes(GHANA_GAME_RESULTOPTIONS);
    getBetOption(GHANA_GAME_BETOPTIONS);
    setBoosterOption([]);
    getOverOption(OVER_BET_OPTIONS);
    getUnderOption(UNDER_BET_OPTIONS);
  };

  const getGhanaGameV2Options = () => {
    getResultTypes(GHANA_GAME_V2_RESULTOPTIONS);
    getBetOption(GHANA_GAME_V2_BETOPTIONS);
    setBoosterOption([]);
    getOverOption(OVER_BET_OPTIONS);
    getUnderOption(UNDER_BET_OPTIONS);
  };

  const getMerryIndoorOptions = () => {
    getResultTypes(MERRY_INDOOR_GAME_RESULT_OPTIONS);
    getBetOption(MERRY_INDOOR_GAME_BETOPTIONS);
    setBoosterOption([]);
    getOverOption(OVER_BET_OPTIONS);
    getUnderOption(UNDER_BET_OPTIONS);
  };
  const getMerryIndoorV2Options = () => {
    getResultTypes(MERRY_INDOOR_GAME_V2_RESULT_OPTIONS);
    getBetOption(MERRY_INDOOR_GAME_V2_BETOPTIONS);
    setBoosterOption([]);
    getOverOption(OVER_BET_OPTIONS);
    getUnderOption(UNDER_BET_OPTIONS);
  };

  const getBetOption = async (value) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setLoadingBetOption(true);
    return axios
      .get(
        `${process.env.BASE_URL}site-settings/fetch-setting-by-slug/${value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        let { content } = res?.data?.data?.data;
        content = safeJSONParse(content);
        setBetOption(content);
        setLoadingBetOption(false);
      })
      .catch((err) => {
        return console.log(err);
      });
  };

  const getResultTypes = async (value) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setLoadingResultTypes(true);
    return axios
      .get(
        `${process.env.BASE_URL}site-settings/fetch-setting-by-slug/${value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        let { content } = res?.data?.data?.data;
        content = safeJSONParse(content);
        setResultTypes(content);
        setLoadingResultTypes(false);
      })
      .catch((err) => {
        return console.log(err);
      });
  };

  const getBoosterOption = async (value) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setLoadingBoosterOption(true);
    return axios
      .get(
        `${process.env.BASE_URL}site-settings/fetch-setting-by-slug/${value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        let { content } = res?.data?.data?.data;
        content = safeJSONParse(content);
        setBoosterOption(content);
        setLoadingBoosterOption(false);
      })
      .catch((err) => {
        return console.log(err);
      });
  };

  const getOverOption = async (value) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setLoadingOverOption(true);
    return axios
      .get(
        `${process.env.BASE_URL}site-settings/fetch-setting-by-slug/${value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        let content;
        content = safeJSONParse(res?.data?.data?.data?.content);

        setOverOption(content);
        setLoadingOverOption(false);
      })
      .catch((err) => {
        return console.log(err);
      });
  };

  const getUnderOption = async (value) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setLoadingUnder(true);
    return axios
      .get(
        `${process.env.BASE_URL}site-settings/fetch-setting-by-slug/${value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        let content;

        setUnderOption(safeJSONParse(res?.data?.data?.data?.content));
        setLoadingUnder(false);
      })
      .catch((err) => {
        return console.log(err);
      });
  };

  const handleSelectCategory = (e) => {
    const value = e.target.value;
    setCheckedBetOptions([]);
    setBetOptionsValues([]);
    setResultOptionsValues([]);
    setOverOption([]);
    setUnderOption([]);
    setSelectedSlug(value);
    setTimeout(() => {
      specialInputRef.current.click();
    }, 1000);
  };
  const specialOnchange = () => {
    getOptions();
  };

  const formPaperStyle = { padding: 3 };
  const { data, error } = useSWRImmutable(settingsURL, fetcher);

  if (error)
    return (
      <FullScreenError
        title=" Sorry, data cannot be retrieved at this moment"
        subtitle={error.message}
      />
    );

  if (!data || loadingSingleLotteryData) return <FullScreenLoader />;

  let Categories = data && data?.content ? safeJSONParse(data?.content) : [];
  // console.log("betopitions values", over);
  // console.log("sigle data values", singleLotteryData);
  return (
    <AdminLayout>
      <MetaDecorator title="Update Game Category- Admin" />
      <Container maxWidth="xl">
        <PageHeading title="Update Game Category" />
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item xs={12} md={8} lg={7}>
              <Paper sx={formPaperStyle}>
                <div>
                  <form noValidate onSubmit={formik.handleSubmit}>
                    <Box
                      my={1}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <FormControl fullWidth>
                        <FormLabel sx={labelStyles}>
                          Select Display Image (Best Fit Images are PNG types)
                        </FormLabel>
                        <br />
                        <input
                          type="file"
                          name="imageUrl"
                          accept="image/png"
                          onBlur={formik.handleBlur}
                          onChange={(e) =>
                            formik.setFieldValue("imageUrl", e.target.files[0])
                          }
                        />
                      </FormControl>
                      <div>
                        {singleLotteryData?.imageUrl && (
                          <div>
                            <Typography variant="caption">
                              Previous Image
                            </Typography>
                            <img
                              src={singleLotteryData?.imageUrl}
                              alt="lottery image"
                              width="60px"
                              height="60px"
                            />{" "}
                          </div>
                        )}
                      </div>
                    </Box>
                    <Box my={2}>
                      <FormControl>
                        <FormLabel sx={labelStyles}>
                          Select Lottery TV Screen Music (Max file size of 2mb)
                        </FormLabel>
                        <br />
                        <input
                          type="file"
                          name="audioUrl"
                          accept=".mp3,audio/*"
                          onBlur={formik.handleBlur}
                          onChange={(e) => {
                            formik.setFieldValue("audioUrl", e.target.files[0]);
                          }}
                        />
                      </FormControl>
                    </Box>
                    <Box my={1}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          label="Enter Game Category Title"
                          error={
                            formik.touched.lotteryName &&
                            formik.errors.lotteryName
                          }
                          name="lotteryName"
                          value={formik.values.lotteryName}
                          variant="outlined"
                          fullWidth
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          helperText={
                            formik.touched.lotteryName &&
                            formik.errors.lotteryName
                          }
                        />
                      </FormControl>
                    </Box>
                    <Box my={1}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        error={
                          formik.touched.category && formik.errors.category
                        }
                      >
                        <InputLabel variant="outlined" sx={labelStylesThree}>
                          Select Category
                        </InputLabel>
                        {/* <br />
                  <br /> */}
                        <Select
                          // label="Select Category"
                          name="category"
                          // variant="outlined"
                          value={selectedSlug}
                          onChange={handleSelectCategory}
                        >
                          {Categories?.map((category, idx) => (
                            <MenuItem
                              key={`222${idx}${category}`}
                              value={category}
                            >
                              {category}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          {formik.touched.category && formik.errors.category}
                        </FormHelperText>
                      </FormControl>
                    </Box>

                    <button
                      style={{ display: "none" }}
                      type="button"
                      ref={specialInputRef}
                      onClick={specialOnchange}
                    >
                      Click
                    </button>

                    <>
                      {loadingResultTypes ? (
                        <FormLoader />
                      ) : (
                        <Box>
                          {resultTypes && resultTypes?.length > 0 && (
                            <Box my={1}>
                              <FormControl variant="outlined" fullWidth>
                                <InputLabel
                                  variant="outlined"
                                  sx={labelStylesTwo}
                                >
                                  Result Options
                                </InputLabel>
                                <br />
                                <FormGroup
                                  aria-label="result options"
                                  row
                                  name="resultOptions"
                                  value={resultOptionsValues}
                                >
                                  {resultTypes &&
                                    resultTypes?.length > 0 &&
                                    resultTypes?.map((item, idx) => (
                                      <FormControlLabel
                                        key={`${item?.value}a0${item?.name}${idx}`}
                                        value={item?.value}
                                        control={
                                          <Checkbox
                                            color="primary"
                                            checked={resultOptionsValues?.includes(
                                              item?.value
                                            )}
                                            onChange={handleResultOptionsChange}
                                            name="resultOptions"
                                          />
                                        }
                                        label={item?.name}
                                      />
                                    ))}
                                </FormGroup>
                              </FormControl>
                            </Box>
                          )}
                        </Box>
                      )}
                    </>

                    {loadingbetOption ? (
                      <FormLoader />
                    ) : (
                      <Box>
                        {betOption && betOption?.length > 0 && (
                          <Box my={1}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel
                                variant="outlined"
                                sx={labelStylesTwo}
                              >
                                Bet Options
                              </InputLabel>
                              <br />
                              <FormGroup
                                aria-label="bet options"
                                row
                                name="betOptions"
                              >
                                {betOption &&
                                  betOption?.length > 0 &&
                                  betOption?.map((item, idx) => (
                                    <FormControlLabel
                                      key={`${item?.value}0${item?.name}${idx}`}
                                      value={item?.value}
                                      control={
                                        <Checkbox
                                          color="primary"
                                          checked={betOptionsValues?.includes(
                                            item?.value
                                          )}
                                          onChange={handleBetOptionChange}
                                          name="betOptions"
                                        />
                                      }
                                      label={item?.name}
                                    />
                                  ))}
                              </FormGroup>
                            </FormControl>
                          </Box>
                        )}
                      </Box>
                    )}

                    {loadingOver ? (
                      <FormLoader />
                    ) : (
                      <Box>
                        {over && over?.length > 0 && (
                          <Box my={1}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel
                                variant="outlined"
                                sx={labelStylesTwo}
                              >
                                Over Options
                              </InputLabel>
                              <br />
                              <FormGroup aria-label="over options" row>
                                {over &&
                                  over?.length > 0 &&
                                  over?.map((item, idx) => (
                                    <FormControlLabel
                                      key={`${item?.value}p${idx}`}
                                      value={item?.value}
                                      control={
                                        <Checkbox
                                          color="primary"
                                          checked={overOptionsValues?.includes(
                                            item?.value
                                          )}
                                          onChange={handleOverOptionsChange}
                                          name="overOptions"
                                        />
                                      }
                                      label={item?.name}
                                    />
                                  ))}
                              </FormGroup>
                            </FormControl>
                          </Box>
                        )}
                      </Box>
                    )}

                    {loadingUnder ? (
                      <FormLoader />
                    ) : (
                      <Box>
                        {under && under?.length > 0 && (
                          <Box my={1}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel
                                variant="outlined"
                                sx={labelStylesTwo}
                              >
                                Under Options
                              </InputLabel>
                              <br />
                              <FormGroup aria-label="under options" row>
                                {under &&
                                  under?.length > 0 &&
                                  under?.map((item, idx) => (
                                    <FormControlLabel
                                      key={`${item?.value}ew${idx}`}
                                      value={item?.value}
                                      control={
                                        <Checkbox
                                          color="primary"
                                          checked={underOptionsValues?.includes(
                                            item?.value
                                          )}
                                          onChange={handleUnderOptionsChange}
                                          name="underOptions"
                                        />
                                      }
                                      label={item?.name}
                                    />
                                  ))}
                              </FormGroup>
                            </FormControl>
                          </Box>
                        )}
                      </Box>
                    )}

                    <br />
                    <Button
                      startIcon={
                        isSubmitting && (
                          <CircularProgress severity="primary" size={"1rem"} />
                        )
                      }
                      type="submit"
                      disabled={isSubmitting}
                      size="large"
                      variant="contained"
                      color="primary"
                    >
                      {isSubmitting ? "Updating Game" : " Update Game"}
                    </Button>
                  </form>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={5}>
              <Paper sx={formPaperStyle}>
                {checkedBetOptions?.length > 0 &&
                  checkedBetOptions?.map((item, idx) => (
                    <Box
                      key={`${item?.name}pp${idx}`}
                      my={1}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormControl variant="outlined">
                        <TextField
                          label="Bet Option"
                          variant="outlined"
                          size="small"
                          value={item?.name}
                          readOnly
                        />
                      </FormControl>
                      &nbsp;
                      <FormControl variant="outlined">
                        <TextField
                          label="Multiplier"
                          variant="outlined"
                          size="small"
                          type="number"
                          value={item?.multiplier}
                          onChange={(e) => {
                            const value = e.target.value;
                            setCheckedBetOptions((currentValue) =>
                              produce(currentValue, (draft) => {
                                draft[idx].multiplier = value;
                              })
                            );
                          }}
                        />
                      </FormControl>
                    </Box>
                  ))}
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </AdminLayout>
  );
}

export default EditGame;
