import React from "react";
import Grid from "@mui/material/Grid";
import { GiChampions, GiPlayerBase } from "react-icons/gi";
import { IoMdTrendingUp } from "react-icons/io";
import { ImTicket } from "react-icons/im";
import "./StatCardStyles.css";
import AnalyticsCard from "../../shared/AnalyticsCard/AnalyticsCard";
import { HalfMoneyAnalyticsCard } from "../../shared/MoneyAnalyticsCard/MoneyAnalyticsCard";

function GameInstanceDetailsStatsCard(data) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={3}>
        <HalfMoneyAnalyticsCard
          mainNumber={data && data?.totalSaleAmount}
          icon={<IoMdTrendingUp className="playerIcon" />}
          text={"Total Sales"}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <AnalyticsCard
          mainNumber={data && data?.totalTickets ? data?.totalTickets : 0}
          icon={<ImTicket className="playerIcon" />}
          text={"Total Tickets"}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <AnalyticsCard
          mainNumber={
            data && data?.totalNumberOfPlayers ? data?.totalNumberOfPlayers : 0
          }
          icon={<GiPlayerBase className="playerIcon" />}
          text={"Players"}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <AnalyticsCard
          mainNumber={data && data?.activeTickets ? data?.activeTickets : 0}
          icon={<GiChampions className="playerIcon" />}
          text={"Active Tickets"}
        />
      </Grid>
    </Grid>
  );
}

export default GameInstanceDetailsStatsCard;
