import React, { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AdminLayout from "../components/shared/AdminLayout/AdminLayout";
import MetaDecorator from "../components/shared/MetaDecorator/MetaDecorator";
import PageHeading from "../components/shared/PageHeading/PageHeading";
import { AppContext } from "../context";
import AdminSentOverdraft from "../components/pages/AdminTransactions/SentOverdraft/AdminSentOverdraft";
import Transactions from "../components/pages/AdminTransactions/Topups/Transactions";
import AdminWalletInflow from "../components/pages/AdminTransactions/WalletInflow/AdminWalletInflow";
import AdminWalletOutflow from "../components/pages/AdminTransactions/WalletOutflow/AdminWalletOutflow";

const OVERDRAFT = "OVERDRAFT";
const TOPUP = "TOPUP";
const WALLET_INFLOW = "WALLET_INFLOW";
const WALLET_OUTFLOW = "WALLET_OUTFLOW";

const AdminTransactions = () => {
  const { adminData } = useContext(AppContext);

  const [value, setValue] = useState(OVERDRAFT);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <AdminLayout>
      <div>
        <MetaDecorator title="Admin Transactions - Admin" />
        <PageHeading title="Admin Transactions" />
      </div>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          centered
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          sx={{ color: "green", marginTop: "5px" }}
        >
          <Tab label="Sent Overdrafts" value={OVERDRAFT} />
          <Tab label="Wallet Top ups" value={TOPUP} />
          <Tab label="Wallet Inflow" value={WALLET_INFLOW} />
          <Tab label="Wallet Outflow" value={WALLET_OUTFLOW} />
        </Tabs>
        {value === OVERDRAFT && <AdminSentOverdraft value={value} />}
        {value === TOPUP && <Transactions value={value} />}

        {value === WALLET_INFLOW && <AdminWalletInflow value={value} />}
        {value === WALLET_OUTFLOW && <AdminWalletOutflow value={value} />}
      </Box>
    </AdminLayout>
  );
};

export default AdminTransactions;
